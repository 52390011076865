.btn {
    font-size: 1.4rem;
    border-radius: 0;
    text-transform: uppercase;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
}
.btn-primary {
    color: $color-white;
    background: $color-tertiary-light;
    border-color: $color-tertiary-light;
    &:hover {
        color: $color-white;
        background: $color-tertiary-dark;
        border-color: $color-tertiary-dark;
        transition: 0.3s;
    }

    &:focus {
        background: $color-tertiary-dark;
        border-color: $color-tertiary-dark;
    }

    &:disabled {
        color: $color-white;
        background: $color-tertiary-light;
        border-color: $color-tertiary-light;
        transition: 0.3s;
        opacity: 0.65;
        text-decoration: none;
    }
}
.btn-outline-primary {
    &:disabled {
        color: $color-tertiary-light;
        transition: 0.3s;
        opacity: 0.65;
        text-decoration: none;
    }
}
.btn-secondary {
    color: $color-white;
    background: $color-primary;
    border-color: $color-primary;
    &:hover {
        color: $color-white;
        background: $color-primary-dark;
        border-color: $color-primary-dark;
        transition: 0.3s;
    }

    &:focus {
        background: $color-primary-dark;
        border-color: $color-primary-dark;
    }
}

.btn-cancel {
    color: $color-dark;
    background: $color-grey-light-1;
    border-color: $color-grey-light-1;
    &:hover {
        color: $color-dark;
        background: $color-grey-light-2;
        border-color: $color-grey-light-2;
        transition: 0.3s;
    }

    &:focus {
        background: $color-grey-light-2;
        border-color: $color-grey-light-2;
    }
}

.btn-link {
    color: $color-tertiary-light;
    &:hover {
        color: $color-tertiary-dark;
    }
}

.button-icon {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

.next-btn, .submit-btn {
    padding: 0.8rem 6rem;
    display: block;
}

.rl-mls-btn {
    display: flex;
    justify-content: flex-start;
    button {
        width: 100%;
    }
    .back-btn {
        width: 100%;
        display: block !important;
    }
}

.back-btn {
    background-color: $color-white;
    border: $color-white;
    color: $color-dark;
    font-size: 1.2rem;
    .button-icon {
        color: $color-dark;
        padding-left: 0;
        padding-right: 0.8rem;
    }
    &:focus {
        color: $color-tertiary-dark !important;
        background: $color-white !important;
        border-color: $color-white !important;
        box-shadow: none !important;
    }
    &:hover {
        color: $color-tertiary-dark !important;
        background: $color-white !important;
        border-color: $color-white !important;
        box-shadow: none !important;
        .button-icon {
            color: $color-tertiary-dark !important;
        }
    }
}

.btn-icon {
    background-color: $color-white;
    border: $color-white;
    color: $color-tertiary-light;
    text-align: start;
    padding-left: 0.3rem;
    text-transform: none;
    text-decoration: underline;
    &:hover {
        background-color: $color-white;
        border: $color-white;
        color: $color-tertiary-dark;
    }
    .button-icon {
        padding-left: 0;
        padding-right: 0.8rem;
        font-size: 1.6rem;
    }

    &:focus {
        background-color: $color-white;
        border: $color-white;
        color: $color-tertiary-dark;
        box-shadow: none;
        outline: none;
    }
}

.btn-grey {
    color: $color-grey-dark-2;
}


/* UI Changes */
.rl-back-btn {
    text-transform: inherit !important;
    color: #1474be;
    text-decoration: underline;
    &:focus-visible {
        outline: none;
    }
    &:active {
        color: #1474be !important;
        background-color: transparent !important;
    }
    &:hover {
        box-shadow: none !important;
    }
    &:focus {
        background-color: #ffffff !important;
        color: #1474be !important;
        box-shadow:none;
    }
}

button {
    letter-spacing: 1px !important;
}
/* UI Changes */

// .btn-icon-primeoutline {
//     background-color: $color-white;
//     border: 1px solid $color-tertiary-light;
//     color: $color-tertiary-light;

//     &:hover {
//         background-color: $color-white;
//         border: 1px solid $color-tertiary-dark;
//         color: $color-tertiary-dark;
//     }

//     &:focus {
//         background-color: $color-white;
//         border: 1px solid $color-tertiary-dark;
//         color: $color-tertiary-dark;
//         box-shadow: none;
//     }
// }


