.rl-unlimited-photo-section {
    margin-bottom: 12rem !important;
    .rl-printing-content {
        .rl-card-checkbox {
            width: 100%;
        }
        .MuiFormGroup-root {
            justify-content: center;
        }
        .MuiTypography-root {
            font-size: 1.8rem !important;
        }
        .MuiRadio-root {
            &.Mui-checked {
                color: rgba(0, 0, 0, 0.6) !important;
            }
        }
        .rl-card-radio {
            .MuiSvgIcon-root {
                font-size: 2rem !important;
            }
        }
    }
    .rl-checkbox-select-service {
        text-transform: initial !important;
        font-size: 1.8rem !important;
        position: absolute;
        right: 100px;
    }
    .rl-select-sevice-title {
        position: relative;
    }
    .rl-card-checkbox {
        .rl-card-radio {
            margin-right: 30px !important;
            width: auto !important;
        }
    }
    .rl-photo-h {
        background-color: $color-grey-light-2 !important;
        padding-top: .5rem!important;
        padding-bottom: .5rem!important;
    }
    .rl-photo-title-h {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }
    .rl-title-radio {
        position: absolute;
        right: 230px;
        svg {
            path {
                color: $color-tertiary-light !important;
            }
        }
        .MuiFormControlLabel-label {
            font-size: 1.6rem !important;
        }
        .MuiFormHelperText-root {
            display: none ;
        }
    }
    .rl-photo-title-h {
        position: relative;
    }
    .card-body {
        display: none;
        .card-title {
            display: none !important;
        }
    }
    .rl-photos-radio {
        padding: 2rem 1.5rem !important;
        padding-bottom: 0.8rem !important;
        display: flex;
        align-items: center;
        .form-check-inline {
            margin: 0px !important;
        }
        .form-check-input {
            width: 1.4em;
            height: 1.4em;
            margin-top: 0px !important;
            border: 2px solid $color-tertiary-light !important;
        }
        .form-check-label {
            font-size: 1.8rem !important;
            // margin-left: -10px;
        }

    }
    .unlimited-photos {
        .slider {
            background-color: $color-white !important;
            padding: 2rem 0rem !important;
            .card {
                padding: calc(var(--bs-gutter-x)*.5) !important;
                border-radius: 0.1rem !important;
            }
        }
        .unlimited-title {
            margin-bottom: 0rem !important;
            text-align: center !important;
            padding-top: 0px !important;
            padding-bottom: 0px !important;
        }
    } 
}