.rl-table-icon {
    button {
        background-color: transparent !important;
        color: #1474be !important;
        border: 0px !important;
        padding-left: 0px !important;
        svg {
            font-size: 1.8rem;
            font-weight: 600;
        }
    }
    .rl-delete-icon {
        svg {
            color: $color-red !important;
        }
    }
}

/* UI Changes */
.rl-advertisement-section {
    .bg-danger {
        background-color: transparent !important;
        color: $color-red !important;
        font-size: 13px !important;
        font-weight: 500 !important;
    }
    .rl-common-content {
        .ag-theme-alpine  {
            .btn-primary {
                background-color: transparent !important;
            }
        }
    }
    .bg-info {
        color: $color-black;
        background-color: transparent !important;
        font-size: 2rem !important;
        font-weight: 600;
    }
}

.rl-upload-btn {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
    padding: 10px 40px !important;
    .button-icon {
        display: block;
        margin-bottom: 10px;
    }
}

.rl-addphoto-advertisement {
    button {
        display: flex;
        align-items: center;
        color: $color-tertiary-light !important;
        background: transparent !important;
        border-color: $color-tertiary-light !important;
        padding: 1.5rem 1.8rem !important;
        width: 100%;
        justify-content: center;
        &:hover {
            color: $color-white !important;
            background: $color-tertiary-light !important;
            border-color: $color-tertiary-light !important;
        }
    }
    span {
        display: block !important;
        line-height:0.5 !important;
    }
    .button-icon {
        margin-right: 5px !important;
    }
    .MuiInputBase-inputMultiline {
        font-family: $font-family !important;
        font-size: 1.4rem !important;
    }
}

.rl-advertisement-photo {
    .rl-btn-primary {
        padding: 1rem !important;
    }
    img {
        width: 20rem;
        height: 15rem;
        // object-fit: cover;
        object-fit: fill;
    }
}
/* UI Changes */