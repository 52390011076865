
$color-primary: #f26e21;
$color-primary-light: #ec8144;
$color-primary-dark: #d9631d;

$color-secondary: #707070;
$color-secondary-light: #f8f8f8;
$color-secondary-dark: #858689;

$color-tertiary-light: #1474be;
$color-tertiary-dark: #1268ab;

$color-grey-light-1: #efefef;
$color-grey-light-2: #eee;
$color-grey-light-3: #e5e5e5;

$color-grey-dark: #777;
$color-grey-dark-2: #979797;
$color-grey-dark-3: #333;

$color-white: #fff;
$color-black: #000;
$color-dark:#18181B;
$color-red:#ff0000;
$color-blue:#0E74BE;
$color-green:#00FF00;

$color-warning: #FFB800;
$color-danger: #c33641;
$color-success: #28a745;
$color-info: #17a2b8;
$color-active:#07bc0c;


$color-bg:#f2f2f2;

$font-family: bebas-neue-pro, sans-serif;

$hairline-weight: 100;
$thin-weight:     200;
$light-weight:    300;
$regular-weight:  400;
$medium-weight:   500;
$semibold-weight: 600;
$bold-weight:     700;
$xbold-weight:    800;
$black-weight:    900;

$italic-style:italic;
$normal-style: normal;




