.pick-up {
    .direction {
        margin-top: 2.5rem;
    }

    .loc-relative {
        position: relative;
    }

    .pickup-set-location {
        margin-top: 1rem;
        margin-bottom: 2rem;
        margin-left: 1.2rem;
        padding: 0;

        position: absolute;
        left: 0;
        z-index: 99;
        background-color: $color-white;

        .input-group {
            height: 4.2rem;

            .input-group-text {
                font-size: 1.2rem;
                padding: 0.375rem 1.5rem;
            }
        }

        ul {
            padding: 1.5rem 2.5rem 0rem;
            font-size: 1.2rem;
            line-height: 2rem;
            li {
                padding-bottom: 1rem;
            }
        }
    }
}

/* UI Changes */
.pickup-address-content {
    margin-bottom: 12rem !important;
    .primarySubTitle {
        padding-top: 1rem !important;
    }
    .rl-card-checkbox {
        .MuiFormGroup-row {
            display: block !important;
        }
        .rl-card-radio {
            .MuiRadio-root {
                margin-right: 5px !important;
            }
            width: 100%;
            .MuiFormControlLabel-labelPlacementEnd {
                margin-left: 0px !important;
                padding-right: 20px !important;
            }
        }
    }
    .no-asterisk {
        .MuiFormControl-root {
            .MuiInputLabel-root {
                &:before {
                    content: "" !important;
                }
            }
        }  
    }
    .direction {
        margin-top: 1rem !important;
    }
    .MuiInputBase-input {
        .MuiInputBase-inputMultiline {
            height: 80px !important;
            padding-top: 1.2rem !important;
        }
    }
    .MuiFormHelperText-root {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
    .rl-dropdown-content {
         .MuiFormControl-root {
            input {
                height: 3.3rem !important;
                padding: 0 1.2rem 0.2rem !important;
            }
         }
    }
    .stepper-bottom {
        text-align: right !important;
    }
    .back-btn {
        text-transform: initial !important;
    }
    .roc-mui {
        .MuiInputLabel-root {
            &::before {
                content:" *";
                color: #d32f2f !important;
            }
        }
    }
    // .MuiAutocomplete-root {
    //     .MuiOutlinedInput-root {
    //         .MuiAutocomplete-input {
    //             padding: 0 1.2rem 0.4rem !important;
    //         }
    //     }
    // }
    .MuiFormControl-root {
        .MuiInputLabel-root {
            &::before {
                content:" *";
                color: #d32f2f !important;
            }
        }
    }
    .no-asterisk {
        label {
            &::before {
               content: "" !important;
            }
        }
    }
    .no-label {
        .MuiFormLabel-root {
            opacity: 0;
        }
    }
    .rl-instructions {
        .MuiOutlinedInput-notchedOutline {
            border-color: transparent !important;
            top: 0px !important;
        }
        .MuiInputBase-root {
            &.MuiOutlinedInput-root {
                background-color: #f2f2f2 !important;
            }
        }
    }
    .MuiAutocomplete-root {
       .MuiAutocomplete-input {
            font-family: $font-family !important;
        }
    }
    .MuiInputBase-input {
        &.MuiOutlinedInput-input {
          font-family: $font-family !important;
          font-size: 1.8rem !important;
         }
    }
    .rl-time-dropdown {
        .MuiInputBase-input {
            &.MuiOutlinedInput-input {
              font-family: $font-family !important;
              font-size: 1.8rem !important;
              padding-top: 5px !important;
              padding-bottom: 5px !important;
            }
        }
    }
    .MuiFormGroup-root {
        .MuiTypography-root {
            font-family: $font-family !important;
            font-size: 1.8rem !important;
        }
    }
    .MuiFormControl-root {
       input {
        font-family: $font-family !important;
       }
    }
    .MuiSelect-select  {
        &.MuiInputBase-input {
            &.MuiOutlinedInput-input {
               font-family: $font-family !important;
            }
        }
    }
    .MuiInputBase-root {
        &.MuiInputBase-formControl {
             font-family: $font-family !important;
        }
    }
    .MuiInputBase-root {
        &.MuiOutlinedInput-root {
            border-radius: 0px !important;
        }
    }
    .rl-custom-date {
        .MuiFormControl-root {
            input {
                height: 3.4rem !important;
            }
        }
    }
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    border-color: teal;
    color: purple;
    font-style: italic;
}

/* UI Changes */

// ======= PICK UP MOBILE RESPONSIVE =======
@media (max-width: 768px) {
    .pick-up {
        .primarySubTitle {
            padding-top: 2rem;
            padding-bottom: 2rem;
            font-size: 2.8rem;
        }
        .form-group {
            .form-check {
                padding-bottom: 1rem;
            }

        }

        .direction {
            margin-top: 1.5rem;
        }
    }
}

@media (max-width: 576px) {
    .pick-up {
        padding: 0;
    }
}