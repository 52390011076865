.packagePage {
    .pageHeading {
        margin-bottom: 0;
        padding-bottom: 1rem;
    }

    .bg-title {
        margin-top: 3rem;
    }

    .addon-row {
        // justify-content: center;
        // display: flex;
        .addon-border {
            padding-top: 1rem;
            border: 1px solid $color-grey-dark;
            width: 32%;
            margin-left: 0.8rem;
            margin-right: 0.8rem;

        }
    }

    .hr-tag {
        margin: 4rem 0;
    }

    .form {
        .card-shadow {
            box-shadow: 0 .175rem .25rem rgba(0,0,0,.15)!important;
            padding: 2rem 1rem;
        }

        .card-head {
            display: flex;
            .form-check {
                padding-right: 1rem;
            }
            svg {
                margin: auto 0;
                margin-top: 0.3rem;
                color: $color-primary;
                font-size: 1.3rem;
            }
        }

        .duplicate-price {
            font-size: 1.5rem;
            font-weight: 500;
            text-decoration: line-through;
            text-decoration-color: red;
            color: $color-grey-dark;
            margin-bottom: 0;

            span {
                vertical-align: super;
                font-size: 1.2rem;
                padding-right: 0.25rem;
            }
        }
        .discount-price {
            font-size: 2.4rem;
            font-weight: 600;
            color: $color-grey-dark-3;
            margin-bottom: 0;

            span {
                vertical-align: super;
                font-size: 1.4rem;
                // padding-right: 0.25rem;
            }
        }
    }

    .media-row {

    }
}

.rl-slider-addon {
    .addons-flex {
        width: 100% !important;
        justify-content: center !important;
        min-height: 60px !important;
    }
    .MuiFormControlLabel-root {
        margin-left: 0px !important;
    }
    .rl-addons-row  {
        width: 100% !important;
        align-items: center !important;
        justify-content: center !important;
    }
    .carousel-inner {
        height: 25rem !important;
        // height: 270px !important;
    }
}

.rl-radio-addrider  {
    .MuiTypography-root {
        font-family: $font-family !important;
    }
}

.rl-package-block {
    .modal-title {
        font-family: $font-family !important;
        color: #18181b !important;
    }
    .rl-table-search {
        margin-left: 0px !important;
    }
}

#new_price {
    border: 1px solid #ced4da !important;
    padding: 1px 8px !important;
    color: #212529 !important;
    font-family: $font-family !important;
    font-size: 1.8rem!important;
    width: 50%;
}

.rl-update-package {
    justify-content: center;
    .rl-update-title {
        display: flex;
        align-items: baseline;
        h1 {
            font-family: $font-family !important;
            margin-right: 10px;
        }
    }
    svg {
        cursor: pointer;
        color: #1474be!important;
    }
    .MuiChip-root {
        font-family: $font-family;
        // width: 100%;
        border: 1px solid $color-tertiary-light !important;
        color: $color-tertiary-light !important;
        font-size: 1.4rem;
        border-radius: 5px;
    }
}

.rl-custom-package-content {
    background-color: $color-white !important;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.08) !important;
    padding: 20px !important;
    margin-left: -10px;
    margin-bottom: 30px;
    .rl-update-package {
        .MuiChip-label {
            overflow: initial !important;
        }
    }
}

.rl-package-search {
    .input-group {
        align-items: center;
        border: 1px solid #ced4da;
    }
    .form-control {
        height: 3.5rem !important;
        padding: 0.275rem 0.75rem !important;
        font-family: $font-family !important;
        font-size: 1.9rem!important;
        border: 0px !important;
        &:focus {
            border: 0px !important;
            box-shadow: none !important;
        }
    }
    svg {
        font-size: 16px!important;
        margin-left: 5px!important;
        margin-right: 12px!important;
    }    
}

.rl-installation-block {
    .rl-addons-check {
        display: block !important;
    }
    .rl-card-radio {
        width: auto !important;
    }
    .rl-addons-check {
        .btn {
            color: #212529 !important;
            background-color: $color-white !important;
            border-color: $color-white !important;
            svg {
                path {
                    &:nth-child(1) {
                        color: $color-primary;
                    }
                }
            }
        }
        .MuiCheckbox-root {
            padding-top: 0px !important;
        }
    }
    .MuiRadio-root  {
        display: block;
        padding: 5px 0px !important;
    }
    .rl-card-radio {
        .MuiSvgIcon-root {
            width: 1.5em !important;
            height: 1.5em !important;
        }
    }
    .MuiFormGroup-row {
        padding-left: 25px;
    }
    .MuiFormControlLabel-root {
        display: block !important;
        margin-left: 0px !important;
    }
    .rl-card-radio {
        .MuiFormControlLabel-label {
            font-size: 1.4rem !important;
        }
    }
    .MuiFormControl-root {
        padding: 3px 25px;
    }
}

.rl-custom-package {
    .rl-card-radio {
        .MuiFormControlLabel-root {
            margin-right: 25px !important;
            margin-left: 0px !important;
        }
    }
    .rl-radiobutton-list {
        &.rl-custom-radio {
            .MuiRadio-root {
                padding-right: 9px !important;
                padding-top: 9px !important;
                padding-bottom: 9px !important;
            }
        } 
    } 
}

// ======= FORM MOBILE RESPONSIVE =======
@media (max-width: 1200px) {
    .packagePage {
        .addon-row {
            .addon-border {
                padding-top: 1rem;
                border: 1px solid $color-grey-dark;
                width: 31%;
            }
        }
    }
}

@media (max-width: 992px) {
    .packagePage {
        .addon-row {
            .addon-border {
                padding-top: 1rem;
                border: 1px solid $color-grey-dark;
                width: 31%;
            }
        }
    }
}

@media (max-width: 768px) {
    .packagePage {
        .pageHeading {
            font-size: 4.0rem;
        }

        .pac-add {
            padding-left: 0;
            padding-right: 0;
        }
        .blueSubTitle {
            font-size: 2.6rem;
        }

        .form {
            .duplicate-price {
                display: none;
            }
        }

        .addon-row {
            padding-left: 5rem;
            padding-right: 5rem;
            .addon-border {
                padding-top: 1rem;
                border: 1px solid $color-grey-dark;
                width: 100%;
                margin-left: 0;
                margin-right: 0;

            }
        }
        .carousel-col {
            padding-left: 5rem;
            padding-right: 5rem;
        }

        .hr-tag {
            width: 84%;
            margin-right: auto;
            margin-left: auto;
        }

        .special-instruction {
            text-align: center;
            padding-left: 5rem;
            padding-right: 5rem;
        }
    }
}

.rl-row {
    margin-left: -15px !important;
    margin-right: -15px !important;
}