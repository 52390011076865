.dashboard-row {
    margin-top: 2rem;
    .avatar__text-box--avatar-name {
        span {
            color: $color-black !important;
            font-weight: 600;
            font-family: $font-family !important;
        }
    }
}

.logged-out {
    padding: 5rem 10rem 3rem !important;
    // min-height: 550px;
}

a {
    text-decoration: none;
}

.avatar__text-box--avatar-name {
    font-family: $font-family !important;
    line-height: 1.2 !important;
}

.rl-superadmin-checkbox {
    .MuiTypography-root {
        margin-left: 15px;
        font-family: $font-family !important;
    }
}

/* UI Changes */
.rl-dashboard-view {
    // .avatar__image-box {
    //        box-shadow: 0px 0px 2px 0px rgba(136,136,136,0.5);
    // }
    .figure {
           box-shadow: 0px 0px 2px 0px rgba(136,136,136,0.5);
           padding: 10px;
    }
    .rl-avatar-content {
        .figure-img {
            // object-fit: contain !important;
        }
    } 
}

.rl-logo-content {
    .figure-img {
        height: 15rem !important;
    }
}

.avatar__image-box {
    margin-top: 10px;
    .figure {
        margin-bottom: 0px !important;
    }
}

.rl-avatar-content {
    .avatar__image {
        margin: 0px !important;
    }
}
.rl-sidebar {
    .MuiTypography-root {
        font-family: $font-family !important;
        font-size: 1.4rem !important;
    }
}
.rl-banner-img-box {
    .card {
        .card-body {
            text-align: center;
        }
    }
}

.rl-emailsent-block {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin: auto;
    width: 100%;
    text-align: center;
    justify-content: center;
    h1 {
        font-size: 4rem;
        text-transform: uppercase;
        color: $color-primary;
        letter-spacing: 1rem;
        margin-bottom: 2.8rem;
        font-weight: 600;
        margin-top: -10px;
    }
    h3 {
        font-size: 2.8rem !important;
        color: $color-black;
        margin-bottom: 1.5rem !important;
        font-weight: 600;
    }
    span {
        font-size: 2.3rem;
        font-weight: 200;
    }
    p {
        color: $color-tertiary-light;
        font-size: 1.7rem;
        font-weight: 500;
    }
    .rl-emailsent_icon {
        width: 40px;
        height: 10px;
        border-radius: 100%;
        background-color: RGB(242 110 33 / 0.10);
        padding: 2rem 2rem;
        line-height: 10rem;
        display: initial;
        svg {
            width: 4rem;
            height: 4rem;
            color: $color-primary;
        }
    }
}
.rl-logout-block {
    display: flex;
    align-items: center;
    // position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height:calc(100vh - 95px);
    margin: auto;
    width: 100%;
    text-align: center;
    justify-content: center;
    .custom-back-btn {
        font-size: 1.6rem !important;
    }
    .rl-logout-btn {
        justify-content: center;
        .btn  {
            min-width: 14rem !important;
            font-size: 1.8rem !important;
        }
    }
    h1 {
        font-weight: 600;
        font-size: 2.4rem;
        color: $color-black;
        font-family: $font-family !important;
    }
}
.rl-superadmin-checkbox {
    .MuiButtonBase-root {
        &.MuiCheckbox-root {
         padding: 0px !important;
        }
    }
    .MuiFormGroup-root {
        margin-left: 0px !important;
    }
    .MuiFormControlLabel-root {
        padding-top: 0px !important;
        margin-left: 0px !important;
    }
}

.rl-error-section {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin: auto;
    width: 100%;
    text-align: center;
    justify-content: center;
    .rl-404-content {
        display: flex;
        align-items: center;
        justify-content: center;
        h2 {
            font-size: 26rem;
            font-family: $font-family;
            color: $color-black;
            font-weight: $xbold-weight;
            margin-bottom: 0px !important;
            &:first-child {
                margin-right: -20px;
            }
            &:last-child {
                margin-left: -20px;
            }
        }
    }
    h1 {
        font-size: 7rem !important;
        text-transform: uppercase;
        font-weight: $xbold-weight;
        color: $color-primary;
        margin-bottom: -5px !important;
        margin-top: -20px !important;
    }
    p {
        font-size: 2rem !important;
        font-weight: $regular-weight;
        margin-bottom: 25px!important;
    }
    .btn {
        background-color: $color-black;
        border-color: $color-black;
    }
    &.rl-denied-section {
        .rl-404-content {
            h2 {
                margin: 20px !important;
                margin-bottom: 0px !important;
            }
        }
    }
}

.rl-sidebar-section {
    width: 220px !important;
    .MuiToolbar-root {
        border: 0px !important;
    }
}
.css-ttn1xo {
    // width: calc(100% - 220px);
    width: calc(100% - 18rem) !important;
    // width: 100% !important;
}

.rl-sidebar-content-section {
    .MuiDrawer-docked {
        width: 18rem !important;
    }
}


.css-1snu2qi {
    margin-left: -150px;
}
.rl-admin-section {
    .home-subtitle {
        font-weight: $medium-weight;
        font-size: 3.5rem !important;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
        font-family: $font-family !important;
    }
    .rl-table-icon {
        button {
            background-color: transparent !important;
            color: $color-tertiary-light !important;
            border: 0px !important;
            padding-left: 0px !important;
        }
        .button-icon {
            padding-left: 0px !important;
        }
    }
    h1 {
        margin-bottom: 0px;
        color: #000000 !important;
        font-size: 2.2rem;
        font-weight: 600;
        font-family: $font-family !important;
    }
    .rl-admins-list {
        background-color: $color-white !important;
        box-shadow: 0 0 5px 0rem RGB(0 0 0 / 0.08) !important;
        padding:20px !important;
        .rl-btn-primary {
            padding-top: 0.7rem !important;
            padding-bottom: 0.7rem !important;
        }
        &.rl-common-content {
            .rl-table-search {
                 input {
                    padding: 0.275rem 0.75rem !important;
                    height: 3.5rem !important;
                 }
            }
        }
        .btn-primary {
            margin: 0px !important;
            align-items: center;
            display: flex;
            svg {
                font-size: 1.8rem;
                font-weight: 600;
            }
        }
    }
}

.bg-success {
    background-color: transparent !important;
    color: $color-active !important;
    font-size: 13px !important;
    font-weight: 500 !important;
}
.bg-info {
    background-color: transparent !important;
    color: $color-red !important;
    font-size: 13px !important;
    font-weight: 500 !important;
}

.rl-dollar-block {
    svg {
        font-size: 1.4rem;
        margin-top: -5px;
    }
}

.rl-table-search {
    .MuiFormControl-root {
        &.MuiTextField-root {
            margin-bottom: 0px !important;
        }
    }
    .roc-mui {
        .MuiOutlinedInput-root {
            input {
                height: 4rem !important;
                padding: 0px 1.2rem !important;
            }
        }
    }
    .MuiOutlinedInput-notchedOutline {
        top: 0px !important;
    }
}

.rl-admins-list {
    .ag-theme-alpine  {
        .ag-header {
            background-color: #ffffff !important;
        }
    }
}

@media (max-width:768px) {
    .rl-dashboard-view {
        text-align: center;
        .rl-avatar-content {
            position: relative;
        }
        .rl-logo-content {
            h1 {
                position: absolute;
                top: -12px;
                right: 0px;
                left: 0px;
                font-size: 1.4rem !important;
            }
        } 
    } 
    .rl-error-section {
        img {
            width: 50%;
        }
        h2 {
            font-size: 20rem !important;
        }
        &.rl-denied-section {
            img {
                width: 32%;
            }
            h2 {
                margin: 0px !important;
            }
         }
    }
}

.rl-dashboard-content {
    .avatar__image-box  {
        .avatar__image {
            width: 24rem !important;
            margin: 1rem !important;
            height: 14rem !important;
            object-fit: cover !important;
        }
    }
}
.css-k008qs {
    .banner {
        margin-bottom: 12rem !important;
    }
}

.ag-theme-alpine {
    .ag-ltr {
        .ag-has-focus {
            .ag-cell-focus {
                &:not(.ag-cell-range-selected) {
                    border: none !important;
                }
            }
            .ag-full-width-row {
                &.ag-row-focus  {
                    .ag-cell-wrapper {
                        &.ag-row-group {
                            border: none !important;
                        }
                    }
                }
            }
        }
        .ag-context-menu-open {
            .ag-cell-focus:not(.ag-cell-range-selected) {
                border: none !important;
            }
        }
        .ag-cell-range-single-cell {
            &.ag-cell-range-handle {
                border: none !important;
            }
        }
    }
}


@media (max-width:567px) {
    .rl-dashboard-view {
        .rl-logo-content {
            h1 {
                font-size: 1rem;
            }
        }
    }
}