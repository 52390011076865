.roc-accordion {
    padding: 2rem 10rem;

    .roc-accordion-item {
        margin-bottom: 3rem;
        border: none;
        padding: 0 2rem;
        .roc-accordion-header {
            
            .accordion-button {
                font-size: 1.6rem;
                font-weight: 500;
                color: $color-black;
                padding: 1.5rem 2rem;
                border-bottom: 1px solid $color-black;

                &:not(.collapsed) {
                    background-color: $color-white;
                }

                &:focus {
                    box-shadow: none;
                    border-color: $color-black;
                }
            }
        }

        .roc-accordion-body {
            padding: 2rem;
            font-size: 1.3rem;
            font-weight: 400;
            background-color: $color-grey-light-1;
            color: $color-secondary;
            
        }
    }
}