.reset-password-container {
    margin-top: 5rem;
    margin-bottom: 5rem;
    height: 57vh;
}

.reset-pwd-modal {
    padding-top: 5rem;
    padding-bottom: 5rem;
}

// .footer__navigation {

// }

/* UI*/
@media (max-width:768px) {
    .rl-btn-resend {
        text-align: center;
        justify-content: center !important;
    }
}


.rl_reset_password {
    padding: 10rem 5rem !important;
    display: block;
    .text-icon {
        font-size: 2.8rem;
        margin-right: 0.8rem;
        color: $color-primary;
    }
    .modal-title {
        display: flex;
        align-items: flex-end;
        line-height: auto;
        margin-bottom: 20px;
        p {
            margin-bottom: 0px;
            line-height: 1.1;
            font-weight: $semibold-weight;
            letter-spacing: 1px;
        }
    }
    .sub-para {
        font-size: 1.4rem !important;
        margin-bottom: 5px;
        display: block;
    }
    .form {
        .form-group {
            label {
                &::before {
                    content:" *";
                    color: $color-danger;
                }
            }
        }
        .btn {
            text-transform: initial !important;
        }
    }
    .rl-back-btn {
        padding-left: 0px !important;
    }
    .forogt_back_link {
        position: absolute;
        bottom: 140px;
    }
}
.rl_forgotpassword_section {
    display: block;
    padding: 10rem 5rem !important;
    .rl_back_btn {
        padding-left: 0px;
    }
    h1 {
        font-family: $font-family !important;
    }
    p {
        font-family: $font-family !important;
    }
    .MuiFormLabel-root {
        &.MuiInputLabel-root {
        font-family: $font-family !important;
        &.Mui-focused {
           font-family: $font-family !important;
            }
        }
    }
    label {
        font-weight: $medium-weight;
    }
    .modal-title {
        display: flex;
        align-items: flex-end;
        line-height: initial;
        font-weight: $semibold-weight;
        margin-bottom: 20px;
        letter-spacing: 1.2px;
        text-transform: capitalize;
    }
    .text-icon {
        font-size: 2.8rem;
        margin-right: 0.8rem;
        color: $color-primary;
    }
    .rl-back-btn {
        padding-left: 0px !important;
    }
    .forogt_back_link {
        position: absolute;
        bottom: 140px;
    }
    .sub-para {
        font-size: 1.4rem;
        margin-bottom: 5px;
        display: block;
    }
    .rl-emailtext {
        color: $color-tertiary-light;
        font-size: 1.6rem;
        font-weight: $medium-weight;
    }
    .btn {
        text-transform: initial !important;
        &:focus {
            box-shadow: none !important;
        }
    }
    .form {
        .form-group {
            label {
                &::before {
                    content: " *";
                    color: #d32f2f !important;
                }
            }
        }
    }
}

@media (max-width:768px) {
    .rl_reset_password {
        .modal-title {
            justify-content: center;
        }
        .sub-para {
            text-align: center;
        }
        .btn-group {
            width: 100%;
        }
        .forogt_back_link {
            // display: none;
        }
    }
    .rl_forgotpassword_section {
        text-align: center;
        .modal-title {
            justify-content: center;
            align-items: baseline;
        }
        .forogt_back_link {
            // display: none;
            left: 0px;
            right: 0px;
            margin-bottom: 20px;
        }
        .btn-group {
            width: 100%;
        }
        .btn {
            width: 100%;
        }
    }
}
/* UI Changes*/