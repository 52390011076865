.pricing {
    &__title {
        &__header {
            @include pageTitle;
        }
    }
    &__tagline {
        display: flex;
        align-items: center;
        justify-content: center;
        &__grey {
            // @include tagline;
            // margin-right: 0.8rem;
        }
        &__prime {
            @include tagline;
            color: $color-primary;
        }
    }

    &__getPrice {
        display: flex;
        justify-content: center;
        align-items: center;
        // margin: 1rem 0;
        // padding-left: 8rem;
        // padding-right: 8rem;

        &__form-group {
            display: flex;
            // width: 33.33%;
            justify-content: center;
            align-items: center;
            padding: 1.5rem;
            &__label {
                font-size: 1.6rem;
                font-weight: 600;
                color: $color-black;
            }
            &__input {
                color: $color-tertiary-light;
                height: 36px;
                font-size: 1.4rem;
                border-radius: 0;
                border: none;
                border-bottom: 1px solid $color-black;
                padding-bottom: 0;
                &:focus {
                    box-shadow: none;
                }
            }
            &::placeholder {
                color: $color-tertiary-light;
            }
        }

        .zip-form {
            justify-content: flex-end;
            // width: 25%;
            .zip-lbl {
                // width: 60%;
            }
        }
        .sqft-form {
            // width: 25%;
            .sqft-lbl {
                // width: 40%;
                text-align: center;
            }
        }
        .discount-form {
            // width: 50%;
            justify-content: flex-start;
            .discount-lbl {
                // width: 80%;
            }
        }
    }
    .pricing__custom {
        margin-right: 0.5rem;
        justify-content: center;
        text-align: center;
        .chevron-btn {
            background: unset;
            border-color: unset;
            border: none;
            color: $color-tertiary-light;
            margin: 0rem auto 1.5rem;
            font-size: 1.8rem;
            // width: 400px;
            text-align: center;
            display: flex;
            flex-direction: row-reverse;
            span {
                margin-left: 1rem;
                color: $color-black;
            }
            &:hover {
                color: $color-tertiary-dark;
            }
        }
    }

}
// .discount-form {
//     width: 40%;
//     .discount-lbl {
//         width: 80%;
//     }
// }

/* UI Changes */
.rl-see-pricing-block {
    text-align: start !important;
}

.rl-pricing-content {
    .rl-info-pricing {
         color: $color-warning !important;
    }
}

.rl-info {
    color: $color-warning !important;
}

.rl-pricing-content {
    min-height: calc(100vh - 400px) !important;
    .pricing__getPrice__form-group  {
        .MuiTextField-root {
            margin-bottom: 0px !important;
            width: 45%;
            input {
                font-size: 2rem !important;
                // color: #1474be !important;
                padding: 0px !important;
            }
        }
        .rl-pricing-block {
            display: flex;
            align-items: center;
            .MuiInputBase-root {
                padding-right: 0px !important;
            }
            label {
                font-size: 2rem !important;
            }
        }
    }
    .rl-alacarte {
        .btn {
            justify-content: center !important;
        }
    }
    margin-bottom: 12rem !important;
    .pricing__tagline__grey {
        font-size: 1.6rem !important;
        font-weight: 500 !important;
    }
    .form-text {
        font-size: 1.2rem !important;
        color: $color-red !important;
        display: block;
        line-height: 1.4 !important;
    }
    form {
        padding: 2px 0px;
        display: inline-block;
        // margin-bottom: 20px !important;
        background-color: #fff;
        // box-shadow: 0 0 5px 0rem RGB(0 0 0/0.08) !important;
    }
    // .discount-form {
    //   width: auto !important;
    // }
    input:-internal-autofill-selected {
        background-color: transparent !important;
    }
    .pricing__getPrice__form-group__label {
        line-height: 1.5;

    }
    .pricing__tagline__grey {
        text-align: center !important;
        display: flex !important;
        justify-content: center !important;
        color: $color-red;
    }
    .pricing__getPrice__form-group__input {
        line-height: 1.5;
        font-size: 2.4rem;
        background-image: none !important;
        font-family: $font-family !important;
        color: #1474be !important;
    }
    .rl-pricing-input {
        align-items: baseline;
    }
    .rl-alacarte {
        .btn {
            text-transform: capitalize;
        }
    }
    .pricing__getPrice {
        // fieldset {
        //     border-top: 0px !important;
        //     border-left: 0px !important;
        //     border-right: 0px !important;
        // }
        .zip-form {
            padding-bottom: 0px !important;
            align-items: center !important;
            padding-top: 0px !important;
            label {
                margin-bottom: 0px !important;
                // width: 60% !important;
                // width:auto !important;
                width: 50% !important;
            }
            .rl-pricing-block {
                justify-content: flex-end;
            }
        }
        .sqft-form {
            padding-top: 0px !important;
            padding-bottom: 0px !important;
            align-items: center !important;
            label {
                margin-bottom: 0px !important;
                width: 50% !important;
            }
            .rl-pricing-block {
                justify-content: center;
            }
        }
        .discount-form {
            padding-top: 0px !important;
            padding-bottom: 0px !important;
            align-items: flex-end !important;
            // width: 25% !important;
            label {
                margin-bottom: 0px !important;
                width: 50% !important;
            }
            .rl-pricing-block {
                justify-content: flex-start;
                .MuiInputBase-formControl {
                    padding-right: 0px !important;
                }
            }
        }
    }
    .MuiTabs-root {
        .MuiTab-root {
            color: #1976d2 !important;
            opacity: 0.6;
        }
    }
    .MuiButtonBase-root {
        &.MuiTab-root {
            &.Mui-selected {
                opacity: 1 !important;
                font-family: $font-family !important;
            }
        }
    }
}

.pricingPlan {
    .pricingPlan__col {
        &:nth-child(2) {
              .card-header {
                &:first-child {
                    color: $color-primary-dark;
                }
            }
        }
    }
}

.pricing__getPrice__form-group {
    .MuiFormHelperText-root {
        text-align: center;
    }
    .mb-2 {
        margin-bottom: 0px !important;
    }
}

.rl-addon-section {
    .rl-addons-row {
        .MuiSvgIcon-root {
            height: 2em !important;
            width: 2em !important;
        }
    }
}

.rl-video-title {
    font-family: $font-family;
    font-size: 3rem !important;
    font-weight: 500 !important;
}

.rl-addons-check {
    .MuiCheckbox-root {
        padding: 9px;
    }
    align-items: center;
    .MuiSvgIcon-root {
        height: 2em !important;
        width: 2em !important;
    }
    .counter-option {
        display: flex !important;
    }
    justify-content: center;
    .rl-addon-section {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .carousel-control-next {
            opacity: 1 !important;
        }
        .carousel-control-prev {
            opacity: 1 !important;
        }
    }
    .addons-flex {
        width: 100%;
        justify-content: center !important;
        min-height: 62px !important;
    }
    .rl-addons-row {
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }
}

.rl-pricing-ordersummary {
    table {
        thead {
            tr {
                th {
                    font-size: 1.6rem !important;
                    font-family: $font-family !important;
                    font-weight: 700;
                    &:last-child {
                        text-align: right;
                    }
                }
            }
        }
        tbody {
            tr {
                td {
                    font-size: 1.5rem !important;
                    font-family: $font-family !important;
                    span {
                        font-size: 1.5rem !important;
                        font-family: $font-family !important;
                    }
                    &:last-child {
                        text-align: right !important;
                    }
                }
            }
        }
    }
}

.rl-pricing-ordersummary .MuiPaper-elevation::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.rl-pricing-ordersummary .MuiPaper-elevation::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}

.rl-pricing-ordersummary .MuiPaper-elevation::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}

.rl-saving-total {
    margin-bottom: 0.2rem !important;
}

.rl-pricing-content {
    .rl-pricing-plans {
        align-items: center !important;
    }
    .pricingPlan__col__card {
        padding: 1rem 0rem !important;
    }
    .pricingPlan__col {
        &:first-of-type {
            .pricing_card {
                margin-top: 0px !important;
            }
        }
        &:nth-of-type(3) {
            .pricing_card {
                margin-top: 0px !important;
            }
        }
    }  
}

.rl-pricing-total {
    box-shadow: none !important;
    background-color: rgba(151,151,151,0.02) !important;
    .order-total {
        background-color: transparent !important;
        li {
            background-color: transparent !important;
            font-size: 3.8rem !important;
            font-weight: 700 !important;
            padding: 0px !important;
            line-height: 1.5 !important;
            p {
                color: $color-black !important;
                font-size: 3rem !important;
                font-weight: 500 !important;
            }
            .amount {
                margin-bottom: 2rem !important;
            }
        }
    }
}

@media (max-width:768px) {
    .rl-pricing-content {
        .MuiTabs-root {
            .MuiTab-root {
                font-size: 1.6rem !important;
            }
        }
        .pricing__getPrice {
            .zip-form {
                width: 100%;
                display: flex;
                // align-items: flex-end !important;
                label {
                    height: auto !important;
                    width: auto !important;
                    line-height: 0.6 !important;
                    margin-right: 5px !important;
                }
            }
            .sqft-form {
                width: 100%;
                display: flex;
                // align-items: flex-end !important;
                label {
                    height: auto !important;
                    width: auto !important;
                    line-height: 0.6 !important;
                    margin-right: 5px !important;
                }
            }
            .discount-form {
                width: 100% !important;
                display: flex !important;
                // align-items: flex-end !important;
                label {
                    height: auto !important;
                    width: auto !important;
                    line-height: 0.6 !important;
                    margin-right: 5px !important;
                }
            }
            .btn {
                margin-top: 20px;
                width: 50%;
            }
        }
        .pricing__title {
            display: none;
        }
    }
    .pricingPlan__col__card {
        margin-bottom: 15px !important;
    }
}
/* UI Changes */

// ======================================
// MOBILE RESPONSIVE
// ======================================
@media (max-width: 768px) {
    .pricing {
        &__tagline {
            &__grey {
                font-size: 2.1rem;
            }
            &__prime {
                font-size: 2.1rem;
            }
        }

        &__getPrice {
            display: block;
            padding-left: 0rem;
            padding-right: 0rem;

            &__form-group {
                display: block;
                &__label {
                    height: 4rem;
                }
            }

            .zip-form {
                width: 100%;
                .zip-lbl {
                    width: 100%;
                }
            }
            .sqft-form {
                // width: 100;
                .sqft-lbl {
                    width: 100%;
                    text-align: center;
                }
            }
            .discount-form {
                width: 100%;
                .discount-lbl {
                    width: 100%;
                }
            }
        }
    }
}

