.registerPage {
    padding-left: 0;

    &__head {
        display: flex;
        justify-content: space-between;
        padding-left: 4rem;
        padding-right: 4rem;

        .home-title {
            width: auto;
        }

        .back-arrow {
            font-size: 1.3rem;
            text-transform: capitalize;
            font-size: 1.4rem;
            width: 200px;
            span {
                margin-right: 0.8rem;
                color: $color-grey-dark;
            }
        }
    }

    &__leftbg {
        .left-banner {
            width: 100%;
            // padding-right: 3rem;
            height: 825px;
        }

        .auth-form {
            padding-left: 6rem;
            padding-right: 6rem;

            .home-subtitle {
                margin-top: 1rem;
            }

            .reg-upload-btn {
                font-size: 1rem !important;
                border: 1px solid transparent;
                padding: 0.7rem 1.4rem;
                margin-top: 2.1rem;
                text-transform: uppercase;

                &:focus {
                    box-shadow: none;
                    outline: none;
                    background-color: $color-tertiary-light;
                }
            }

            .accordion-body {
                padding: 1rem 0.25rem;
            }
        }
    }
}

/* UI Changes */

.rl-btn-table {
    visibility: hidden !important;
    padding-bottom: 0.8rem;
}
.rl_back_signup {
    margin-top: 25px !important;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    text-align: right;
    width: 100%;
}
// .reg-upload-btn {
//     margin-top: 0.9rem !important;
// }
.registerPage__leftbg {
    .auth-form {
        .reg-upload-btn {
            margin-top: 0rem !important;
            margin-left: 0px !important;
            margin-right: 0px !important;
            height: 4rem !important;
            display: inline-block !important;
        }
    }
}
.show-hide-icon {
    font-size: 1.4rem;
    cursor: pointer;
}
.registerPage__leftbg {
    .left-banner {
        height: 100%;
    }
    &.rl_register_block {
        // height: 100vh;
        // overflow-y: auto !important;
        .rl-radio-btn-block {
            position: relative;
            .MuiFormHelperText-root {
                position: absolute !important;
                margin-top: 15px;
                margin-left: -11px;
                font-size: 1.1rem;
                color: #d32f2f;
            }
        }
        .MuiChip-label {
            font-family: $font-family !important;
            font-size: 1.8rem !important;
        }
        .MuiAutocomplete-root {
            .MuiAutocomplete-tag {
                font-family: $font-family !important;
                font-size: 1.5rem;
            }
        }
        .MuiSelect-select {
            padding: 7px 12px !important;
            min-height: 1.4em !important;
            font-family: $font-family !important;
        }
        .form {
            label {
                font-family: $font-family !important;
                font-size: 1.6rem !important;
            }
            input {
                font-family: $font-family !important;
                // height: 3.8rem !important;
            }
            .btn {
                font-size: 1.3rem;
                letter-spacing: 1.8px;
                margin-top: 10px;
            }
        }
        .auth-form {
            margin-top: 0px !important;
            margin-bottom: 0px !important;
        }
        .home-subtitle {
            text-transform: none;
            font-weight: 600;
        }
        .flex_content {
            margin-left: 0px !important;
        }
        .form-group {
            margin-bottom: 0px;
            label {
                &::before {
                    content:" *";
                    color: #d32f2f !important;
                }
            }
            input {
                height: 3.5rem;
            }
            &.no-asterisk {
                label {
                    &::before {
                       content: "";
                    }
                }
                &.rl_radio_form {
                    margin-bottom: 0px !important;
                    label {
                        margin-left: 0px !important;
                        margin-right: 0px !important;
                    }
                    span {
                        padding: 0px !important;
                    }
                }
            }
        }
        .terms_of_use_text {
            font-size:1.2rem;
            margin-left: 10px;
            display: block;
            color: #18181b !important;
            a {
                color: #18181b !important;
            }
        }
        .reg-upload-btn {
            padding: 0.3rem 0rem !important;
            font-size: 0.7rem !important;
            // display: grid;
            // width: 100px;
            // max-width: 150px;
            width: 100% !important;
            font-family: $font-family !important;
            font-size: 1.2rem !important;
            .button-icon {
                font-size: 1rem !important;
                padding-bottom: 3px !important;
                padding-left: 0px !important;
            }
        }
    }
}
.MuiIconButton-root {
    padding: 0px !important;
}

// .rl_register_block .rl-dropdown-content {
//     margin-bottom: 1.5rem !important;
// }



.rl_register_block {
    // margin-bottom: 100px;
    .rl-dropdown-select {
               .MuiInputLabel-root {
                 padding-bottom: 0.3rem !important;
              }
              .rl-dropdown-list {
                  .css-1s2u09g-control {
                      height: 4.1rem;
                  }
                  .css-1pahdxg-control {
                      height: 4.1rem;
                  }
               }
           }
    .rl-dropdown-option {
        margin-bottom: 1rem;
    }
    .MuiFormControl-root {
        .MuiOutlinedInput-root {
            border-radius: 0px !important;
        }
    }
    .home-subtitle {
        font-size:3.2rem !important;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
            line-height: 0.9em !important;
        }
    }
    .rl-back-btn {
        font-size: 1.5rem !important;
    }
    .rl_radio_form {
        .MuiFormControlLabel-label {
            font-size: 1.6rem !important;
        }
    }
    .auth-form {
        display: flex;
        align-items: center;
    }
    .rl-register-content {
        .MuiFormLabel-root {
            color: #18181b !important;
        }
        .rl-dropdown-multilist {
            .multiSelectContainer {
                margin-top: -7px;
            }
        } 
        .rl_radio_form {
            .MuiFormControlLabel-label {
                margin-left: 8px !important;
            }
        }
        .MuiInputBase-root {
            color: #18181b !important;
        }
        width: 100%;
        .rl-dropdown-list {
            input {
                height: 2.9rem !important;
            }
        }
        .rl-btn-table {
            padding-bottom: 0px !important;
        }
    }
    .left-banner {
        height: 100% !important;
        min-height: 1000px;
        object-fit: cover;
    }

}

.css-2613qy-menu {
    z-index: 999999999 !important;
}
// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus,
// textarea:-webkit-autofill,
// textarea:-webkit-autofill:hover,
// textarea:-webkit-autofill:focus,
// select:-webkit-autofill,
// select:-webkit-autofill:hover,
// select:-webkit-autofill:focus {
//   border: none !important;
//   -webkit-text-fill-color: inherit;
//   -webkit-box-shadow: 0 0 0px 1000px transparent inset;
//   transition: background-color 5000s ease-in-out 0s;
// }

.rl-logo-row {
    align-items: flex-start;
    .MuiFormControl-root {
        margin-bottom: 0px !important;
    }
    .MuiAvatar-root {
        margin: 0px auto;
        margin-bottom: 5px;
    }
}

@media (max-width:768px) {
    .rl-logo-row {
        .MuiFormControl-root {
            margin-bottom: 1.5rem !important;
        }
    }
    .rl_register_block {
        height: auto !important;
        overflow-y: auto !important;
        // .rl-dropdown-content {
        //     margin-bottom: 20px !important;
        // }
        .rl_back_signup {
            display: none !important;
        }
        .reg-upload-btn {
            width: 100% !important;
            max-width: auto !important;
            margin-bottom: 20px;
        }
        .form {
            .btn-group {
                justify-content: center;
            }
            .btn-primary {
                width: 100%;
            }
        }
    }
}

.rl-register-content {
    .rl-dropdown-multilist {
        .search-wrapper {
            height: 48px !important;
        }
        .multiSelectContainer {
            margin-top: -8px !important;
        }
    } 
    .rl_radio_form {
        display: flex;
        align-items: center;
        .MuiCheckbox-root {
            margin-top: -1px !important;
        }
        .MuiFormControlLabel-root {
            padding: 0px !important;
        }
        .MuiFormControlLabel-label {
            margin-right: 5px !important;
            line-height: 1 !important;
        }
        input {
            height: auto !important;
        }
        a {
            margin-bottom: 5px;
            color: $color-tertiary-light !important;
            line-height: 1 !important;
        }
    }
}
/* UI Changes*/

// ======================================
// MOBILE RESPONSIVE
// ======================================
@media (max-width: 1200px) {
    .registerPage__leftbg {
        .left-banner {
            height: 665px;
        }
    }
}


@media (max-width: 992px) {
    .registerPage__leftbg {
        .left-banner {
            height: 620px;
        }
    }
}


@media (max-width: 768px) {
    .registerPage__leftbg {
        .auth-form {
            padding-left: 3rem;
            padding-right: 3rem;
            .home-subtitle {
                margin-top: 3rem;
            }
        }
    }
    .rl-register-content {
        .rl-logo-row {
            margin-bottom: 0px !important;
        }
    }
}