.order-summary {
    margin-bottom: 5rem;
    .orderSum-set {
        // margin-right: 0.8rem;
        // margin-left: 0.8rem;
        border: 2px solid $color-tertiary-light;
        // margin-bottom: 2rem;
        // padding-bottom: 2rem;
        min-height: 325px;
        .bg-lightGrey {
            background-color: $color-grey-light-1;
            margin-bottom: 1rem;
        }
        .greyTitle-icon {
            text-align: end;
            margin: auto 0;
            margin-right: 2rem;
            font-size: 1.6rem;
        }
        .greyTitle-price {
            // margin: auto 0;
            margin-right: 2rem;
            font-size: 2.4rem;
            font-weight: 500;
            color: $color-black;
            text-align: right;

            .price-unit {
                font-size: 1.3rem;
                vertical-align: super;
                margin-right: -0.3rem;
            }
        }

        .padding-xy {
            padding-left: 2rem;
            padding-right: 2rem;
            padding-bottom: 1rem;
        }

        .font-greyLabel {
            font-size: 1.4rem;
            color: $color-grey-dark;
            font-weight: 400;
            padding-bottom: 0.5rem;
            text-align: start;

        }
        .font-black500 {
            color: $color-black;
            font-size: 1.4rem;
            font-weight: 500;
            padding-bottom: 0.5rem;
            text-align: start;
        }

        .borderBottom {
            margin: 0 2rem 3rem;
            padding-bottom: 1rem;
            border-bottom: 2px solid $color-black;
        }
        .bottom-borderTitle {
            font-size: 1.8rem;
            font-weight: 500;
            color: $color-black;
        }

        .package-price {
            text-align: end;
            margin: auto 0;
            font-size: 2.4rem;
            font-weight: 500;
            color: $color-black;

            .price-unit {
                font-size: 1.3rem;
                vertical-align: super;
                margin-right: 0.3rem;
            }
        }

        .list-group-item {
            border: none;
        }

        .order-summary-rm-btn {
            background-color: $color-white;
            border-color: $color-white;
            color: $color-primary;
            padding: 0;
            border-radius: 0;
            text-transform: capitalize;

            &:hover {
                color: $color-black;
            }
        }

        .addon-total {
            margin-top: 3rem;

            .hr-devider {
                hr {
                    border: 0.2rem dashed $color-grey-dark-3;
                    border-style: none none dashed;
                    color: #fff;
                    background-color: #fff;
                    opacity: 1;
                }
            }

            .total-amount {
                text-align: end;
                margin: auto 0;
                font-size: 4.2rem;
                font-weight: 600;
                color: $color-black;

                .price-unit {
                    font-size: 1.8rem;
                    font-weight: 500;
                    vertical-align: super;
                    margin-right: -0.5rem;
                }
            }
        }
    }

    .form {
        .credit-select-option {
            display: inline;
            text-align: start;
            margin-bottom: 1.5rem;
            margin-right: 0.8rem;
            margin-left: 0.8rem;
            // margin: 2rem 35rem;
            padding: 2rem;
            border: 1px solid $color-grey-dark-2;

            .credit-icon {
                font-size: 1.4rem;
                margin-top: 1.4rem;
            }

            label {
                margin-right: 3rem;
            }

            .form-check-label {
                font-size: 1.4rem;
                padding-left: 0.5rem;

                .small-text {
                    font-size: 1.1rem;
                    padding-left: 0.3rem;
                    color: $color-grey-dark-2;
                }
            }

            .form-check-inline {
                font-size: 1.4rem;
                float: right;
            }
        }

        .orsum-billCardInfo {
            margin-top: 3rem;
            // display: flex;

            .orsum-billing {
                padding-right: 0.8rem;
            }

            .orsum-card {
                padding-left: 0.8rem;
            }

            .credit-modal-title {
                padding: 1.5rem;
                margin-bottom: 2rem;
                background-color: $color-grey-light-1;
                font-size: 1.8rem;
                font-weight: 400;
                color: $color-grey-dark-3;
            }
        }
    }

    .sub-para {
        color: $color-secondary;
    }

    .addon-total {
        margin-top: 3rem;

        h1 {
            margin-bottom: 0;
            padding: 10px;
            color: $color-primary;
            background-color: $color-grey-light-1;
            font-weight: 400;
        }

        .hr-devider {
            hr {
                border: 0.2rem dashed $color-grey-dark-3;
                border-style: none none dashed;
                color: #fff;
                background-color: #fff;
                opacity: 1;
            }
        }

        .total-amount {
            text-align: end;
            margin: auto 0;
            font-size: 6rem;
            font-weight: 400;
            color: $color-black;

            .price-unit {
                font-size: 2rem;
                font-weight: 400;
                vertical-align: super;
                margin-right: -0.8rem;
            }
        }
    }

}

.rl-order-total {
    padding-left: 2.8rem !important;
    .bgGrayTitle {
        padding-left: 0px !important;
    }
    .rl-color-block {
        color: #212529 !important;
    }
}

.credit-card-row  {
   .credit-modal-title {
        text-transform: uppercase !important;
   } 
   .form-group {
        label:before {
            color: red;
            content: " *";
        }
    }
    .MuiFormLabel-root {
        &.Mui-error {
            color: #18181b !important;
        }
    }

}

.rl-remember-button {
    .MuiCheckbox-root {
        padding-left: 0px !important;
    }
}

.rl-table {
    .rl-packages {
        
    }
}

.orsum-billing {
    .MuiFormControlLabel-label {
        margin-left: 10px !important;
    }
}

// ======= ORDER SUMMARY MOBILE RESPONSIVE =======

@media (max-width: 1400px) {

}

@media (max-width: 1200px) {

}

@media (max-width: 992px) {
    .order-summary {
        .bgGrayTitle {
            font-size: 2.2rem;
        }
    }
}

@media (max-width: 768px) {
    .rl-order-summary {
        .order-summary {
            .orderSum-set {
                width: 100% !important;
                min-width: 100% !important;
            }
        }
    }
    .rl-mls-btn {
        .mt-2 {
            display: inline-grid !important;
        }
    }
    .order-summary {
       // padding-left: 3rem;
        // padding-right: 3rem;
        .bgGrayTitle {
            font-size: 1.6rem;
        }
        .form {
            .credit-select-option {
                margin: 2rem 5rem;
            }

            .orsum-billCardInfo {
                display: block;
                .orsum-billing {
                    width: 100%;
                    padding: 0;
                    margin-bottom: 3rem;
                }
                .orsum-card {
                    width: 100%;
                    padding: 0;
                    margin-bottom: 3rem;
                }
            }
        }
    }
}

@media (max-width: 576px) {

}

/* UI Changes */
.order-history-content {
    margin-bottom: 12rem !important;
    .ag-theme-alpine {
        font-family: $font-family !important;
        .ag-root-wrapper {
            border: 0px !important;
        }
        .ag-paging-panel {
            border: 0px !important;
        }
        .ag-ltr {
            .ag-cell {
                color: $color-grey-dark-3 !important;
                font-size: 1.5rem !important;
                padding: 0px !important;
            }
        }
    }
    .ag-header-cell {
        padding: 0px !important;
    }
    .badge {
        background-color: transparent !important;
        color: inherit !important;
        font-size: 1.5rem !important;
        padding-left: 0px !important;
        font-weight: $medium-weight !important;
    }
    .ag-body-horizontal-scroll-viewport {
        overflow-x: none !important;
    }
    .ag-force-vertical-scroll {
        overflow: auto !important;
    }
    .ag-header-cell-text {
        font-weight: $semibold-weight !important;
        font-size: 1.6rem !important;
    }
    h1 {
        margin-bottom: 0px !important;
        background-color: $color-grey-light-3;
        padding: 8px 8px;
        text-align: center;
        color: #0e74be;
        font-weight: $semibold-weight !important;
        font-family: $font-family !important;
    }
    .ag-theme-alpine {
        .ag-header-cell-resize {
            &::after {
                background-color: transparent !important;
            }
        }
        .ag-header {
            background-color: $color-white !important;
            border-bottom-color: transparent !important;
        }
        .ag-row {
            border-color: transparent !important;
        }
    }
}
.rl-remove-text {
    text-align: left;
}
.rl-order-summary {
    // .back-btn {
    //     padding-left: ;
    // }
    .MuiTableCell-head {
        // font-size: 2rem !important;
    }
    .rl-back-btn {
        padding: 0px !important;
    }
    .rl-mls-btn {
        justify-content: flex-end !important;
    }
    .MuiTableCell-root {
        font-family: $font-family !important;
    }
    .MuiTableCell-root{
        font-family: $font-family !important;
    }
    .MuiTableCell-root {
        font-family: $font-family !important;
        font-size: 1.4rem;
        padding: 8px 16px !important;
    }
    .rl-remember-button {
        span {
            margin-left: 10px !important;
        }
        .MuiFormControlLabel-label {
            margin-left: 0px !important;
        }
    }
    margin-bottom: 5rem;
    .rl-greyTitle {
        margin-bottom: 0px !important;
        background-color: #e5e5e5;
        padding: 8px 8px;
        text-align: center;
        color: #0e74be;
        font-weight: 600 !important;
        margin-bottom: 20px !important;
        }
        // .rl-order__btn {
        //     text-align: left !important;
        //     // .btn {
        //     //     padding-left: 0px !important;
        //     // }
        // }
    .order-summary {
        .bgGrayTitle {
            color: $color-tertiary-light;
            font-family: $font-family !important;
            padding-bottom: 0px !important;
            padding-top: 0px !important;
            font-weight: 500 !important;
            font-size: 3.5rem !important;
            letter-spacing: 4px !important;
        }
        .rl-discount--color {
            color: $color-red !important;
        }
        .orderSum-set {
            .font-greyLabel {
                color: $color-black !important;
                font-weight: 600;
            }
            .font-black500 {
                color: $color-secondary !important;
            }
            .MuiTableCell-root {
                font-size: 1.8rem;
                font-weight: 500;

            }
            table {
                thead {
                    th {
                        font-size: 1.5rem;
                        color: $color-tertiary-light !important;
                        text-transform: uppercase;
                        font-weight: 600 !important;
                    }
                }
            }
        }
    }
    .rl-order-total {
        .bgGrayTitle {
            text-align: left
        }
    }

    .rl-delete-icon {
        color: $color-red;
        font-size: 1.5rem;
    }
}


.rl-order-summary {
    .orderSum-set  {
        .MuiPaper-elevation {
            box-shadow: none !important;
        }
        .rl-delete-icon {
            svg {
                cursor:  pointer !important;
            }
        } 
    }
    .MuiTableCell-root {
        border:none !important;
    }
    .rl-order-sum {
        .orderSum-set {
            //  border: none !important;
        }
    }
    .rl-order-sum-table {
        padding: 1rem 2rem !important;
    }
}

@media (max-width:768px) {
    .logged-out {
        .container-fluid {
            padding-left: 0px !important;
        }
    }
    .logged-out {
        padding: 24px !important;
    }
    .order-history-content {
        .ag-body-horizontal-scroll-viewport {
            overflow-x: auto !important;
        }
    }
}


.rl-card-checkbox  {
    .rl-card-radio {
        width: 50%;
        .MuiFormControlLabel-root {
            border: 0px !important;
        }
    }
}

.rl-order-sum-table {
    .MuiTableBody-root {
        tr {
            background: $color-white !important;
            th {
                background: $color-white !important;
            }
        }
    }
    .MuiTableHead-root {
        tr {
            background: $color-white !important;
            th {
                background: $color-white !important;
            }
        }
    }
}

.credit-form {
    .rl-card-checkbox {
        .rl-card-radio {
            .MuiFormControlLabel-root {
                padding-bottom: 0px !important;
                padding-top: 0px !important;
            }
        }
    }
}

.rl-card-info {
    .order-total {
        border-radius: 0px !important;
    }
    .list-group-item {
        padding: 10px 15px !important;
    }
    .rl-no-cards {
        text-align: center;
    }
}

.rl-cart-empty {
    text-align: center;
    box-shadow: 0 0 5px 0 rgba(0,0,0,.08)!important;
    padding: 20px 10px;
    h1 {
        font-family: $font-family !important;
        padding-bottom: 0px !important;
        font-size: 3rem !important;
    }
    h2 {
        font-family: $font-family;
        padding-top: 0px !important;
        font-size: 2rem !important;
        color: #1474be !important;
    }
    svg {
        color: rgba(0,115,198,0.76);
        font-size: 100px !important;
        margin-bottom: 15px;
    }
    .rl-btn-primary {
        margin-top: 15px !important;
    }
}
/*  UI Changes */