.thank-you {
  // max-width: 1320px;
  // margin-left: 9rem;
  .thankyou-bg {
    margin: 0rem 0 -3rem -12rem;
  }
  .icon-capture {
    padding: 8.5rem 2.3rem;
    border-radius: 50%;
    background: rgba(235, 238, 240, 0.25);

    .camera-span {
      padding: 6rem 2.6rem;
      border-radius: 50%;
      background: rgba(228, 234, 238, 0.3);

      .camera-icon {
        font-size: 8.5rem;
        padding: 2.5rem;
        border-radius: 50%;
        background: rgba(221, 230, 237, 0.5);
        color: #f26e21;
      }
    }
  }

  .orange-tagline {
    color: $color-primary;
    font-size: 1.4rem;
    font-weight: 400;
    padding-bottom: 0.5rem;
  }
  .grey-tagline {
    font-size: 2.1rem;
    font-weight: 600;
    color: $color-secondary;
    text-transform: uppercase;
    padding-bottom: 1rem;
  }

  .back-btn {
    color: $color-grey-dark-2;
    text-transform: capitalize;
    text-align: left;
    padding-left: 1rem;
    margin-top: 5rem;
    .button-icon {
      color: $color-grey-dark-2;
    }

    &:hover {
      background: none;
      border-color: unset;
      color: $color-primary;
      .button-icon {
        color: $color-primary;
      }
    }

    &:focus {
      box-shadow: none;
    }
  }

  .card-thankyou {
    // margin-right: -110px;
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.55);
    }

    .cardBody-thankyou {
      position: absolute;
      bottom: 0;
      padding: 20rem 0rem 1rem;
      margin: 4rem 0 4rem 4rem;
      border: 0.5rem solid $color-white;
      border-right: unset;
      width: 94%;

      .card-title {
        color: $color-white;
        padding: 1.5rem 3rem;
        font-size: 2.8rem;
        font-weight: 600;
        text-transform: uppercase;
        background-color: rgba(112, 112, 112, 0.75);
        margin-bottom: 2.5rem;
      }
      .card-text {
        font-size: 1.4rem;
        color: $color-white;
        background-color: $color-primary;
        padding: 1rem 3rem;
        margin-right: 20rem;
        border-top-right-radius: 0.2rem;
        border-bottom-right-radius: 0.2rem;
      }
    }
  }
}

/* UI Changes */
.rl-thankyou-content {
  background-color: $color-bg;
  .thankyou-text {
    background-color: $color-tertiary-light;
    margin-left: -15px;
    margin-right: -30px;
    padding: 10px;
    color: RGB(255 255 255 / 0.60);
    text-align: center;
    font-size: 1.6rem;
    font-weight: 500;
  }
  .rl-thankyou-block {
    margin-left: 9rem;
    margin-top: 6rem;
  }
  .thank-title {
    font-size: 7rem !important;
  }
  .grey-tagline {
    text-transform: inherit;
    font-weight: 400;
    line-height: 45px;
    font-size: 3.6rem;
    span {
      font-weight: 600;
      color: $color-black;
      font-size: 2,6rem;
    }
  }
  .thank-you {
    .orange-tagline {
      color: $color-tertiary-light;
      font-size: 2rem;
    }
    .back-btn {
      background-color: transparent !important;
      width: auto;
      padding-left: 0rem !important;
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
    .card-thankyou {
      .cardBody-thankyou {
        border:none !important;
        .card-text {
          background-color: $color-tertiary-light;
        }
        .card-title {
          background-color: transparent !important;
          font-size: 4.5rem;
          padding-left: 0rem !important;
        }
      }
    }
  }
}


@media (max-width:767px) {
  .rl-thankyou-content {
    background-color: $color-white;
    .rl-thankyou-block {
      margin: 0px !important;
    }
    .thank-you {
      .card-thankyou {
        .cardBody-thankyou {
          .card-text {
            display: none !important;
          }
        }
      }
      .thank-title {
        font-size: 4rem !important;
        text-align: center;

      }
      .orange-tagline {
        text-align: center;
        font-size: 1.5rem;
      }
      .grey-tagline {
        text-align: center;
        line-height: 20px;
        margin-top: 30px;
      }
      .back-btn {
        margin: 3rem auto !important;
      }
    }
  }
}
/* UI Changes */

// .container {
//   max-width: unset;
//   padding-right: 0;
//   padding-left: 5rem;
// }

// ======= THANKYOU MOBILE RESPONSIVE =======
// @media (min-width: 1400px) {
//   .container {
//     max-width: unset;
//     padding-right: 0;
//     padding-left: 5rem;
//   }
// }

// @media (max-width: 1400px) {
//   .thank-you {
//     .thankyou-bg {
//       margin-top: 0;
//     }

//     .thank-title {
//       margin-top: 2rem;
//     }

//     .card-thankyou {
//       .cardBody-thankyou {
//         padding-top: 12rem;
//         width: 93%;
//       }
//     }
//   }
// }

@media (max-width: 1200px) {
  .thank-you {
  }
}

@media (max-width: 992px) {
  .thank-you {
    .grey-tagline {
      font-size: 1.5rem;
      font-weight: 500;
    }
  }
}

@media (max-width: 768px) {
  .thank-you {
  }
}

@media (max-width: 576px) {
  .thank-you {
  }
}
