.property-detail-form {
  margin-top: 5rem;
  margin-bottom: 5rem;
  padding: 0rem 5rem;

  .select-agent {
    display: flex;

    .form-label {
      width: 38%;
      color: $color-primary;
      font-size: 1.6rem;
      margin: auto;
      text-transform: uppercase;
    }
    .form-select {
      width: 62%;
    }
  }

  input[type="file"] {
    // height: 4.8rem;
    // padding: 1.3rem;
    margin-bottom: 1rem !important;
  }

  .form-group {
    .upload-headshot {
    }

    .upload-logo {
      margin-left: 0;
    }

    .prm-contact-label {
      font-size: 1.6rem;
      text-transform: uppercase;
      color: $color-secondary;
      padding-right: 2rem;
      margin-bottom: 3rem;
    }
    .prm-contact-text {
      color: $color-grey-dark-2 !important;
    }

    .form-check {
      padding-right: 2rem;
      padding-top: 1rem;
      .form-check-label {
        font-size: 1.4rem;
      }
    }

    .form-label {
      font-size: 1.4rem;
      font-weight: 400;
    }
  }

  .form-title {
    font-size: 1.4rem;
    font-weight: 400;
  }

  .button-icon {
    font-size: 1.4rem;
    // color: $color-white;
    text-transform: capitalize;
  }

  .stepper-bottom {
    .next-btn {
      padding: 0.8rem 6rem;
    }
    .back-btn {
      background-color: $color-white;
      border: $color-white;
      color: $color-dark;
      .button-icon {
        color: $color-dark;
        padding-left: 0;
        padding-right: 0.8rem;
      }
    }
  }

  .pets-present-radio {
    .form-group {
      display: flex;
    }
    .form-label {
      margin-top: 1rem;
    }
  }

  .loc-relative {
    position: relative;
  }

  .confirm-set-location {
    margin-top: 2rem;

    position: absolute;
    left: 0;
    top: -10px;

    .input-group {
      height: 4.2rem;

      .input-group-text {
        font-size: 1.2rem;
        padding: 0.375rem 1.5rem;
      }
    }
  }

  .ordering-behalf-dropdown {
    padding: 2rem 2rem 1.5rem;
    margin-bottom: 1rem;
    background-color: $color-grey-light-2;

    .MuiFormControl-root {
      display: flex;
      flex-direction: row;
      margin-bottom: 0 !important;

      .MuiInputLabel-root {
        margin-top: auto;
        margin-bottom: auto;
        padding-bottom: 0;
        width: 18%;
      }

      .MuiOutlinedInput-root {
        width: 40% !important;

        .MuiSelect-select {
          z-index: 1;
        }

        .MuiSvgIcon-root {
          color: $color-dark;
          z-index: 10;
        }
      }

      fieldset {
        background-color: $color-white;
      }
    }
  }

  .search-address {
    .input-group {
      input {
        // padding-right: 0;
      }

      .input-group-text {
        border-right: 1px solid #ced4da;
      }
    }

    ul {
      box-shadow: 0 0.5rem 1rem RGB(0 0 0 / 0.15);
      padding: 1.5rem 2.5rem;
      margin-top: -8px;
      font-size: 1.2rem;
      line-height: 2rem;
    }
  }
}

/* UI Changes */
.rl-mls-user {
  .rl-info-svg {
      svg {
        color: #f26e21!important;
        cursor: pointer;
        font-size: 1.4rem!important;
        font-weight: 600!important;
      }
    } 
}

.rl-property-details {
  margin-bottom: 12rem !important;
  .greyBoldTagline {
    margin-bottom: 0px !important;
  }
  .rl-msl-description {
    margin-bottom: 0rem !important;
  }
  .bg-title {
    margin-bottom: 1.7rem;
  }
  .rl-mls-form {
    padding: 10px 30px;
    margin-bottom: 30px;
    border-radius: 3px;
    box-shadow: 0px 0rem 0.25rem RGB(0 0 0 / 0.1);
  }
  .rl-form-fstline {
    align-items: center;
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
  }
  .MuiFormControlLabel-root {
    margin-left: 0px !important;
  }
  .MuiFormHelperText-root {
    margin-left: 3px !important;
    margin-right: 0px !important;
  }
  .rl-install-date {
    .MuiButtonBase-root {
      &.MuiRadio-root {
        padding-left: 0px !important;
      }
    }
  }
  .rl-form-secline {
    col-md-9 {
      display: flex;
    }
    .rl-card-checkbox {
      margin-bottom: 0px !important;
    }
  }
  .mls-form-section {
    margin-left: 0px !important;
    margin-right: 0px !important;
    .MuiTypography-root {
      display: block !important;
      margin-left: 10px;
      font-family:$font-family !important;
      color: #212529 !important;
    }
  }
  .rl-form-colister {
    align-items: flex-start;
    .MuiFormControl-root {
        &.MuiTextField-root {
          margin-bottom: 0px !important;
        }
    }

  }
  .rl-ordering-behalf {
    .MuiFormControl-root {
      flex-direction: row !important;
    }
  }
  .MuiSelect-nativeInput {
    position: relative !important;
  }
  .rl-ordering-behalf {
    .MuiInputBase-root {
      &.MuiOutlinedInput-root {
        &.MuiSelect-root {
          margin-left: 20px !important;
        }
      }
    }
    .MuiInputBase-formControl {
      margin-left: 10px;
      width: 16%;
    }
  }
  .rl-contact-radio {
    .MuiFormGroup-root {
      justify-content: flex-start !important;
    }
  }
  .rl-time-content {
    .MuiFormControl-root {
      display: flex !important;
    }
    .MuiButtonBase-root {
      &.MuiRadio-root {
        &:first-child {
          padding-left: 0px !important;
        }
      }
    }
  }
  .rl-primary-contact {
    font-size: 1.3rem !important;
  }
  .rl-pets-present {
    fieldset {
      display: block;
    }
    .MuiFormControlLabel-root {
      &:first-child {
         margin-right: 10px !important;
      }
    }
  }
  .rl-pets-present {
    legend {
      width: auto;
    }
  }
  .rl-pets-present {
    .MuiButtonBase-root {
      &.MuiRadio-root {
        padding-left: 0px !important;
      }
    }
  }
  .rl-install-date {
    .MuiFormGroup-root {
      flex-wrap: inherit;
    }
    .MuiFormControlLabel-root {
      .MuiButtonBase-root {
        margin-right: 10px !important;
      }
    }
  }
  .rl-appttime-text {
    font-size: 1.6rem !important;
    font-family: $font-family !important;
    color: #212529 !important;
    margin-bottom: 20px;
    .MuiFormControl-root {
      display: block !important;
    }
    .MuiFormGroup-row {
      display: flex;
    }
    // &::before {
    //   content:"*";
    //   color: #d32f2f !important;
    // }
  }

  .rl-time-content {
    .rl-card-checkbox {
      display: inline-flex !important;
    }
    .MuiFormLabel-root {
      &::before {
        content:"*";
        color: #d32f2f !important;
      }
    }
  }

  .rl-mls-btn {
    // position: absolute;
    // bottom: 0px;
    // right: 0px;
    justify-content: flex-end;
    display: flex;
    .btn {
      display: block !important;
      width: 100%;
      // margin-right: 0px !important;
      // font-size: 1.4rem !important;
    }
  }
  .rl-confirm-location {
    position: relative;
  }
}

.rl-time-content {
  .rl-primary-contact {
    padding-left: 8px !important;
    padding-top: 0px !important;
  }
  .rl-card-radio {
    .MuiRadio-root  {
        &.Mui-disabled {
          color: rgba(0, 0, 0, 0.7) !important;
        }
    }
  }
}

.rl-mls-details-form {
  border: 2px solid #1474be!important;
  margin-bottom: 20px !important;
  .rl-mls-form {
    margin-bottom: 0px !important;
  }
}

.rl-form-colister {
  .mt-4 {
    margin-top: 1.99rem !important;
  }
  .rl-info-svg {
    svg {
      color: #f26e21!important;
      cursor: pointer;
      font-size: 1.4rem!important;
      font-weight: 600!important;
    }
  }
}

.rl-mls-form {
  .greyBoldTagline {
    font-weight: 400 !important;
    margin-bottom: 1.2rem !important;
  }
  .rl-form-fstline {
    .MuiFormGroup-root {
      flex-flow: nowrap !important;
    }
    .MuiTypography-root {
      margin-left: 0px !important;
    }
  }
  .rl-form-secline {
    margin-bottom: 0rem !important;
    .MuiTypography-root {
      margin-left: 0px !important;
    }
  }
  .rl-card-radio {
    width: auto !important;
    .MuiButtonBase-root {
      padding-left: 0px !important;
    }
  }
}

.rl-rent-option {
  .rl-card-checkbox {
    margin-bottom: 0px !important;
    .rl-card-radio {
      // width: 100% !important;
      &:first-child {
        .MuiRadio-root {
          padding-left: 0px !important;
        }
      }
    }
  }
  .MuiFormControlLabel-label {
    margin-left: 0px !important;
  }
  .MuiFormGroup-row {
    flex-flow: row !important;
  }
  .rl-card-checkbox {
    display: flex !important;
  } 
}

.rl-virtualtwilight-content {
  .virtual-twilight-btn {
    padding: 0px !important;
  }
  p {
    font-size: 1.7rem !important;
  }
  h1 {
    font-family: $font-family !important;
  }
  .rl-photo-title {
    font-size: 1.4rem !important;
    font-family: $font-family;
      &.rl-photo-total {
        font-weight: 600;
        font-size: 1.6rem !important;
        color: $color-black !important;
      }
  }
  .rl-photo-counter {
      display: flex;
      align-items: center;
      justify-content: center;
      .rl-photo-decrement {
        font-size: 1.8rem !important;
      }
      .rl-photo-increment {
        font-size: 1.8rem !important;
      }
      span {
        font-size: 1.8rem !important;
      }
  }
}

.rl-confirm-location {
  .MuiButtonBase-root {
    padding-left: 0px !important;
  }
  .MuiFormGroup-row {
    display: block !important;
  }
  .rl-card-checkbox {
    .rl-card-radio {
      width: 100% !important;
      display: block !important;
      .MuiTypography-root {
        font-size: 1.8rem !important;
      }
    }
  } 
}

.rl-contact-radio {
  .MuiFormGroup-row {
    display: block !important;
  }
  .MuiFormControlLabel-label {
    font-size: 1.6rem !important;
  }
}
/* UI Changes */
// ======= PROPERTY-DETAIL MOBILE RESPONSIVE =======

@media (max-width: 992px) {
  .property-detail-form {
    padding: 0;
  }
}

@media (max-width: 768px) {
  .rl-form-colister {
    width: 100%;
    // display: block !important;
    .btn {
      width: 100%;
      padding: 1.6rem !important;
    }
    .btn-group {
      width: 100% !important;
    }
  }
  .rl-property-details {
    .rl-mls-btn {
      position: relative !important;
      .btn {
        // font-size: 2.5rem !important;
      }
    }
  }
  .property-detail-form {
    .select-agent {
      display: block;
      .form-select {
        width: 100%;
      }
    }
    .greyBoldTagline {
      text-align: center !important;
    }
    .form-group {
      .form-label {
        margin-bottom: 1rem;
      }
      .form-check-inline {
        display: block;
        padding-bottom: 1rem;
      }
    }

    .pm-contact {
      display: grid;
      padding-bottom: 2rem;

      .form-label {
        margin-bottom: 0.5rem;
      }
    }

    .pm-contact {
      padding-top: 2rem;
      .form-label {
        font-size: 1.6rem;
      }
    }

    .pets-present-radio {
      text-align: center;
      .form-check {
        .form-check-input {
          float: none;
        }
      }

      .pets-radio-option {
        display: flex;
        justify-content: center;
      }

      .form-group {
        display: block;
      }
    }

    .ordering-behalf-dropdown {
      .MuiFormControl-root {
        .MuiInputLabel-root {
          width: 45%;
        }

        .MuiOutlinedInput-root {
          width: 55% !important;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .property-detail-form {
    padding: 0 3rem;

    .ordering-behalf-dropdown {
      .MuiFormControl-root {
        .MuiInputLabel-root {
          width: 60%;
        }

        .MuiOutlinedInput-root {
          width: 40% !important;
        }
      }
    }
  }
}
