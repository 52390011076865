.banner {
  margin-bottom: 5rem;
  &__text-box {
    &--title {
      background-color: $color-tertiary-light;
      color: $color-white;
      padding: 1rem 2rem;
      text-transform: uppercase;
      font-size: 1.6rem;
    }
    &--description {
      font-size: 1.4rem;
      line-height: 3rem;
      padding: 2rem 3rem 1.5rem 1.5rem;
    }
  }
  &__image-box {
    &--image {
      object-fit: cover;
      width: 52rem;
      height: 30rem;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      border-radius: 0;
    }

    .offer-image {
      object-fit: cover;
      width: 100%;
      height: 280px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      border-radius: 0;
      opacity: 0.8;
    }
  }

  .card {
    height: 280px;
    border: none;

    .card-body {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 10;

      .card-title {
        font-size: 1.8rem;
        color: $color-tertiary-light;

        .card-text {
          
        }

      }

      .offer-value {
        display: flex;
        // margin-top: 10px;
        position: relative;

        .actual-price {
          font-size: 4.8rem;
          span {
            font-size: 1.8rem;
            vertical-align: super;
          }
        }
        .duplicate-price {
          font-size: 1.8rem;
          color: $color-grey-dark-2;
          position: absolute;
          left: 50%;
          bottom: 20%;
          text-decoration: line-through;
          span {
            font-size: 1.2rem;
            vertical-align: super;
          }
        }

        p {
          margin-bottom: 0;
        }
      }

      .icon-dron {
        font-size: 1.3rem;
        color: $color-tertiary-light;
        span {
          color: $color-dark;
        }
      }
    }

    .overlay-img {
      // background: linear-gradient(53.87deg, rgba(4, 4, 4, 0.4), transparent);
      background: linear-gradient(90deg, RGB(255 255 255 / 0.0), #ffffff);
      height: 100%;
      left: 0;
      position: absolute;
      right: 0px;
      top: 0;
      z-index: 0;
    }
  }
}

/* UI Changes */
.rl-banner-text {
  .bold-text {
    font-weight: 600;
  }
}

/* UI Changes */

// ======================================
// MOBILE RESPONSIVE
// ======================================
@media (max-width: 768px) {
  
  .banner {
    &__text-box { 
      margin-bottom: 3rem;
    }
    .card {
      height: auto;
    }
  }
}