.rl-service-pricing {
  .MuiOutlinedInput-notchedOutline {
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
  }
} 

.mt-6 {
  margin-top: 6rem !important;
}

.form {
  .form-group {
    margin-bottom: 1rem;
    .form-label {
      font-size: 1.4rem;
      font-weight: 400;
    }
    .form-control {
      font-size: 1.4rem;
      height: 4.8rem;
      margin-bottom: 0.5rem;
      &:focus {
        box-shadow: none;
      }
    }
    .form-control.is-invalid {
      border-color: $color-primary;
    }
    .form-select {
      font-size: 1.4rem;
      height: 4.8rem;
      margin-bottom: 0.5rem;
    }

    .form-check {
      font-size: 1.3rem;
      font-weight: 500;
      .form-check-input {
        width: 1.4rem;
        height: 1.4rem;
        margin-right: 1rem;
        // .form-check-label {
        // }
      }
    }

    .form-check-input {
      width: 1.4rem;
      height: 1.4rem;
      margin-right: 1rem;
    }

    .upload-btn {
      margin-top: 2.5rem;
    }
    .upload-headshot {
      display: grid;
      font-size: 1rem;
      height: 4.6rem;
      padding-top: 0.3rem;
      span {
        font-size: 1.4rem;
      }
    }
    .upload-logo {
      display: grid;
      font-size: 1rem;
      // margin-left: 1.4rem;
      height: 4.6rem;
      padding-top: 0.3rem;
      span {
        font-size: 1.4rem;
      }
    }

    .input-group-text {
      height: 4.8rem;
      background-color: $color-white;
      border-right: none;
      .addon-icon {
        font-size: 2rem;
        color: $color-grey-dark-2;
      }
    }

    input[type="file"] {
      height: 4.8rem;
      padding: 1.3rem;
    }
  }

  .eye-icon {
    display: flex;
    align-items: center;
    .show-hide-icon {
      position: absolute;
      right: 3%;
      top: 21%;
      font-size: 1.6rem;
      color: $color-secondary;
      cursor: pointer;
    }
  }

  .show-hide-icon {
    cursor: pointer;
  }

  .login__cta--forgot-password {
    font-size: 1.5rem;
    text-transform: capitalize;
    padding-left: 0;
    margin-bottom: 3rem;
    display: block;
    margin-top: -1.5rem;
  }
  .login__cta--register {
    font-size: 1.2rem;
    .register__link {
      font-size: 1.3rem;
      text-transform: capitalize;
      padding-left: 1rem;
    }
  }
  .login__cta--signin {
    display: block;
    // margin-bottom: 2rem;
  }

  .form-text {
    font-size: 1.2rem;
    color: $color-danger !important;
  }
}

.rl-common-content {
  .rl-table-search {
    &.rl-tab-service {
      margin-right: auto !important;
      margin-left: 0px !important;
    }
  }
} 


.custom_link {
  text-decoration: none;
}

.custom_link:hover {
  text-decoration: underline;
}

.form-check {
  font-size: 1.3rem;
  font-weight: 500;
  .form-check-input {
    width: 1.4rem;
    height: 1.4rem;
    margin-right: 1rem;
    // .form-check-label {
    // }
  }
}

.select-agent {

  label {
    font-size: 1.4rem;
    font-weight: 400;
    padding-bottom: 0.8rem;
    transform: none;
    color: $color-dark;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
  }
}

.mb-6 {
  margin-bottom: 4rem !important;
}

.rl-tab-block {
  .MuiTab-root {
    padding: 10px !important;
    margin-right: 10px !important;
    font-family: $font-family !important;
  }
  .MuiBox-root {
    &.css-19kzrtu {
      padding: 24px 0px !important;
    }
  }
  .container {
    padding: 0px !important;
  }
}

.rl-no-required {
  label {
    &:before {
      content: " " !important;
    }
  } 
}
/* UI */

.rl-radiobutton-list {
  .MuiFormControlLabel-label {
    font-family: $font-family !important;
  }
  &.form-group {
    margin-bottom: 0px !important;
  }
}

.input:-internal-autofill-selected {
  background-color: #ffffff !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: inherit;
  box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

.flex_center {
  display: flex;
  align-items: center;
}

.flex_start {
  display: flex;
  align-items: flex-start;
}

.flex_end {
  display: flex;
  align-items: flex-end;
}

.flex_justify_center {
  display: flex;
  justify-content: center !important;
}

.flex_justify_end {
  display: flex;
  justify-content: flex-end !important;
}

.rl-address-dropdown {
  input {
    height: 2.2rem !important;
  }
}

.rl-checkbox {
  .MuiFormControlLabel-root {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .css-j204z7-MuiFormControlLabel-root {
    .row {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
  }
  p {
    display: none;
  }
}

.MuiFormControl-root {
  .MuiInputLabel-root {
    font-family: $font-family !important;
    pointer-events: none !important;
  }
}

.roc-mui {
  .MuiOutlinedInput-root {
    input {
      font-family: $font-family !important;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    // input[type="number"] {
    //   // -moz-appearance: textfield;
    // }
  }
}

.rl-add-agent-rider {
  .button-icon {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .row {
    &.flex_center {
      padding-top: 5px !important;
      padding-bottom: 5px !important;
    }
  }
}

.css-j204z7-MuiFormControlLabel-root {
  .MuiFormControlLabel-label {
    font-family: $font-family !important;
    font-size: 1.6rem !important;
  }
}

.MuiInputBase-input {
/* Hide the up and down arrows in number input */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button,
input[type="number"] {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}


  &.MuiOutlinedInput-input {
    font-family: $font-family !important;
    font-size: 1.7rem !important;
    
  }
}

.rl-linked-table {
  .MuiCheckbox-root {
    padding: 0px !important;
    color: $color-tertiary-light !important;
  }
  .MuiSvgIcon-root {
    width: 1.5em !important;
    height: 1.5em !important;
  }
}
// /*23*/
// .MuiInputBase-root {
//   font-family: $font-family !important;
//   background-color: #ffffff!important;
// }
// /*23*/

.css-viou3o-MuiAutocomplete-root {
  .MuiAutocomplete-input {
    font-family: $font-family !important;
  }
}

.rl-c-dropdown {
  .MuiFormControl-root {
    margin-bottom: 0px !important;
  }
  .MuiSelect-select {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
}

.rl-price-order {
  font-family: $font-family !important;
}

.MuiAutocomplete-popper {
  font-family: $font-family !important;
}

.MuiInputBase-root {
  &.MuiOutlinedInput-root {
    font-family: $font-family !important;
  }
}

.MuiFormControl-root {
  legend {
    font-family: $font-family !important;
  }
}

.css-1v994a0 {
  font-family: $font-family !important;
}
.css-1w13o7u-MuiTypography-root {
  font-size: 1.2rem !important;
  font-family: $font-family !important;
}
.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root {
  font-size: 1.2rem !important;
  font-family: $font-family !important;
}
.css-195y93z-MuiButtonBase-root-MuiPickersDay-root {
  &:not(.Mui-selected) {
    font-size: 1.2rem !important;
    background-color: RGB(20 116 190 / 0.95) !important;
    color: $color-white !important;
    border-color: $color-tertiary-light !important;
    font-family: $font-family !important;
  }
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  font-family: $font-family !important;
  font-size: 1.4rem !important;
}

.btn-check {
  &:focus {
    + .btn-primary {
      box-shadow: none;
    }
  }
  &:active {
    + .btn-primary {
      &:focus {
        box-shadow: none;
      }
    }
  }
  &:checked {
    + .btn-primary {
      &:focus {
        box-shadow: none;
      }
    }
  }
}
.btn-primary {
  &:focus {
    box-shadow: none;
  }
}

.btn-primary {
  &.active {
    &:focus {
      box-shadow: none;
    }
  }
  &:active {
    &:focus {
      box-shadow: none;
    }
  }
}

.show > {
  .btn-primary {
    &.dropdown-toggle {
      &:focus {
        box-shadow: none;
      }
    }
  }
}
.rl-trash-none {
  display: none !important;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 0px !important;
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase {
  &.Mui-checked {
    color: $color-blue !important;
  }
}

.rl-admin-form {
  .MuiGrid-root {
    &.MuiGrid-item {
      font-size: 1.4rem !important;
      color: $color-blue !important;
    }
  }
  .MuiFormControlLabel-root {
    &.MuiFormControlLabel-labelPlacementEnd {
      margin: 0px !important;
    }
  }
  .MuiButtonBase-root {
    &.MuiSwitch-switchBase {
      color: $color-blue;
    }
  }
  .MuiSwitch-track {
    background-color: $color-white !important;
    box-shadow: inset 0 0 1px 2px RGB(0 0 0/0.13) !important;
  }
  .MuiSwitch-root {
    .MuiSwitch-switchBase {
      &.Mui-checked {
        + .MuiSwitch-track {
          background-color: $color-white !important;
          box-shadow: inset 0 0 1px 2px RGB(0 0 0 / 0.13) !important;
        }
      }
    }
  }
}

.rl-table-header {
  &.rl-common-content {
    background-color: $color-white !important;
    box-shadow: none !important;
    padding: 0px !important;
    .ag-header-row {
      background-color: $color-white !important;
    }
  }
}

.btn {
  text-transform: capitalize !important;
}

.btn-primary {
  text-transform: capitalize !important;
}

.home-subtitle {
  text-transform: capitalize !important;
}

.rl-btn-primary {
  @include button-color(
    $color-white,
    $color-tertiary-light,
    $color-tertiary-light
  );
}

.rl-btn-secondary {
  @include button-color(
    $color-tertiary-light,
    $color-white,
    $color-tertiary-light
  );
}

.rl-text-right {
  text-align: right !important;
}

.rl-printing-content {
  .form-check-input {
    margin-top: 2px !important;
  }
  .form-check-inline {
    margin-right: 3.5rem !important;
  }
}

.credit-select-option {
  .MuiFormControl-root {
    width: 100%;
  }
  .MuiFormControlLabel-labelPlacementStart {
    width: 95% !important;
    justify-content: space-between !important;
  }
  .rl-card-checkbox {
    .rl-card-radio {
      padding: 10px 15px;
      border: 1px solid rgba(0, 0, 0, 0.23);
    }
  }  
  .MuiFormGroup-root {
    justify-content: flex-start !important;
  }
  .MuiFormControlLabel-root {
    width: 50%;
    border: 1px solid RGB(0 0 0 / 0.35);
    border-radius: 0px !important;
    flex: 0 0 auto;
    width: 50%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
    padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
    margin-top: var(--bs-gutter-y);
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .MuiFormHelperText-root {
    display: none;
  }
  .MuiFormGroup-root {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    // margin-right: calc(-0.5 * var(--bs-gutter-x));
    // margin-left: calc(-0.5 * var(--bs-gutter-x));
  }
}

.rl-checkbox-block {
  .MuiFormGroup-root  {
    display: block;
    flex-flow: initial;
  }
  .MuiCheckbox-root {
    cursor: pointer;
    pointer-events: initial;
  }
  .MuiFormControlLabel-label {
    pointer-events: none;
  }
  .MuiFormControlLabel-root {
    cursor: none !important;
    pointer-events: none !important;
  }
}

.rl-common-content {
  background-color: #fff !important;
  box-shadow: 0 0 5px 0rem RGB(0 0 0/0.08) !important;
  padding: 20px !important;
  .input-group {
    margin-bottom: 0px !important;
  }
  .rl-delete-icon {
    svg {
      color: $color-red;
      font-size: 2rem !important;
    }
  }
  .rl-table-search {
    margin-left: auto !important;
    .input-group {
      border: 1px solid #ced4da ;
      // border-radius: 0.375rem;
      align-items: center;
    }
    input {
      height: 3.8rem !important;
      position: relative;
      border: 0px !important;
      font-size: 1.9rem !important;
      padding: 0.275rem 0.75rem !important;
      font-family: $font-family !important;
      &:focus {
        box-shadow: none !important;
      }
    }
    svg {
      font-size: 16px !important;
      margin-right: 12px !important;
      margin-left: 5px !important;
    }
  }
}

.css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 0px !important;
}
.ag-paging-panel {
  justify-content: space-between !important;
}

.rl-admin-heading {
  display: flex;
  align-items: center;
  margin-bottom: 3rem !important;
  span {
    display: block;
  }
  .rl-admim-title {
    font-size: 2.5rem !important;
    color: $color-black !important;
    display: block;
    line-height: 0.9;
    margin-top: 8px;
    font-family: $font-family !important;
  }
}

.rl-table-header {
  h1 {
    margin-bottom: 0px;
    color: #000000 !important;
    font-size: 2.2rem;
    font-weight: 600;
    font-family: $font-family !important;
  }
}

.rl-custom-form {
  background-color: #fff !important;
  box-shadow: 0 0 5px 0rem RGB(0 0 0/0.08) !important;
  padding: 20px !important;
}

.rl-service-type {
  .MuiSelect-select {
    height: 3.5rem !important;
    padding: 0 1.2rem 0.2rem !important;
  }
  .MuiSelect-select {
    &.MuiSelect-outlined {
      line-height: 3.2rem !important;
    }
  }
  &.rl-select-noinput {
     .MuiInputBase-inputAdornedEnd {
        padding: 0 1.2rem .2rem!important;
     }
  }
}

.rl-dropdown-list {
  cursor: pointer;
}
.MuiSelect-select {
  text-align: left;
}
.rl-dropdown-content {
  .css-1pahdxg-control {
    height: 48px !important;
  }

  .rl-dropdown-list {
    div {
      min-height: auto !important;
    }
  }
  .MuiFormControl-root {
    input {
      padding: 0px !important;
      min-height: auto !important;
    }
  } 
  .MuiSelect-select {
    &.MuiSelect-outlined {
      &.MuiInputBase-input {
        padding-top: 8px;
        padding-bottom: 8px;
        border-radius: 0px !important;
      }
    }
  }
  .MuiInputBase-root {
    &.MuiOutlinedInput-root {
      border-radius: 0px !important;
    }
  }
  .MuiFormControl-root {
    input {
      height: 3.5rem !important;
    }
  }
  .roc-mui {
    .MuiOutlinedInput-root {
      input {
        height: 3.5rem !important;
        padding: 0 1.2rem 0.2rem !important;
      }
    }
  }
  .rl-btn-add-market {
    margin-top: 0.8rem !important;
  }
}

.rl-total-price {
  line-height: 28px;
  font-weight: 500 !important;
}

.rl-price-count {
  font-size: 3.5rem !important;
}

.rl-admin-table {
  .ag-header {
    background-color: $color-white !important;
  }
  .ag-theme-alpine {
    .ag-row {
      border-color: transparent !important;
    }
    .ag-row-odd {
      background-color: #f3f4f6 !important;
    }
  }
  .MuiCheckbox-root {
    .MuiSvgIcon-root {
      width: 1.5em;
      height: 1.5em;
    }
  }
}

.rl-upload-image {
  .modal-title {
    margin-top: -48px !important;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 20px !important;
  }
  .form-check {
    padding-left: 0px !important;
    .form-check-input {
      margin-left: 0px !important;
    }
  }
  .upl-image-crop {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }
  .prev-box {
    margin-top: 2rem;
    // margin-bottom: 2rem;
  }
  .crop-box {
    margin-top: 2rem;
    // margin-bottom: 2rem;
  }
}

input[type="file"] {
  height: 3.5rem;
  font-size: 1.3rem !important;
  width: 100%;
  font-family: $font-family !important;
}

input[type="file"]::-webkit-file-upload-button {
  height: 3.5rem;
  font-size: 1.3rem !important;
}

.pickup-address-content {
  .rl-dropdown-content {
    .MuiFormControl-root {
      input {
        padding: 0 1.2rem 0.2rem !important;
      }
    }
  }
}

/*23*/
.rl-admin-table {
  .MuiTableCell-root {
    font-size: 2rem;
    font-weight: 700;
    font-family: $font-family !important;
    padding: 8px 6px !important;
  }
  thead {
    tr {
      vertical-align: initial !important;
    }
  }
  .bg-info {
    font-size: 2rem !important;
  }
  .bg-success {
    font-size: 2rem !important;
  }
  .MuiTableCell-root {
    font-size: 2rem;
    font-family: $font-family !important;
    padding: 8px 6px;
    border: 0px !important;
  }
  .btn-primary {
    //padding: 0px !important;
  }
}
/*23*/

.roc-mui {
  .MuiOutlinedInput-root {
    input {
      height: 3.5rem !important;
      padding: 0 1.2rem 0.2rem !important;
      font-size: 1.4rem;
    }
  }
}

.rl-select-noinput {
  .MuiSelect-nativeInput {
    display: none !important;
  }
  .MuiIconButton-label {
    margin-right: 30px;
  }
  .MuiInputBase-root {
    padding-right: 0px !important;
  }
  .MuiInputBase-inputAdornedEnd {
    padding: 7px 12px !important;
    display: flex;
    align-items: center;
  }
  .rl-dropdown-close {
    position: absolute;
    right: 23px !important;
    cursor: pointer;
    svg {
      font-size: 1.4rem !important;
    }
  }
}

.rl-table-seelist {
  .btn {
    font-size: 2rem !important;
  }
}

.rl-video-tool {
  background-color: #2b333f !important;
  .btn {
    background-color: #2b333f !important;
    border-color: #2b333f !important;
    display: flex;
    align-items: center;
  }
}

.rl-addon-help-text {
  text-align: right;
  display: block;
}

.banner__text-box--title {
  font-family: $font-family !important;
  color: $color-white !important;
}

.rl-banner-desc {
  padding: 1rem 2rem !important;
  font-family: $font-family !important;
  font-size: 1.3rem !important;
}

.rl-intents-input {
  .MuiFormControl-root  {
    margin: 0px !important;
  }
  .MuiSelect-select {
    padding-top: 6px !important;
  }
}

.rl-intents {
  display: flex !important;
  &#menu- {
    .MuiButtonBase-root {
      display: flex !important;
    }
  } 
  .MuiTypography-root {
    font-family: $font-family !important;
    font-size: 1.6rem !important;
  }
}

.rl-textarea {
  position: relative;
  padding: 0px !important;
  .rl-textarea-count {
    // position: absolute;
    display: block;
    text-align: end;
    // right: 6px;
    // bottom: 16px;
  }
}

.rl-brokerage-block {
  .form-group {
    margin-bottom: 0px !important;
  }
}

.rl-dropdown-multilist {
  margin-bottom: 0px !important;
  .form-group {
    margin-bottom: 0px !important;
  }
  .MuiFormControl-root {
    margin-bottom: 0px !important;
  }
  .rl-error-text {
    color: #d32f2f !important;
    font-size: 1.1rem !important;
  }
  .chip {
    background-color: #1474be;
    padding: 4px 6px;
    border-radius: 2px;
    margin-bottom: 5px !important;
  }
  .multiSelectContainer {
    margin-top: -5px !important;
    li {
      &:hover {
        background-color: #1474be !important;
      }
    }
  }
  .searchWrapper {
    border-radius: 0px !important;
    input {
      height: 2.9rem !important;
      font-family: $font-family !important;
      font-size: 1.7rem !important;
    }
  }
  .optionContainer {
    border-radius: 0px !important;
    .option {
      display: flex;
      align-items: center;
      background-color: transparent !important;
      padding: 0px 10px !important;
      height: 40px;
        &.highlightOption {
          &.highlight {
            background-color: #ffffff !important;
            color: #212529!important;
          }
        } 
    }
  }
  .search-wrapper {
    height: 47px;
    overflow-y: auto;
  }
}

.rl-dropdown-select {
  .form-label {
    font-size: 1.6rem !important;
    margin-bottom: 0px;
    padding-bottom: 2px;
  }
  .rl-dropdown-list {
    // z-index: 99;
    .css-1s2u09g-control {
        height: 48px !important;
    }
  }
}

.rl-add-btn {
    padding-top: 0.65rem !important;
   padding-bottom: 0.65rem !important;
}
  
.rl-list-error {
  .css-1s2u09g-control {
    border: 1px solid rgba(211, 47, 47,1);
  }
}

.rl-list-error {
  .css-1yqqxd8-control {
      border: 1px solid rgba(211, 47, 47,1);
  }
  .css-3ysvgk-control {
    border: 1px solid rgba(211, 47, 47,1);
  }
}

.rl-custom-brokerage {
  .rl-select-noinput {
     .MuiSelect-select {
        display: flex !important;
        height: auto !important;
      }
  }
}

.rl-custom-modal {
  .rl-brokerage-block {
    .MuiSelect-select {
      display: flex !important;
      height: auto !important;
    }
  }
  .rl-nodata-content {
    margin-bottom: 10px !important;
  }
  &.rl-credit-edit-form {
    .rl-cardholder-input {
      margin-top: 1rem !important;
      margin-bottom: 1.4rem !important;
    }
    .rl-address-details-input {
      margin-top: 1rem !important;
    }
    .rl-remember-button {
      .MuiFormControlLabel-root {
        margin-left: 0px !important;
      }
    }
    .btn {
      margin-bottom: 20px;
    }
  }
}

.rl-modal-btn {
  display: block !important;
}

.rl-cardholder-input {
  margin-top: 4.3rem !important;
}

.rl-mt-5 {
  margin-top: 2.6rem !important;
}

.rl-market-btn {
  button {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    height: 4.2rem !important;
    display: flex;
    margin-top: 0px !important;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

.rl-brokerage-input {
  .MuiFormLabel-root {
    z-index: -1;
  }
  // z-index: 99;
  .MuiInputLabel-root {
    padding-bottom: 0.3rem !important;
 }
 .rl-dropdown-list {
     .css-1s2u09g-control {
         height: 4.2rem !important;
     }
     .css-1pahdxg-control {
         height: 4.2rem !important;
     }
  }
}

.rl-admin-home-section {
  img {
     height: 8rem!important;
    max-height: 8rem!important;
    max-width: 8rem!important;
    object-fit: contain;
    width: 8rem;
    border: 1px solid rgba(0,0,0,.08)!important;
    object-fit: cover!important;
    border-radius: 50%;
    padding: 12px;
  }
}

.MuiFormControl-root {
  input {
    height: 3.5rem !important;
    padding: 0 1.2rem 0.2rem !important;
    font-size: 1.4rem;
  }
}

.rl-custom-pagination {
  .MuiPagination-ul {
    justify-content: right;
  }
  .MuiPaginationItem-rounded {
    border-radius: 0px !important;
  }
  .MuiPaginationItem-icon {
    font-size: 2rem !important;
  }
  .MuiPaginationItem-root {
    font-size: 1.4rem !important ;
    font-family: $font-family !important;
  }
  .MuiPaginationItem-page {
    &:hover {
      background-color: rgba(20, 116, 190, 0.1);
    }
  }
  .MuiPaginationItem-textPrimary {
    &.Mui-selected {
      background-color: $color-tertiary-light !important;
      &:hover {
        background-color: $color-white !important;
        border: 1px solid $color-tertiary-light !important;
        color: $color-tertiary-light !important;
      }
      &.Mui-focusVisible {
        background-color: $color-tertiary-light !important;
      }
    }
  }
}

.rl-table-icon {
  .rl-delete-icon {
    svg {
      font-size: 2rem !important;
      font-weight: 900 !important;
    }
  }
}

.rl-table-icon {
  button {
    svg {
      font-size: 1.5rem !important;
    }
  }
}

.rl-admin-table {
  thead {
    tr {
      th {
        background-color: rgba(230, 233, 239, 0.7) !important;
        border: none !important;
      }
    }
  }
  tbody {
    tr {
      &:nth-child(even) {
        background: #f3f4f6 !important;
      }
      &:nth-child(odd) {
        background: $color-white !important;
      }
    }
  }
}
.rl-page-title {
  font-size: 2.5rem !important;
  color: #000 !important;
  display: block;
  line-height: 0.9;
  margin-top: 8px;
  font-weight: 600 !important;
  font-family: $font-family !important;
}

/* 23*/
.rl-superadmin-toggle {
  .MuiButtonBase-root {
    position: absolute !important;
    top: 0px !important;
    bottom: 0px !important;
  }
  .MuiSwitch-switchBase {
    &.Mui-checked {
        transform: translateX(25px);
    }
  }
  .MuiButtonBase-root {
    color: #0e74be;
  }
  &.form-group {
    margin-bottom: 0px !important;
  }
  .MuiSwitch-track {
    background-color: $color-white !important;
    box-shadow: inset 0 0 1px 2px RGB(0 0 0/0.13) !important;
  }
  .MuiGrid-item {
    font-size: 1.4rem !important;
    // color: #0E74BE !important;
  }
  .MuiFormControlLabel-root {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .MuiTypography-root {
    display: none !important;
  }
  .MuiGrid-item {
    padding-top: 0px !important;
    color: #18181b !important;
  }
  .MuiGrid-container {
    margin-top: 0px !important;
    margin-left: 0px !important;
  }
  .MuiGrid-root {
    & > .MuiGrid-item {
      padding-left: 0px !important;
    }
  }
}

input:-internal-autofill-selected {
  background-color: #ffffff !important;
}
/* 23*/

/*23*/
.rl-express-video {
  .rl-checkbox-list {
    display: flex !important;
  }
}

.rl-status-filter {
  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  .MuiButtonBase-root {
    display: block !important;
  }
  .MuiSelect-select {
    padding: 2px 30px !important;
    padding-left: 10px !important;
  }
}
.rl-dropdown-content {
  .MuiIconButton-root {
    margin-right: 5px !important;
  }
}

.rl-calender {
  .MuiIconButton-edgeEnd {
    margin-right: 0px !important;
  }
  .anticon {
    svg {
      width: 1.5em !important;
      height: 1.5em !important;
    }
  } 
}

.rl-advertisement-calender {
  span {
    display: block;
    padding-bottom: 0.2rem !important;
  }
  .ant-picker {
    padding: 8px 12px!important;
  }
  .ant-picker-suffix {
    display: flex !important;
    padding-bottom: 0px !important;
  }
}

.rl-calender-content {
  .ant-picker {
    border-radius: 0px !important;
    padding: 8px 12px !important;
  }
  .rl-calender-label {
    display: block;
    padding-bottom: 3px !important;
  }
  .ant-picker-status-error {
    border-color: rgb(211, 47, 47) !important;
  }
  .ant-picker-input {
    input {
      font-family: $font-family !important;
      font-size: 1.6rem !important;
    }
  }
  .rl-calender-error {
    color: #d32f2f !important;
    font-family: $font-family !important;
    font-size: 1.1rem!important
  }
}

.rl-market-dopdown {
  &.rl-dropdown-content {
    .MuiSelect-select {
      &.MuiSelect-outlined {
        &.MuiInputBase-input {
          padding-top: 6px !important;
          padding-bottom: 6px !important;
        }
      }
    }
  }
}
/*23*/

#menu- {
  .MuiButtonBase-root {
    display: block;
    padding: 6px 12px !important;
  }
  .MuiList-root {
    &.MuiMenu-list {
      padding: 0px !important;
    }
  }
}

.rl-status-dropdown {
  display: flex;
  align-items: center;
  width: 100%;
  p {
    margin-bottom: 0px !important;
  }
  .MuiSelect-select {
    min-height: auto !important;
  }
}

.rl-admin-brokerage {
  .rl-dropdown-list {
    .css-1s2u09g-control {
      height: 4.3rem !important;
    }
  } 
}

.rl-agent-market-btn {
  button {
    height: 4.3rem !important;
  }
}

.rl-checkbox {
  padding-left: 0px !important;
}

.upl-image-crop {
  margin-bottom: 0rem !important;
}

.rl-profile-photo {
  &.figure {
    margin-bottom: 0px !important;
  }
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-bottom: 0px !important;
    object-fit: cover;
    box-shadow: 0px 0px 2px 0rem rgba(0, 0, 0, 0.3) !important;
  }
}

.css-gcwvw8-MuiAutocomplete-root {
  width: 100% !important;
}

.rl-checkbox {
  &.form-switch {
    .form-check-input {
      margin-left: 0px !important;
    }
  }
}

.rl-pricing-filter-section {
  .rl-dropdown {
    input {
      height: 3.5rem !important;
    }
  }
  .rl-unit-input {
    .MuiInputBase-root {
      padding-right: 0px !important;
    }
  }
}

// .MuiAutocomplete-popper {
//   z-index: 1350 !important;
// }

.install-instock {
  input {
    width: 20px !important;
    text-align: center;
  }
}

.rl-radio-videography {
  .rl-radio-sub-products {
      .addons-flex {
    &.rl-addon-active {
      background-color: $color-white !important;
      .MuiTypography-root  {
        color: #212529 !important;
      }
      .rl-price-text {
        color: #181818 !important;
        span {
          color: #181818 !important;
        }
      }
    }
  }
  }
  &.rl-marketing-card-content {
    .rl-info-icon {
      // display: none !important;
    }
  }
  .rl-radio-sub-products {
    .MuiTypography-root {
      font-size: 1.2rem !important;
    }
  } 
}

.rl-with-info-icon {
  display: block !important;
}

.rl-without-info-icon {
  display: none !important;
}

.rl-package-section {
  .rl-services-block {
    .rl-radio-sub-products {
        .rl-addon-active {
      .Mui-checked {
        .MuiSvgIcon-root {
          fill: #1474be !important;
        }
      }
    }
    }
  }
} 

.install-counter-option {
  width: 45px;
  .rl-counter {
    svg {
      font-size: 1.4rem;
      font-weight: 600;
      color: #212529!important;
      margin: 7px auto !important;
    }
  }
  input {
    // width: 50px !important;
    text-align: center;
  }
}

.rl-reusable-table {
  .rl-card-checkbox {
    .rl-card-radio {
      width: 100% !important;
    }
  } 
  .rl-profile-photo {
    img {
      border-radius: 0px !important;
      width: 120px !important;
      height: 120px !important;
      object-fit: contain !important;
      // height: auto !important;
    }
  }
  table {
    tbody {
      tr {
        td {
          padding: 8px 6px !important;
          border-bottom: 0px !important;
          font-family: $font-family !important;
          font-size: 1.4rem !important;
        }
      }
    }
    thead {
      tr {
        th {
          font-family: $font-family !important;
          font-size: 1.6rem !important;
          font-weight: 500 !important;
          padding: 8px 6px!important;
        }
      }
    }
  }
  .MuiFormControlLabel-label {
    font-size: 1.6rem !important;
  }
  .MuiTableCell-footer {
    border: none !important;
  }
  .MuiToolbar-root {
    width: 100%;
    justify-content: flex-end;
    .MuiTablePagination-selectLabel {
      margin-bottom: 0px !important;
      font-family: $font-family;
      font-size: 1.6rem !important;
    }
    .MuiTablePagination-select {
      font-family: $font-family !important;
      font-size: 1.6rem;
      min-height: auto !important;
      &:hover {
        background-color: transparent !important;
      }
      &:focus {
        background-color: transparent !important;
      }
    }
    .MuiTablePagination-spacer {
      flex: none !important;
    }
    .MuiInputBase-colorPrimary {
      margin-left: 4px !important;
    }
    .MuiTablePagination-displayedRows {
      margin-bottom: 0px !important;
      font-size: 1.6rem !important;
      font-family: $font-family !important;
    }
  }
}

.rl-warning-modal {
    h6 {
      margin-bottom: 2rem !important;
    }
    .rl-warning-messages {
      font-family: $font-family;
      color: #212529!important;
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
    .rl-warning-message {
      p {
        font-family: $font-family;
        color: #212529!important;
        font-size: 1.2rem;
      }
      svg {
        font-size: 1.5rem;
        margin-bottom: 0.5rem !important;
        color: #f26e21;
      }
      margin-bottom: 1.5rem;
      margin-top: 2.8rem;
      background-color: #f3f4f6!important;
      padding: 0.5rem !important;
    }
}

.rl-reset-btn {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.ant-picker-dropdown {
  z-index: 99 !important;
}

.ant-picker-today-btn {
  color: $color-tertiary-light !important;
}

.ant-picker {
  border-color: rgba(0, 0, 0, 0.23) !important;
  padding: 7px 12px!important;
  border-radius: 4px !important;
  font-family: $font-family !important;
  font-size: 1rem !important;
  &.ant-picker-focused {
    box-shadow: none !important;
  }
}

.ant-picker-cell-inner {
  &::before {
    border: 1px solid $color-tertiary-light !important;
  }
}

.ant-picker-month-btn {
  font-family: $font-family !important;
  font-size: 1.5rem !important;
}

.ant-picker-year-btn {
  font-size: 1.5rem !important;
  font-family: $font-family !important;
}

.ant-picker-today-btn {
  font-family: $font-family !important;
  font-size: 1.5rem !important;
}
.ant-picker-content {
  thead {
    tr {
      th {
        font-family: $font-family !important;
        font-size: 1.8rem !important;
      }
    }
  }
  tbody {
    tr {
      td {
        font-family: $font-family !important;
        font-size: 1.5rem !important;
      }
    }
  }
}

.rl-rider-checkbox {
  text-align: initial !important;
  margin-bottom: 10px;
  .MuiFormControlLabel-label {
    margin-left: 8px !important;
  }
  .MuiFormControlLabel-label {
    font-family: $font-family !important;
    font-size: 1.6rem !important;
    line-height: 0.7 !important;
    margin-top: 0.2rem !important;
  }
  svg {
    height: 1.5em !important;
    width: 1.5em !important;
  }
}

.rl-dropdown {
  input {
    height: 3.3rem !important;
    padding: 0 1.2rem 0.2rem !important;
    font-family: $font-family !important;
  }
  .MuiInputBase-root {
    border-radius: 0px !important;
  }
}

.MuiAutocomplete-propper {
  font-family: $font-family !important;
}

.rl-pricing-btn {
  padding: 0.7rem 0.8rem !important;
  width: 100% !important;
}

.rl-appointment-details {
  border: 2px solid #1474be !important;
  .bg-title {
    margin-bottom: 0px !important;
  }
  .rl-admin-table {
    .mb-3 {
      margin-bottom: 0.5rem !important;
    }
    thead {
      tr {
        th {
          font-size: 1.5rem !important;
          font-weight: 500 !important;
        }
      }
    }
    tbody {
      tr {
        td {
          font-size: 1.4rem !important;
          font-weight: 400 !important;
        }
      }
    }
  }
  .rl-pagination-list {
    padding: 0px 24px !important;
    margin-top: 0px !important;
  }
  .rl-order-only {
    padding-left: 24px !important;
    .MuiFormControlLabel-label {
      margin-left: 6px !important;
    }
  }
  .rl-form-appoinment {
    padding: 0px 24px 0px 24px !important;
    .MuiFormControlLabel-label {
      margin-left: 6px !important;
    }
  }
  .rl-admin-table {
     .MuiTableRow-root {
        td {
          &:first-child {
            text-align: left !important;
            input {
              margin-left: 10px !important;
            }
          }
        }
    }
  }
  .rl-mls-form {
    padding: 0px !important;
    box-shadow: none !important;
    margin-bottom: 8px !important;
  }
  .rl-primary-contact {
    padding-left: 24px !important;
    padding-top: 24px !important;
  }
  .rl-admin-table {
    .MuiTableCell-root {
      padding-left: 24px !important;
    }
  } 
}

.rl-info-icon {
  svg {
    font-size: 1.4rem !important;
    color: $color-primary !important;
    font-weight: 600 !important;
    cursor: pointer;
  }
}


.rl-note-popover {
  text-align: center;
  font-family: $font-family !important;
  h1 {
    font-size: 1.8rem !important;
  }
  span {
    font-size: 1.3rem !important;
  }
  button {
    font-size: 1.4rem !important;
    margin-left: 0px !important;
    padding: 0.5 1.8rem !important;
  }
}

.rl-error-input {
    border: 1px solid rgba(211, 47, 47, 1) !important;
}

.rl-popover-text {
  padding: 1rem !important;
  min-width: 250px !important;
  .carousel-item {
    height: 341px !important;
    align-items: center;
   
  }
  h1 {
    font-family: $font-family !important;
  }
  .description-text {
    text-align: center;
    display: block;
    white-space: normal; 
    word-wrap: break-word;
  }
  p {
    margin-bottom: 0px !important;
    font-size: 1.2rem !important;
    font-family: $font-family !important;
    color: #212529 !important;
  }
  .rl-popover-title {
    display: flex;
    align-items: center;
    position: relative;
    svg {
    // position: absolute;
    right: 0px;
    font-size: 20px;
      // color: #ff0000;
      cursor: pointer;
    }
    span {
      margin: 0px auto;
    }
  }
}

.rl-popover {
  .MuiPopover-paper {
    border-radius: 2px !important;
  }
}
.MuiOutlinedInput-notchedOutline {
  border-radius: 0px !important;
}

.rl-add-service {
  .MuiSwitch-sizeMedium {
    margin-left: 0px !important;
  }
  .MuiTypography-root {
    display: block !important;
    font-family: $font-family !important;
  }
}

.rl-package-block {
  .rl-asterisk-mark {
    content: " *";
    color: #d32f2f !important;
  }
  .MuiFormLabel-root {
    margin-bottom: 0px !important;
    font-family: $font-family !important;
  }
}

// .MuiTextField-root {
//   margin-bottom: 0px !important;
// }

.rl-asterisk-mark {
  color: #d32f2f!important;
}

.rl-address-dropdown {
  .MuiInputBase-input {
    padding-left: 5px !important;
  }
  .MuiAutocomplete-endAdornment {
    top: 30% !important;
  }
}

.rl-replace-input {
  display: inline-flex;
  align-items: flex-end;
  width: 50%;
  .form-label {
    margin-bottom: 0rem !important;
  }
  input {
    font-family: $font-family !important;
    font-size: 1.6rem !important;
  }
  .MuiTextField-root {
    width: 100% !important;
    margin-bottom: 5px;
  }
  legend {
    font-weight: 500;
    font-family: $font-family;
    font-size: 1.6rem !important;
  }
  #standard-basic {
    height: 2.5rem !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.rl-table-input {
  input {
    &:focus-visible {
      outline: none !important;
    }
  }
}

.rl-table-total-text {
  font-size: 2.5rem !important;
  font-weight: 700 !important;
}

.rl-table-total-count {
  font-size: 1.9rem !important;
  font-weight: 500 !important;
}

.rl-button-text {
  font-size: 1.3rem !important;
}

.rl-package-block {
  border: 1px solid #ced4da !important;
  box-shadow: none !important;
}

.rl-nodata-content {
  text-align: center;
  padding: 10px;
  background-color: rgba(14, 116, 190, 0.06) !important;
  .rl-table-nodata {
    margin-left: -6px !important;
    margin-right: -6px !important;
  }
  p {
    font-size: 1.6rem !important;
    font-family: $font-family !important;
    font-weight: 600;
    margin-bottom: 0px !important;
    text-transform: capitalize !important;
  }
}
.rl-table-title {
  margin-bottom: 0px !important;
  background-color: #e5e5e5;
  padding: 8px 8px;
  text-align: center;
  color: #0e74be;
  font-family: $font-family !important;
  font-weight: 600 !important;
}

.rl-agentprofile-table {
  .order-history-content {
    margin-bottom: 0px !important;
  }
}

.rl-installation-block {
  .install-btn {
    display: flex;
    padding: .8rem 2rem !important;
    align-items: center;
    span {
      font-size: 1.8rem !important;
      line-height: 1px;
      margin-right: 2px;
      color: $color-tertiary-light;
      // padding-right: 1rem !important;
    }
  }
}

.rl-installation-block {
  .install-btn-danger {
    display: flex;
    padding: .8rem 2rem !important;
    align-items: center;
    span {
      font-size: 1.8rem !important;
      line-height: 1px;
      margin-right: 2px;
      color: red;
      // padding-right: 1rem !important;
    }
  }
}

// .MuiPickersPopper-root {
//   z-index: 9 !important;
// }

.rl-addon-modal {
  .modal-title {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .modal-body {
    form {
      padding-left: 1.5rem !important;
      padding-right: 1.5rem !important;
    }
  }
}

.rl-admin-table {
  thead {
    tr {
      th {
        font-weight: 700 !important;
        line-height: 2.8rem !important;
      }
    }
  }
  tbody {
    tr {
      td {
        font-weight: 500 !important;
      }
    }
  }
}

.rl-dropdown-content {
  &.rl-dropdown-option {
    .form-label {
      margin-bottom: 0.2rem;
      font-size: 1.6rem !important;
      color: #18181b !important;
    }
  }
}

.MuiFormControl-root {
  .MuiInputLabel-root {
    font-size: 1.6rem !important;
    pointer-events: none !important;
  }
} 

.rl-service-update {
  .rl-add-variant-accordin {
    box-shadow: rgba(0, 0, 0, 0.1) -1px 1px 1px -1px, rgba(0, 0, 0, 0.1) 1px 1px 1px 0px, rgba(0, 0, 0, 0.1) -1px -1px 3px 0px !important;
    z-index: 9;
  }
  .rl-uploaded-photo {
    .card-img-top {
      height: 200px!important;
      object-fit: cover !important;
    }
  } 
  .rl-radiobutton-list {
    .MuiFormGroup-root {
      display: block !important;
    }
  }
  .rl-card-checkbox  {
    .rl-card-radio {
      width: 100% !important;
    }
  }
  .rdw-editor-main {
    overflow: hidden !important;
  }
}

.rdw-editor-main {
  overflow: hidden !important;
}

.rl-delete-addon-photo {
  color: $color-tertiary-light !important;
  // width: 100% !important;
  background-color: transparent !important;
  border-radius: calc(0.375rem - 1px) !important;
  padding: 6px 12px !important;
  margin-bottom: 5px !important;
}

.rl-uploaded-photo {
  .card-body {
    display: none !important;
  }
  .card-img-top {
    height: 290px !important;
    object-fit: fill !important;
  }
}

.css-26l3qy-menu {
  z-index: 99999 !important;
}

.css-1nmdiq5-menu {
  z-index: 99999!important;
}


div[id^="react-select-"][id$="-listbox"] {
  z-index: 99999!important;
}

.rl-dropdown-list {
  // z-index: 99;
  div {
    border-radius: 0px !important;
    min-height: 28px !important;
    font-size: 1.7rem !important;
    margin: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  span {
    background-color: transparent !important;
  }
  svg {
    width: 14px !important;
    fill: rgba(0, 0, 0, 0.54) !important;
    margin: auto 0px !important;
  }
}

.MuiAutocomplete-root {
  width: auto !important;
}

.MuiAutocomplete-endAdornment {
  top: 20% !important;
}

.rl-user-add-form {
  .rl-dropdown-list {
    // div {
    //   min-height: 32px !important;
    // }
    input {
      padding: 4px 0px !important;
    }
  }
  .rl-dropdown-option {
    .rl-dropdown-list {
       input {
        height: auto !important;
      }
    }
  }
  .MuiFormControl-root {
    input {
      height: 3.2rem !important;
    }
  }
  .rl-dropdown-content {
    .roc-mui {
      .MuiOutlinedInput-root {
        input {
          height: 3.2rem !important;
        }
      }
    }
  }
  .rl-dropdown-content {
    input {
      height: 3.2rem !important;
    }
  }
}

.rl-service-update {
  .rl-superadmin-checkbox {
    .MuiFormControlLabel-root {
      margin-right: 0px !important;
    }
  }
  .rl-btn-primary {
    z-index: 999 !important;
  }
  .rl-btn-secondary {
    z-index: 999 !important;
  }
}

.rl-superadmin-checkbox {
  .MuiFormControlLabel-label {
    color: #18181b !important;
  }
  .MuiFormControlLabel-root {
    display: block;
    width: fit-content;
    cursor: default;
  }
}

.rl-dashboard-view {
  .rl-avatar-content {
    .avatar__image-box {
      margin-top: 0px !important;
    }
    .figure {
      padding: 0px !important;
    }
    img {
      max-width: 100% !important;
      max-height: 15rem !important;
    }
  } 
}

.rl-reusable-table {
  .rl-pagination-list {
    margin-bottom: 1.5rem !important;
  }
}

.rl-pagination-client-table {
  .MuiTableCell-footer {
    padding: 0px !important;
  }
  .MuiPagination-ul {
    li {
      &:first-child {
        display: none !important;
      }
      &:last-child {
        display: none !important;
      }
    }
  }
  .MuiPaginationItem-icon {
    font-size: 2rem !important;
  }
  .MuiPaginationItem-root {
    font-family: $font-family !important;
    font-size: 1.4rem!important;
    border-radius: 0px !important;
  }
  .MuiPaginationItem-textPrimary {
    .Mui-selected {
      background-color: #1474be !important;
    }
  } 
  .Mui-selected {
    background-color: #1474be !important;
  }
  .MuiPaginationItem-textPrimary {
    &.Mui-selected {
      &:hover {
        background-color: #fff!important;
        border: 1px solid #1474be!important;
        color: #1474be!important;
      }
    } 
  } 
}

.rl-custom-package-table {
  .list-group {
    border-radius: 0px;
    .list-group-item {
      border: none;
    }
    .rl-total-package {
      background-color: $color-white;
    }
    &:nth-child(2) {
      .list-group-item {
        background: #f3f4f6!important;
      }
    }
  }
}

.rl-warning-text {
  color: #d32f2f !important;
}

// .sticky-top {
//   z-index: 99999 !important;
// }

.rl-virtual-total {
  background-color: $color-grey-light-1;
}

.rl-count-virtual {
  span {
     width: fit-content;
  border: 1px solid $color-secondary;
  }
}

.rl-custom-dropdown {
  .rl-dropdown-list {
    input {
      padding: 2px 0px !important;
    }
  }
}

.rl-dropdown-view {
  .rl-dropdown-list {
    div {
      z-index: 99 !important;
    }
  }
}

.rl-custom-package-option {
  .rl-dropdown-content {
    &.rl-dropdown-option {
      .form-label {
        margin-bottom: 0.3rem !important;
        font-size: 1.4rem !important;
      }
    }
  }

  .rl-dropdown-list {
    div {
      border-radius: 0px !important;
      min-height: 2.9rem !important;
      font-size: 1.4rem !important;
      line-height: 1.9 !important;
    }
    span {
      background-color: transparent !important;
    }
    svg {
      width: 14px !important;
      fill: rgba(0, 0, 0, 0.54) !important;
    }
  }
}

.rl-badge {
  background-color: transparent !important;
  color: initial !important;
  padding: 0px !important;
  font-size: 2rem !important; 
  font-weight: $regular-weight !important;
}

.rl-video-full {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px !important;
  // width: 100%;
}

.rl-modal-video-play {
  .rl-video-bg {
    background-color: transparent !important;
  }
  .form {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .modal-header {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
    .modal-title {
      padding: 0px !important;
    }
  }
  .rl-video-full {
    padding: 0px !important;
    margin-top: -15px !important;
    margin-bottom: 5px !important;
  }
}

.rl-premium-checkbox {
  padding: 0px 10px;
  .MuiFormControlLabel-label {
    font-size: 1.5rem !important;
  }
  .MuiFormControlLabel-root {
    margin-right: 0px !important;
    margin-bottom: 5px !important;
  }
}

.rl-addons-check {
  .rl-premium-checkbox {
    .MuiCheckbox-root {
      padding: 0px 9px !important;
    }
  }
} 

.rl-walkthrough-video {
  .MuiCheckbox-root {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }
  .MuiFormControlLabel-root {
    padding: 0px !important;
  }
}

.rl-video-premium {
  position: relative;
  padding: 10px;
  padding-bottom: 0px !important;
  .MuiFormControlLabel-label {
    font-size: 1.5rem !important;
  }
  .rl-video-icon {
    display: block;
    position: absolute;
    width: max-content;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    svg {
      fill: $color-white !important;
      width: 3em !important;
      height: 3em !important;
    }
  }
}

.rl-order-summary-form {
  padding-left: 20px !important;
}

.rl-order-summary {
  .order-summary {
    .rl-save-text {
      padding-left: 1.4rem !important;
      display: block !important;
    }
    .bgGrayTitle {
      &.rl-total-text {
        text-align: left !important;
        padding: 0rem 1.4rem!important;
        font-size: 2.5rem !important;
        font-weight: 600 !important;
      }
      &.rl-color-block {
        color: $color-black !important;
      }
    }
  } 
} 
.orderSum-set.rl-order-details {
  position: relative;
}

.listing-detail-btn.btn-group {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.userprofile {
    padding-right: 0px;
    text-align: center;
    margin-top: 30px;
}

.userprofile img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border: 1px solid #ccc;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 10px;
}

.userprofile p {
  font-size: 20px;
  margin-bottom: 0px;
  color: #000;
  text-transform: capitalize;
}
.userprofile p span {
  color: #707070;
}
.ServicesType h3 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #000000;
  font-family: bebas-neue-pro, sans-serif !important;
}
.ServicesType {
  margin-bottom: 40px;
    padding: 20px;
    position: relative;
}
.userprofile p svg {
  margin-right: 7px;
}
.mytablebox {
  box-shadow: none !important;
  border: 1px solid rgba(224, 224, 224, 1);
}
.ordertable {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 5px !important;
  padding-bottom: 0px !important;
}
.ServicesType.BillingStatus.MuiBox-root.css-0 {
  margin: 0px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #e4e3e3;
}
.ServicesType.Transcation h3 {
  text-align: center;
}
.add-extra-class .accordion-item {
  margin-bottom: 50px;
}
.marketingdetails.borderline {
  border-right: 1px solid #ccc;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.marketingdetails.borderline p {
  margin-bottom: 10px;
}
.services-width {
  width: 250px;
}
.listing-detail-btn.editBox.btn-group {
  justify-content: end;
}

.mls-drop-down fieldset {
  height: 49.39px;
}

.mls-drop-down  .MuiSelect-select {
  padding: 10px 12px !important;
  position: relative;
  top: -4px;
}
.marketingdetails img {
  width: 150px;
  border-radius: 5px;
  margin-bottom: 13px;
}
.rl-most-popular-addon-section .carousel-item img {
  max-height: 90vh;
  object-fit: contain;
}

.rl-most-popular-addon-section .carousel-control-next-icon, 
.rl-most-popular-addon-section .carousel-control-prev-icon{
  width: 4rem;
  height: 4rem;
}
.marketingdetailsThumbnail img {width: 150px;display: flex;}

.marketingdetailsThumbnail p {
    margin-bottom: 20px;
    font-size: 17px;
}
.ServicesType::before {
  content: "";
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: -29px;
  height: 1px;
  background: #c8c6c6;
}
.ServicesType:last-child::before {
  display: none;
}
.listingtabpanel .padding-xy.mb-3.row{
  border-bottom: 1px solid #cccccc75;
  padding-top: 10px;
}
.TranscationDetails{background: #efefef !important;}
.listingtabpanel.orderSum-set.rl-order-details {
  border: 0px;
}
.listing-detail-btn.btn-group button {
  flex: initial !important;
  white-space: nowrap;
}
.rl-total-hr {
  width: 50%;
}
.add-extra-class .accordion-item {
  border: 1px solid #e0e0e0 !important;
}

.add-extra-class button.accordion-button.btn.btn-sm {
  background: transparent !important;
  padding-left: 0px !important;
  justify-content: center !important;
  box-shadow: none !important;
}

.add-extra-class button.accordion-button.btn.btn-sm::after {
  display: none;
}
.rocket-ListAccordion.add-extra-class .accordion-button span {
  position: relative;
  left: 0px;
  transform: initial;
  color: #1474be;
  box-shadow: none !important;
  outline: none !important;
  font-size: 30px;
  font-weight: 300;
}
.rl-card-details {
  border: 2px solid #1474be;
  padding: 0rem;
  .orsum-billCardInfo {
    padding-top: 1rem !important;
  }
  &.rl-card-info {
    border: 2px solid #1474be !important;
  }
  .btn-primary {
    background-color: transparent;
    border-color: transparent;
    .rl-delete-icon {
      color: #ff0000 !important;
    }
    &:hover {
      background-color: transparent;
      border-color: transparent;
      span {
        svg {
          color: #0e74be;
        }
      } 
    }
    span {
      svg {
        color: #0e74be;
      }
    } 
  } 
  .rl-admin-table {
    .mt-4 {
      margin-top: 0px !important;
      padding-right: 0px !important;
    }
  }
  .rl-card-option {
    border: 1px solid #cfcfcf !important;
    &:first-child {
      border-left: 0px !important;
      border-top: 0px !important;
    }
    &:last-child {
      border-right: 0px !important;
      border-top: 0px !important;
    }
  }
  .rl-card-option-content {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .rl-creditcard-checked {
    background-color: #1474be !important;
    .rl-card-option-item-block {
      span {
        color: #ffffff !important;
      }
      .MuiTouchRipple-root {
        color: #ffffff !important;
      }
    }
    .MuiCheckbox-root {
      &.Mui-checked {
        color: #ffffff !important;
      }
    }
  }
  .rl-card-option-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 0.5rem 0rem !important;
    .MuiFormControlLabel-root {
      margin-right: 0px !important;
      svg {
        margin-right: 0px !important;
      }
    }
    .rl-card-option-item-block {
      display: flex;
      align-items: center;
    }
.rl-cardCheckBox{
display: flex;
align-items: center;
cursor: pointer;
}
    svg {
      height: 1.5em !important;
      width: 2em !important;
      margin-right: 0.5rem;
    }
    span {
      font-size: 2rem !important;
      font-weight: 500 !important;
      line-height: 1.6;
    }
  }
}

.rl-card-info {
  border-left: 1px solid #707070 !important;
}

.rl-credit-edit-form {
  .rl-custom-modal {
    .modal-title {
      padding: 0px !important;
    }
  }
  .rl-credit-title {
    font-size: 1.8rem !important;
    font-weight: 600 !important;
    margin-bottom: 1rem !important;
  }
}

.rl-creditcard-list {
  padding-right: 0px !important;
  border-right: 1px solid #777 !important;
}
.rl-credit-card-radio {
  margin-top: -20px !important;
}

.rl-checkbox-list {
  display: block !important;
  label {
    display: flex !important;
    margin-left: 0px !important;
    background-color: $color-white;
    width: 100%;
  }
  .MuiTypography-root {
    font-family: $font-family !important;
  }
  .rl-checkbox-video {
    margin-bottom: 20px;
    .MuiSvgIcon-root {
      color: $color-primary;
    }
    .MuiFormHelperText-root {
      margin-left: 2px !important;
    }
    .rl-video-thumbnail {
      background-color: #000000;
      min-height: 130px;
      height: 130px;
      display: flex;
      justify-content: center;
      align-items: center;
      .rl-video-icon {
        font-size: 4rem;
        color: $color-primary;
      }
    }
  }
}

.rl-video-bg {
  background-color: #efefef !important;
}

.rl-service-slider {
  .carousel-inner {
    height: 25rem !important;
  }
  .carousel-control-next,
  .carousel-control-prev {
    opacity: 1 !important;
  }
  // .carousel-control-prev {
  //   opacity: 1 !important;
  // }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    height: 2.5rem !important;
    width: 2.5rem !important;
  }
  .card-title {
    font-family: $font-family !important;
    font-size: 1.6rem !important;
    padding: 1rem 1rem !important;
    margin-bottom: 0rem !important;
    color: #212529 !important;
  }
  .card-body {
    .card-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .card {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08) !important;
    border: none !important;
  }
  .carousel {
    margin-bottom: 0rem !important;
  }
}

.rl-addadmin-form {
  .rl-custom-form {
    box-shadow: none !important;
  }
}

.rl-discountcode-text {
  margin-top: -50px !important;
  text-align: left;
  font-family: $font-family;
  font-size: 2.5rem;
  font-weight: 600!important;
  line-height: 1.5;
}

// .rl-service-pricing {
//   .pricing__getPrice__form-group {
//     &.zip-form  {
//       .MuiTextField-root {
//         width: 60%;
//       }
//       input {
//         padding-left: 10px !important;
//       }
//     }
//     &.sqft-form {
//       .MuiTextField-root {
//         width: 60%;
//       }
//       input {
//         padding-left: 10px !important;
//       }
//     }
//   } 
// }

.rl-pricing-filter-section {
  // background-color: #f6f5f7;
  background-color: #f3f4f6!important;
  padding: 20px;
  text-align: center;
  .MuiInputBase-root {
    background-color: $color-white !important;
    font-family: $font-family !important;
  }
  .MuiFormControl-root {
    margin-bottom: 0px !important;
  }
  .MuiFormLabel-root {
    display: none;
  }
  .MuiOutlinedInput-notchedOutline {
    inset: 0px 0px 0px !important;
  }
  .rl-pricing-input {
    justify-content: center;
    align-items: start !important;
  }
  .form-group {
    .MuiFormControl-root {
      margin-bottom: 0px !important;
    }
  }
  form {
    background-color: transparent !important;
    padding: 0px !important;
  }
}

.rl-see-pricing-btn {
  justify-content: flex-start !important;
}

.rl-admin-form-bg {
  box-shadow: none !important;
  padding: 10px 20px !important;
  .rl-user-profile {
    cursor: pointer;
    img {
      object-fit: fill !important;
    }
    .rl-update-userimg {
      display: inline-block;
      position: relative;
      margin: 30px 0px;
      .btn {
        display: block;
        // width: 100%;
        // margin-bottom: 20px;
        background-color: $color-white !important;
        color: $color-tertiary-light !important;
        position: absolute;
        bottom: 25px;
        right: 0px;
        border-radius: 50%;
        padding: 0.8rem !important;
      }
    }
  }  
  .rl-user-logo  {
    .rl-update-userimg {
      display: inline-block;
      position: relative;
      margin: 30px 0px;
      img {
        padding: 0px !important;
      }
      .btn {
        display: block;
        // width: 100%;
        // margin-bottom: 20px;
        background-color: $color-white !important;
        color: $color-tertiary-light !important;
        position: absolute;
        bottom: 20px;
        right: -10px;
        border-radius: 50%;
        padding: 0.8rem !important;
      }
    }
  }
  .rl-custom-package-content {
    margin-left: 0px !important;
    margin-top: 20px !important;
  }
}

.rl-sublinked-table {
  .MuiTableCell-head {
    &:nth-child(4) {
      text-align: center;
    }
    &:nth-child(5) {
      text-align: center;
    }
  }
  .MuiTableRow-root {
    .MuiTableCell-body {
      &:nth-child(4) {
        text-align: center;
      }
      &:nth-child(5) {
        text-align: center;
      }
    }
  }
}

.rl-linkedaccount-table {
  .MuiTableCell-head {
    &:nth-child(4) {
      text-align: center;
    }
    &:nth-child(5) {
      text-align: center;
    }
  }
  .MuiTableRow-root {
    .MuiTableCell-body {
      &:nth-child(4) {
        text-align: center;
      }
      &:nth-child(5) {
        text-align: center;
      }
    }
  }
}

.home-subtitle {
  font-family: $font-family !important;
}

.primarySubTitle {
  font-family: $font-family !important;
}

.pageTitle {
  font-family: $font-family !important;
}

.MuiTabs-root {
  .MuiTab-root {
    font-family: $font-family !important;
  }
}

.custom-form-bg {
  background-color: #fff !important;
  box-shadow: 0 0 5px 0rem RGB(0 0 0/0.08) !important;
  padding: 20px !important;
  .terms_of_use_text {
    color: #18181b !important;
    font-size: 1.4rem !important;
    a {
      color: #18181b !important;
    }
  }
}

.MuiFormGroup-root {
  .MuiTypography-root {
    font-family: $font-family !important;
    color: #212529 !important;
  }
}

.pageHeading {
  font-family: $font-family !important;
}

.rl-fullwidth {
  width: 100%;
}

// .css-k008qs {
//   height: calc(100% - 100px) !important;
// }

.css-2613qy-menu {
  z-index: 999999999 !important;
}

// .css-26l3qy-menu {
//   z-index: 999 !important;
// }

#react-select-3-listbox {
  z-index: 999 !important;
}

.css-13cymwt-control {
  height: 4.2rem !important;
}

.css-1tcbki6 {
  .MuiSwitch-switchBase {
    &.Mui-checked {
      color: rgb(25, 118, 210) !important;
    }
  }
}

.rl-service-section {
  .rl-common-content {
    padding: 0px !important;
    box-shadow: none !important;
  }
  .rl-slider-icon {
    background-color: #1474be !important;
    border-radius: 50%;
    display: block;
    padding: 6px;
    height: 35px;
    width: 35px;
    line-height: 15px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    cursor: pointer;
    svg {
      color: $color-white !important;
    }
  }
}

.MuiPickersDay-root {
  font-weight: 700 !important;
}

.MuiPickersDay-today {
  border: 1px solid #1474be !important;
  background-color: rgba(20, 116, 190, 0.1) !important;
  color: rgba(20, 116, 190, 1) !important;
  font-weight: 900 !important;
}

.MuiPickersDay-root:hover {
  background-color: rgba(20, 116, 190, 0.1) !important;
  color: rgba(20, 116, 190, 1) !important;
  font-weight: 900 !important;
}

.rl-admins-list {
  .rl-admim-title {
    font-family: $font-family !important;
  }
}

.rl-btn-next {
  display: flex;
  justify-content: flex-end;
  .rl-btn-primary {
    width: 100%;
    display: block;
  }
  .back-btn {
    padding-left: 0px !important;
  }
  .rl-text-center {
    text-align: center !important;
  }
}

.rl-addons-check {
  .rl-price-text {
    .rl-per-option {
       margin-top: -10px !important;
    }
  }
}

.rl-package-section {
  .MuiTypography-root {
    font-weight: 500 !important;
    font-size: 1.8rem;
  }
  .rl-price-text {
    .rl-per-option {
      font-size: 1.4rem !important;
      font-weight: 300 !important;
      display: block !important;
      // margin-top: -10px !important;
    }
    p {
      font-size: 4rem !important;
      span {
        display: block;
        // font-size: 2rem !important;
      }
    }
  }
  .rl-lg-h {
    padding-bottom: 0px !important;
    line-height: 1.2em !important;
  }
  .rl-md-h {
    padding-top: 0px !important;
    font-size: 5rem !important;
    margin-top: 4rem;
    margin-bottom: 0rem !important;
    padding-bottom: 0px !important;
  }
  .rl-md-title {
    font-size: 2.9rem !important;
    margin-bottom: 4rem !important;
    font-family: $font-family !important;
    text-align: center !important;
    color: #1474be !important;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 3.6px;
    margin-top: 4rem !important;
  }
  .rl-services-block {
    &:first-child {
      .carousel {
        border: 1px solid #eee!important;
      }
    }
  }
}

.rl-dusk-info-text {
  color: $color-primary !important;
  align-items: center;
  display: flex;
  svg {
    margin-right: 5px;
  }
  span {
    color: #6c757d !important;
  }
}

.mls-check-row {
  .MuiFormControlLabel-root {
    margin-right: 4rem !important;
  }
  .MuiButtonBase-root {
    padding-left: 9px;
  }
}

.rl-calender {
  font-size: 1.6rem !important;
  .MuiPaper-root {
    font-size: 1.6rem !important;
  }
}

.MuiCalendarPicker-root {
  .PrivatePickersFadeTransitionGroup-root {
    div {
      font-size: 1.6rem !important;
    }
  }
  .MuiPickersDay-root {
    font-size: 1.4rem !important;
    font-family: $font-family !important;
  }
  .MuiTypography-caption {
    font-size: 1.4rem !important;
    font-family: $font-family !important;
  }
  .MuiTouchRipple-root {
    font-size: 1.4rem !important;
  }
  .css-1dozdou {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}

// .MuiPopperUnstyled-root {
//   z-index: 9 !important;
// }

.MuiMenuItem-gutters {
  font-family: $font-family !important;
  font-size: 1.4rem !important;
}

.MuiMenu-list {
  .MuiMenuItem-root {
    min-height: auto !important;
  }
}

.MuiPaper-elevation {
  border-radius: 0px !important;
}

.rl-dropdown-content {
  .MuiChip-root {
    span {
      font-family: $font-family !important;
    }
  }
}

.rl-positoin-btn {
  padding: .2rem 0.5rem !important;
  border-color: rgba(0,0,0,0.08) !important;
}

.roc-mui {
  .Mui-error {
    font-weight: 500 !important;
    font-family: $font-family !important;
  }
}

.rl-counter {
  color: $color-white !important;
  border: none !important;
  text-align: center !important;
  span {
    border-color: $color-white !important;
  }
  svg {
    color: #ffffff !important;
    font-weight: 600;
  }
}

.mt-6 {
  margin-top: 3.5rem !important;
}

.rl-dropdown-custom {
  &.rl-dropdown-content {
    .form-label {
      color: #18181b;
      font-family: $font-family !important;
      font-size: 1.4rem;
      padding-bottom: 0rem !important;
      position: relative;
      -webkit-transform: none;
      transform: none;
      margin-bottom: 0px;
    }
    div {
      min-height: 28px !important;
      line-height: 28px;
      cursor:  pointer !important;
    }
  }
}

.rl-calender {
  .MuiIconButton-edgeEnd {
    margin-right: 0px !important;
  }
}

.rl-admin-form-bg {
  .rl-edit-content {
    a {
      color: #212529 !important;
    }
    .MuiFormLabel-root {
      font-family: $font-family !important;
      font-size: 1.4rem !important;
      color: #18181b !important;
      padding-bottom: 0.8rem !important;
    }
    .editor-class {
      background-color: transparent !important;
      padding: 0.5rem !important;
      .public-DraftStyleDefault-ltr {
        margin: 0px !important;
        span {
          color: #212529 !important;
        }
      }
    }
  }
}

.rl-radiobutton-list {
  &.rl-custom-radio {
    .MuiFormGroup-row {
      display: block !important;
    }
    .MuiFormControlLabel-label {
      font-size: 1.6rem !important;
    }
    .MuiRadio-root {
      padding-left: 0px !important;
    }
  }
}

.rl-add-discountcode {
  .rl-expire-date {
    .MuiIconButton-edgeEnd {
      margin-right: 0px !important;
    }
  }
  .rl-radiobutton-list {
    &.rl-custom-radio {
      .MuiFormGroup-row {
        display: block !important;
      }
      .MuiFormControlLabel-label {
        font-size: 1.6rem !important;
      }
      .MuiRadio-root {
        padding-left: 0px !important;
      }
    }
  } 
  .rl-custom-radio {
    .MuiFormControlLabel-labelPlacementEnd {
      margin-left: 0px !important;
      margin-right: 25px !important;
    }
    .MuiButtonBase-root {
      padding-right: 5px !important;
    }
  }
  .rl-dropdown-content {
    .rl-dropdown-list {
       div {
        z-index: 99 !important;
        min-height: auto !important;
      }
    }
  }
  .rl_radio_form {
    .MuiFormControlLabel-root {
      margin-right: 0px !important;
    }
  }
}

.rl-radio-content {
  .MuiFormControlLabel-labelPlacementEnd {
    margin-left: 0px !important;
  }
  .MuiButtonBase-root {
    padding-right: 5px !important;
  }
}

.rl-confirm-modal {
  .modal-title {
    font-size: 2rem !important;
  }
  span {
    color: #18181b !important;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
  // border: none !important;
  box-shadow: none !important;
}

.rl-error-message {
  color: rgb(211, 47, 47) !important;
  font-size: 1.1rem !important;
  font-family: $font-family !important;
}

.MuiFormHelperText-root {
  color: rgb(211, 47, 47) !important;
  font-size: 1.1rem !important;
  font-family: $font-family !important;
}

.MuiFormHelperText-root {
  margin-left: 0px !important;
}

.rl-delete-icon {
  background-color: transparent !important;
  border: none !important;
  padding: 0px !important;
  svg {
    color: $color-red !important;
    font-size: 2rem!important;
    font-weight: 900!important;
  }

}

// .rl-custom-modal {
//     span {
//       color: #18181b;
//     }
// }
.rl-placeholder-input {
  div {
    // color: #999999 !important;
    color: rgba(0,0,0,0.33) !important;
  }
}


.rl-replace-input {
  .form-label {
    display: flex !important;
  }
}

.rl-dropdown-block {
  .MuiSelect-select {
    height: auto !important;
  }
}


.rl-package-section {

  .blueSubTitle {
    border: 0px !important;
  }
  .rl-services-block {
    // padding: 1rem;
    border: 1px solid $color-tertiary-light !important;
    margin-top: 2rem !important;
    .rl-addon-active {
      .Mui-checked {
        .MuiSvgIcon-root {
          fill: $color-white !important;
        }
      }
    }
    .rl-most-popular-addon-section {
      .carousel-inner {
        height: 380px !important;
      }
    }
    .MuiCheckbox-root {
      .MuiSvgIcon-root {
        fill: #1474be !important;
      }
    }
    .MuiTouchRipple-root {
      color: #1474be !important;
    }
    &:first-child {
      .rl-addons-check {
        border: 1px solid $color-grey-light-2 !important;
      }
    }
  }
} 

.rl-package-section  {
  .rl-addons-check {
    &.rl-marketing-card {
      border: none !important;
    }
  }
}

.rl-marketing-card-content {
  .list {
    margin-bottom: 0px !important;
    // display: block !important;
    width: 100% !important;
    .row {
      margin-right: calc(var(--bs-gutter-x)*-.5) !important;
    }
  }
  .rl-addons-check {
    .MuiCheckbox-root {
      padding-left: 4px !important;
    }
  } 
  .addons-flex {
    padding-left: 0px !important;
  }
  .card-body {
    display: none !important;
  }
  .rl-marketing-card {
    width: 100% !important;
    &.rl-addons-check {
      .MuiSvgIcon-root {
        width: 1.8rem !important;
        height: 1.8rem !important;
      }
      .rl-price-text {
        font-size: 2rem !important;
      }
      .MuiTypography-root {
        font-size: 1.4rem !important;
      }
      .rl-addon-active {
        background-color: #ffffff !important;
        .MuiFormControlLabel-label {
          color: #212529 !important;
        }
        .Mui-checked {
          .MuiSvgIcon-root {
            fill: #1474be !important;
          }
        }
      }
    }
    .rl-addon-active {
      .rl-price-text {
        color: #181818 !important;
        span {
          color: #18181b !important;
        }
      }
    }
  }
  .col-9 {
    // flex: 0 0 auto !important;
    // width: auto !important;
  }
  .card {
    border-radius: 0px !important;
    border-color: transparent !important;
    border-width: 0px !important;
    margin-top: 7px !important;
  }
  .rl-marketing-divider {
    padding: 0px 10px !important;
    display: none;
    border: 1px solid #dedede;
    width: 1px;
    height: auto;
    z-index: 999;
    margin: 0px auto;
    margin-right: 10px;
    &:nth-child(0) {
      display: block;
    }
  }
}

/* UI */

// ======= FORM MOBILE RESPONSIVE =======
@media (max-width: 768px) {
  .MuiAutocomplete-endAdornment {
    top: 20% !important;
  }
  .rl-pricing-filter-section {
    .zip-form {
      margin-bottom: 20px !important;
    }
    .mt-4 {
      margin-top: 0rem !important;
    }
    .mb-5 {
      margin-bottom: 0rem !important;
    }
    button {
      width: 100% !important;
      margin-top: 0px !important;
      padding: 0.6rem 0.5rem !important;
    }
  }
  .rl-pricing-content {
    margin-top: 5rem !important;
  }
  .MuiAutocomplete-root {
    width: auto !important;
  }
  .form {
    .form-group {
      .form-label {
        font-size: 1.4rem;
      }

      .form-check {
        font-size: 1.4rem;
      }
    }
  }
  .rl-pricing-ordersummary {
    margin-bottom: 15px !important;
  }
  .rl-mls-btn {
    justify-content: center !important;
    display: flex;
  }
}

/* WordPress UI */
// .rl-wp-service {
//   .MuiPaper-root {
//     margin-bottom: 25px !important;
//     border-radius: 1px !important;
//   }
// }

.rl-service-list {
  .MuiPaper-root {
    margin-bottom: 25px !important;
    border-radius: 1px !important;
    box-shadow: none !important;
    border-color: #1474be !important;
  }
  .pricing__title__header {
    font-size: 4rem !important;
    color: $color-black !important;
    text-transform: uppercase;
    font-weight: 600 !important;
    font-family: $font-family !important;
  }
  .MuiAccordion-root {
    .content {
      font-family: $font-family !important;
      margin-bottom: 1.5rem !important;
    }
  }
  .MuiAccordionSummary-content {
    align-items: center !important;
    width: 100% !important;
    margin-bottom: 0px !important;
    .row {
      width: 100% !important;
      align-items: center !important;
    }
    p {
      &:nth-child(2) {
        font-weight: 700;
        color: $color-black;
        font-family: $font-family !important;
        font-size: 2.2rem !important;
        margin: 0px !important;
      }
      &:nth-child(3) {
        // font-weight: 700;
        color: $color-black !important;
        font-family: $font-family !important;
        margin: 0px !important;
      }
      &:nth-child(4) {
        font-weight: 800 !important;
        color: #1474be !important;
        font-family: $font-family !important;
        margin: 0px !important;
      }
    }
  }
}

.addon-inactive {
  .rl-addon-help-text {
    color: $color-secondary !important;
    margin-top: 8px !important;
  }
}

#panel1bh-content {
  text-align: center !important;
  .MuiAccordionDetails-root {
    display: inline-block !important;
  }
  ul {
    text-align: left !important;
    li {
      font-weight: 500 !important;
      font-family: $font-family !important;
      &::marker {
        color: $color-tertiary-light !important;
      }
    }
  }
  .content {
    p {
      color: $color-primary !important;
      font-weight: 600 !important;
      font-family: $font-family !important;
      text-align: left !important;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none !important;
  box-shadow: none !important;
  // -webkit-text-fill-color:transparent !important;
}

.credit-modal-title {
  background-color: #efefef;
  margin-bottom: 3rem;
  color: #1474be !important;
  font-family: $font-family !important;
  font-size: 2rem !important;
  padding: 10px;
  font-weight: 600 !important;
}

.rl-table-input {
  input {
    border: 1px solid #ced4da!important;
  color: #212529!important;
  font-family: $font-family !important;
  font-size: 1.5rem!important;
  padding: 4px 8px!important;
  }
}

.rl-service-checkbox {
  .MuiTypography-root {
    margin-left: 2px !important;
  }
  svg {
    path {
      color:rgb(204, 204, 204) !important;
    }
  }
  .Mui-checked {
    svg {
      path {
        color:rgb(25, 118, 210) !important;
      }
    }
  }
}

.rl-service-video {
  .MuiFormLabel-root {
    font-family: $font-family !important;
    color: #212529!important;
    font-size: 1.4rem !important;
  }
  .container {
    padding: 0px !important;
  }
}

.rl-addon-help-text {
  color: #ffffff !important;
}

.rl-standard-post-modal {
  .modal-title {
    margin-left: 0px !important;
  }
  .MuiTableFooter-root {
    display: none !important;
  }
  .counter-option {
    display: flex;
    justify-content: flex-start;
    span  {
      text-align: center;
      display: block;
    }
    svg {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
  }
  .rl-sign-checkbox {
    margin-top: 10px;
    text-align: left;
    .MuiFormControlLabel-root {
      margin-left: 0px !important;
    }
    .MuiFormControlLabel-label {
      margin-left: 5px;
      margin-top: 3px;
      font-family: $font-family !important;
      font-size: 1.4rem !important;
    }
  }
}

.rl-addon-active {
  background-color: $color-tertiary-light;
  .MuiFormControlLabel-label {
    color: $color-white !important;
  }
  .rl-popover {
    path {
      color: #f26e21!important;
    }
  }
  path {
    color: $color-white;
  }
  .rl-price-text {
    color: $color-white !important;
    span {
      color: $color-white !important;
    }
    p {
      color: $color-white !important;
    }
  }
  .rl-info-icon {
    svg {
      color: $color-white;
    }
  } 
  .MuiCheckbox-root {
    color: $color-white !important;
  }
}

.rl-parent-service-dropdown {
  &.rl-dropdown-select {
    .rl-dropdown-list {
      .css-1s2u09g-control {
        height: 43px !important;
      }
    }
    &.rl-dropdown-content {
      .css-1pahdxg-control {
        height: 43px !important;
      }
    } 
  }
  .MuiInputLabel-root {
    padding-bottom: 0.4rem !important;
  }  
}
/* WordPress UI */

@media (max-width:460px) {
  .rl-othercontact-modal {
    .modal-title {
      margin-top: -35px!important;
    }
  }
  .rl-servicelist-modal {
    .modal-title {
      margin-top: -35px!important;
    }
  }
  .rl-logo-content {
    text-align: center;
  }
}

.rl-pricing-input {
  align-items: end !important;
  .rl-pricing-block {
    margin-right: 10px !important;
  }
}

.rl-product-pricing {
  .MuiAccordion-root {
    margin-bottom: 10px !important;
  }
  padding-bottom: 250px !important;
  .MuiCollapse-root {
    .rl-btn-primary  {
      margin-top: 10px !important;
    }
    .MuiTypography-root {
      .content {
         font-family: $font-family !important;
      }
    }
  }
  .MuiAccordionSummary-content {
    display: block !important;
    .row {
      align-items: center;
      .MuiTypography-root {
        margin: 0px !important;
        font-family: $font-family !important;
      }
    }
  }
}

.MuiInputAdornment-outlined {
  svg {
    font-size: 1.8rem !important;
    cursor: pointer;
  }
}

.rl-asterisk-input {
  &.form-group {
    margin-bottom: 0px;
    label {
        &::before {
            content:" *";
            color: #d32f2f !important;
        }
    }
    .rl-calender-label {
      &::before {
        content:" *";
        color: #d32f2f !important;
      }
    }
  }
  .MuiInputLabel-formControl {
    &::before {
      content:" *";
      color: #d32f2f !important;
      pointer-events: none !important;
    }
  }
  .MuiInputLabel-formControl {
    color: #18181b !important;
    pointer-events: none !important;
  }
}

.rl-primary-contact {
  &::before {
    content:" *";
    color: #ff0000;
  }
}

.rl-user-dropdown {
  .form-label {
      margin-bottom: 0.3rem !important;
  }
}


.css-2613qy-menu {
  z-index: 9999999 !important;
}

#react-select-2-listbox {
  z-index: 9999999 !important;
}

#react-select-2-live-region {
  z-index: none !important;
}

.rl-virtual-twilight-slider-content {
  .carousel-control-next {
    opacity: 1 !important;
    padding: 5px;
    background-color: rgba(255, 255, 255, 0.65);
    margin-right: 5px;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    .carousel-control-next-icon {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2300000'%3E%3Cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
      width: 1.5rem !important;
      height: 1.5rem !important;
    }
  }
  .carousel-control-prev {
    opacity: 1 !important;
    padding: 5px;
    background-color: rgba(255, 255, 255, 0.65);
    margin-left: 5px;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    .carousel-control-prev-icon {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg'  viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3E%3C/svg%3E");
      width: 1.5rem !important;
      height: 1.5rem !important;
    }
  }
}

.rl-addons-check {
  display: block !important;
  .rl-virtual-twilight-slider {
    .MuiTypography-root {
      font-size: 1.5rem !important;
    }
    .carousel {
      &.slide {
        max-height: 100% !important;
      }
    }
    .carousel-indicators {
      display: none !important;
    }
    .carousel-control-next, .carousel-control-prev {
      top: 35% !important;
      max-height: max-content !important;
    }
  }
}

.card-section-img {
  display: flex;
  align-items: center;
  .card-section-img-item {
    text-align: center !important;
    margin-right: 5px;
    img {
      width: 30px;
      height: 30px;
    }
  }
}

.rl-cvv-content {
  display: flex;
  align-items: center;
}

.rl-cvv-img {
  img {
    width: 40px;
    height: 40px;
    margin-top: 14px;
  }
}

.rl-creditcard-label {
  label {
    padding-bottom: 0.8rem !important;
    font-size: 1.6rem!important;
    font-family: $font-family !important;
    color: #18181b !important;
    text-align: left !important;
    &:before {
      content: "*";
      color: #d32f2f!important;
    }
  }
}

.rl-card-date {
  margin-top: -10px !important;
}

.rl-order-tbl-inventory {
  max-width: 250px;
  min-width: 250px;
}

.rl-order-form-content {
  .rl-page-count {
    padding: 0px 6px !important;
  }
}

.rl-order-form {
  border: 2px solid #1474be !important;
}

.rl-community-block {
  padding: 0rem 1rem !important;
}

.rl-virtual-twilight {
  padding: 0.5rem 1.5rem !important;
  padding-bottom: 0rem !important;
  padding-top: 5px !important;
}

.rl-sign-error-p {
  font-size: 14px;
  font-family: $font-family;
  font-weight: 500;

}


.rl-installation-radio {
  display: flex;
  .install-btn {
    padding: 0.8rem 1rem !important;
  }
  .rl-installation-radio {
    text-decoration: underline !important;
  }
}


.rl-video-thumbnail{
  img {
    width: 100% !important;
  }
}

.rl-virtual-twilight-slider {
  padding: 10px !important;
  .MuiFormControlLabel-root {
    z-index: 99 !important;
  }
}

.rl-product-video {
   padding-left: 0px !important;
}

.rl-admin-videos {
  margin-top: 0px !important;

}

.rl-video-full-block {
  padding-top: 0px !important;
}

.rl-video-package {
  width: 100% !important;
}
.rl-appointment-details .rl-form-appoinment .rl_radioInput_btn .rl-card-radio span {
  margin: 0px !important;
}

@media (max-width:1024px) {
  .rl-package-section   {
    .rl-services-block {
      .rl-most-popular-addon-section {
        .carousel-inner {
          height: 212px !important;
        }
      }
    }
  }
}

@media (min-width: 1280px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    &.rl-package-section {
      max-width: 1400px !important;
    }
    &.rl-pricing-content {
      max-width: 1400px !important;
    }
    &.rl-proertydetails-container {
      max-width: 1400px !important;
    }
    &.rl-order-summary-container {
      max-width: 1400px !important;
    }
  }
}

@media (min-width: 1880px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    &.rl-package-section {
      max-width: 1720px !important;
    }
    &.rl-pricing-content {
      max-width: 1720px !important;
    }
    &.rl-proertydetails-container {
      max-width: 1720px !important;
    }
    &.rl-order-summary-container {
      max-width: 1720px !important;
    }
  }
}


@media (max-width:360px) {
  .rl-dashboard-view {
    .rl-avatar-content {
      .figure {
         margin-bottom: 12px !important;
      }
    }
  }
}

.rl-installation-mls {
  padding: 1rem 1.2rem !important;
  h4 {
    font-size: 1.6rem !important;
    font-weight: 500 !important;
    line-height: 1.5 !important;
    font-family: $font-family !important;
  }
  .mls-check-row  {
    padding-top: 0rem !important;
    .rl-card-checkbox {
      padding: 0px 9px !important;
      padding-left: 20px !important;
      .rl-card-radio {
        .MuiFormControlLabel-root {
          margin-left: 0px !important;
        }
      }
    }
    .MuiButtonBase-root {
      padding: 0px 9px !important;
    }
    .MuiFormControlLabel-root {
       margin-right: 0rem !important;
       padding-top: 0px !important;
    }
  }
  .rl-card-checkbox {
    width: 100% !important;
  }
  .rl-card-radio {
    .MuiSvgIcon-root {
      height: 1.5em !important;
      width: 1.5em !important;
    }
    .MuiFormControlLabel-label {
      font-size: 1.4rem !important;
      margin-top: 3px;
    }
    .MuiFormControlLabel-root {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }
  } 
  .rl-mls-radio-group {
    .col-md-6 {
      flex: 0 0 auto;
      width: 33.33333333% !important;
    }
  }
}

.rl-add-co-lister {
  .rl-mls-user {
    padding: 1rem 1.2rem !important;
  }
}

.rl-public-remarks {
  .rl-mls-user {
    .rl-input-text {
      width: 100% !important;
    }
  }
}

.rl-mls-entry-section {
    flex: 0 0 auto;
    width: 33.33333333% !important;
}

.rl-mls-input-form {
  padding: 1rem 1.2rem !important;
  .MuiTypography-root {
    text-align: center !important;
    font-size: 1.4rem !important;
    font-weight: 400 !important;
  }
  .rl-mls-fileupload-input {
    padding: 0px 30px !important;
    .rl-mls-input-btn {
      text-align: center !important;
    }
    .rl-text-input {
      margin-bottom: 0px !important;
    }
    .rl-uploaded-file-mls {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      margin-bottom: 1rem !important;
    }
    .rl-mls-fileuploaded-p {
      margin-bottom: 0px !important;
      margin-left: 5px !important;
    }
  }
  .rl-mls-input {
    text-align: center;
  }
}

@media (max-width:575px) {
  .rl-dashboard-view {
    .rl-avatar-content {
      .avatar__image-box {
        margin-bottom: 15px !important;
      }
    }
  }  
}

@media (max-width:768px) {
  .rl-btn-next {
    justify-content: center !important;
    margin-top: 15px !important;
  }
  .rl-custom-package-content {
    .rl-package-form {
      margin-top: 0px !important;
    }
  }
  .rl-order-only {
    .MuiCheckbox-root {
      padding-left: 0px !important;
      padding-right: 5px !important;
    }
  }
  .rl-order-sum {
    margin-bottom: 1.5rem !important;
  }
  .rl-admin-table {
    thead {
      tr {
        th {
          line-height: 2.7rem !important;
        }
      }
    }
  }
  .rl-video-package {
    iframe {
      width: 400px !important;
    }
  }
  .rl-appoinment-confirmation {
    margin-top: 0px !important;
    margin-bottom: 5px !important;
    .MuiCheckbox-root {
      padding-left: 0px !important;
      padding-right: 5px !important;
      padding-top: 6px !important;
      padding-bottom: 6px !important;
    }
  }
  .rl-order-details {
    margin-bottom: 1.5rem !important;
  }
  .rl-update-profile {
    .rl-brokerage-input {
      label {
        display: none !important;
      }
    }
    .rl-market-btn {
      margin-top: 0rem !important;
      margin-bottom: 2rem !important;
    }
  }
  .rl-order-value {
    text-align: right !important;
  }
  .rl-order-sum-list {
    margin-top: 15px !important;
  }
  .rl-total-hr {
    width: 100% !important;
  }
  .rl-virtual-twilight-slider {
    .rl-slider-content {
      .rl-virtual-twilight-slider-content {
        width: 100% !important;
        max-width: 100% !important;
      }
    }
  }
  .rl-register-content  {
    .rl-dropdown-custom   {
      &.rl-dropdown-content  {
        div {
          margin-bottom: 0px !important;
        }
      } 
    } 
  } 
  .rl-register-content {
    .rl-market-btn  {
      margin-top: 0rem !important;
      margin-bottom: 1.8rem !important;
    }
  }
  .rl-dropdown-multilist {
    .MuiFormControl-root {
      margin-bottom: 1.8rem !important;
    }
  }
  .rl_register_block {
    .rl-dropdown-option {
      margin-bottom: 0px !important;
    }
  } 
  .rl-dropdown-list {
    svg {
      width: 8px !important;
    }
  }
}

.rl-appointment-details {
  .MuiTableCell-root {
    input {
      cursor: pointer !important;
    }
  }
}

.rl-package-content {
  .rl-edit-package-btn {
    float: right;
    padding: 0.8rem !important;
  }
}


.rl-pick-service {
  padding: 15px !important;
  border: 1px solid $color-tertiary-light !important;
  p {
    font-size: 1.6rem !important;
    font-weight: 500 !important;
    margin-bottom: 0px;
    color: $color-tertiary-light !important;
  }
}

.page-template {
  .footer_wrap {
    display: none !important;
  }
}

.rl-radio-sub-products {
  padding: 0px !important;
  border: 1px solid #eee !important;
  margin-bottom: 0px !important;
  .MuiTypography-root {
    font-size: 1.4rem !important;
  }
  .rl-price-text {
      font-size: 2rem !important;
  }
  .rl-info-icon {
    // display: none !important;
  }
  .rl-walkthrough-video {
    width: 100% !important;
  }
  .MuiCheckbox-root  {
    .MuiSvgIcon-root {
      width: 1.8rem !important;
      height: 1.8rem !important;
    }
  }
}

.rl-radio-videography {
  --bs-gutter-x:0rem !important;
}

.rl-announcement-section {
  .rl-admin-table {
    table {
      thead {
        tr {
          th {
            &:last-child {
              width: 110px;
            }
          }
        }
      }
    }
  }
}

.rl-announcement-form {
  .rl-textarea {
    .rl-textarea-count {
      // right: 30px !important;
    }
  }
  .rl-announcement-input {
    .MuiInputBase-input {
      overflow-y: scroll !important;
    }
    .MuiInputBase-multiline {
      padding-right: 0px !important;
    }
  }
}

.rl-thankyou-content {
  width: 100%;
  .thank-you {
    overflow-x: hidden;
    width: 100%;
  }
}

.rl-pricing-content {
  text-align: center !important;
  &.pricing {
    .pricing__custom {
      display: inline-block !important;
    }
  } 
}

.Toastify {
  .MuiCircularProgress-root {
    width: 20px !important;
    height: 20px !important;
    margin-right: 10px !important;
    color: #1474be !important;
  }
}


.rl-creditCard-modify .form.credit-form.rl-card-info.rl-card-details {
  border: 0px !important;
}

.mt-5.rl-save-creditDetail {
  display: flex;
}

.rl-save-creditDetail button.btn.btn-primary.next-btn.rl-btn-primary.btn.btn-primary.btn-md {
  margin-right: 20px;
}

.rental-rider-search-input {
  font-size: 1.5em;
}

.ServicesType .MuiPaper-root {
  box-shadow: none;
}

.ServicesType .MuiPaper-root thead tr th, .ServicesType .MuiPaper-root tbody tr td, .ServicesType .MuiPaper-root tbody tr th {
  border: 0px;
}

.ServicesType .MuiPaper-root thead tr th, .ServicesType .MuiPaper-root tbody tr th, .ServicesType .MuiPaper-root tbody tr td strong{
    color: #1474be !important;
    text-transform: uppercase;
    font-family: bebas-neue-pro, sans-serif !important;
    font-weight: 600 !important;
}
.Standing-Instructions thead tr th{
  color: #1474be !important;
    text-transform: uppercase;
    font-family: bebas-neue-pro, sans-serif !important;
    font-weight: 600 !important;
}
.ServicesType .MuiPaper-root tbody tr td,
.Standing-Instructions tbody tr th{
    font-size: 1.8rem !important;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.87);
    font-family: bebas-neue-pro, sans-serif !important;
}

.radio-btn-space .rl-card-radio.col-md-6 {
  margin-right: 20px;
}

.inventory-pickup-btn button {
  margin-bottom: 22px;
}

.inventory-pickup-btn {
  justify-content: start;
  width: 100%;
}
.direction.typebox .counter-option.install-counter-option {
  width: 40%;
}
.direction.typebox .rl-asterisk-input {
  display: flex;
  align-items: center;
  padding-bottom: 23px;
}
.direction.typebox .rl-calender.col-md-3 {
  padding-left: 48px;
}
.direction.typebox hr {
  width: 40%;
}

.ServicesType  .servicesCards {
  box-shadow: 0px 0px 3px #423c3c !important;
}
.logged button{
  border-radius: 0px !important;
  box-shadow: none !important;
}
.buttonlistbox.listtabbox button {
  width: 100%;
}
.ServicesType .buttonlistbox button {
  margin-bottom: 5px;
}


@media(max-width:767.98px){
  .rocket-ListAccordion .accordion-item .accordion-button{
    padding: 5px 10px;
  }
  .add-extra-class .accordion-item {
      margin-bottom: 20px;
  }
  span.viewImages {
        margin: 0px !important;
        display: block;
    }
  .ServicesType .MuiPaper-root thead tr th, .ServicesType .MuiPaper-root tbody tr th, .ServicesType .MuiPaper-root tbody tr td strong, .Standing-Instructions thead tr th{
    line-height: initial;
    font-size: 14px !important;
  }
  .rocket-ListAccordion .accordion-item .accordion-button span{
    left: 0px !important;
    font-size: 13px;
  }
  .rl-IMG-not-found {
      font-size: 20px !important;
  }
  .ServicesType{
    padding: 0px !important;
  }
  .TranscationInner {
      width: 100% !important;
  }
  .add-extra-class button.accordion-button.btn.btn-sm {
      justify-content: flex-start !important;
  }
  .rocket-ListAccordion.add-extra-class .accordion-button span{
    font-size: 25px !important;
  }
  .ServicesType h3{
    font-size: 20px !important;
  }
  .PrintPaid {
      font-size: 13px;
      margin-top: 20px;
  }
  .customerDetailPageInner {
      height: initial !important;
  }

  .customerDetailPageInner button {
    min-width: 100%;
    font-size: 11px;
    margin: 0px 0px 5px;
  }
  .rl-order-summary .order-summary .orderSum-set .font-greyLabel, .rl-order-summary .order-summary .orderSum-set .font-black500 {
      font-size: 16px;
  }
  .padding-xy .col-md-2 {
      margin-bottom: 10px;
  }
  .userprofile img {
      width: 100px;
      height: 100px;
  }
  .listing-detail-btn.btn-group{
    margin-top: 20px;
  }
  .rl-order-summary {
      margin-bottom: 0rem !important;
  }
  .buttonlistbox {
      padding: 0px !important;
      flex-direction: column !important;
  }

  .buttonlistbox button {
      margin: 0px 0px 5px !important;
      font-size: 10px;
  }
  .listing-detail-btn.btn-group button{
    font-size: 14px !important;
  }
  .buttonlistbox.listtabbox button {
      margin: 20px 0px 10px !important;
      width: 100%;
  }
  .ServicesType .servicesCards {
    margin-bottom: 10px;
}

}
.manage-listing{
  box-shadow: 0px 0px 3px #423c3c !important;
}
.contact-head{
    color: #1474be;
    font-size: 30px;
    font-weight: 300;
    text-transform: uppercase;
    text-align: center;
    display: block;
    padding-right: 70px;
}
@media(max-width:575.98px){
  .text-start.mb-5.col-lg-4.col-md-12.col-12 {
    text-align: center !important;
}
}