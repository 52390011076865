.loginPage {
    padding-left: 0;

    &__head {
        display: flex;
        justify-content: space-between;
        padding-left: 4rem;
        padding-right: 4rem;

        .home-title {
            width: auto;
        }

        .back-arrow {
            font-size: 1.3rem;
            text-transform: capitalize;
            width: 200px;
            span {
                margin-right: 0.8rem;
                color: $color-grey-dark;
            }
        }
    }

    &__leftbg {
        .left-banner {
            width: 100%;
            padding-right: 4rem;
        }
        .home-subtitle {
            margin-bottom: 2.5rem;
            text-transform: none;
        }
        .login-page__body__login-form--logo {
            margin-bottom: 4rem;
        }

        .auth-form {
            padding-left: 4rem;
        }
    }
}

/* UI Changes */
.rl_login_block {
    height: 100vh;
    // overflow-y: hidden;
    label {
        &.Mui-error {
            color: #18181b !important;
        } 
    }
    .form {
        .form-group {
            margin-bottom: 0px !important;
        }
    } 
    .home-subtitle {
        text-align: left;
        margin-bottom: 1.5rem !important;
        margin-top: 0px !important;
        font-family: $font-family !important;
    }
    .MuiInputBase-root {
        &.MuiOutlinedInput-root {
            border-radius: 0px !important;
        }
    }
    .home-subtitle {
        font-weight: 500 !important;
        font-size: 3.2rem !important;
    }
    .login-page__body__hero-img-container {
        img {
            padding-right: 0px !important;
            height: 100vh;
            object-fit: cover;
        }
    }
    form {
        label {
            font-family: $font-family !important;
            font-size: 1.6rem !important;
        }
        input {
            font-family: $font-family !important;
            font-size: 1.8rem !important;
        }
    }
    .rl_back_login {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        text-align: right;
        width: 100%;
        // margin-top: -150px;
    }
    .rl-back-btn {
        text-transform: inherit !important;
        color: #1474be;
        text-decoration: underline;
        font-size: 1.5rem !important;
    }
    .btn-primary {
        font-size: 1.9rem;
        letter-spacing: 1.8px;
        text-transform: capitalize !important;
    }
    .rl-back-btn {
        letter-spacing: normal;
        &:focus-visible {
            outline: none;
        }
    }

    .rl-back-btn {
        &:active {
            color: #1474be !important;
            background-color: transparent !important;
        }
    }
    .rl-back-btn {
        &:hover {
            box-shadow: none !important;
        }
    }

    .rl-back-btn {
        &:focus {
        background-color: #ffffff !important;
        color: #1474be !important;
        box-shadow:none;
      }
    }
}


.rl_link_btn {
    text-decoration: none !important;
}
.rl_back_login {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    text-align: right;
    width: 100%;
    .form-group {
        .mb-4 {
             margin-bottom: 1.5rem!important; 
        }
    }
    // margin-top: -80px;
}
.rl-back-btn {
    text-transform: inherit !important;
    color: #1474be;
    text-decoration: underline;
    &:focus-visible {
        outline: none;
    }
    &:active {
        color: #1474be !important;
        background-color: transparent !important;
    }
    &:hover {
        box-shadow: none !important;
    }
    &:focus {
        background-color: #ffffff !important;
        color: #1474be !important;
        box-shadow:none;
    }
}


// .rl-login-section {
//     height: 100vh;
//     overflow: hidden;
// }

@media (max-width: 767px) {
    .rl_login_block  {
        margin-right: -15px;
        margin-left: -15px;
        height: auto !important;
        overflow-y: auto !important;
        .rl_back_login {
          margin-top: 0px !important;
        }
        .login-page__body__hero-img-container {
            img {
                height: 200px;
                object-fit: cover;
            }
        }
        .col-md-8 {
            text-align: center;
        }
        .home-subtitle {
            text-align: center !important;
            margin-top: 2rem !important;
        }
        .rl-back-btn {
            display: none;
        }
        .form {
            label {
                text-align: left;
            }
        }
        .show-hide-icon {
            font-size: 2.0rem;
        }
        .form .login__cta--signin {
            padding: 0.375rem 0.75rem !important;
            font-size: 2.3rem !important;
            margin-bottom: 10px;
            width: 100%;
        }
    }
    .rl_footer_nav {
        .footer__list {
            .footer__item {
                font-size: 2.2rem !important;
            }
        }
    }
}

@media (min-width:768px) and (max-width:992px) {
    .rl_login_block .login-page__body__hero-img-container img {
        height: 100vh !important;
        object-fit: cover;
    }
}

.rl-login-section  {
    .MuiInputBase-input {
        &.MuiOutlinedInput-input {
            // font-size: 1.8rem !important;
        }
    }
}
/* UI Changes */

// ======================================
// MOBILE RESPONSIVE
// ======================================
@media (max-width: 768px) {
    .loginPage {
        &__leftbg {
            .login-page__body__login-form--logo {
                margin-bottom: 4rem;
                width: 80%;
            }

            .auth-form {
                padding-left: 3rem;
                padding-right: 3rem;
                margin-bottom: 3rem;
            }
        }
    }
}

@media (min-width: 1400px) {
    .col-xxl-2 {
        flex: 0 0 auto;
        width: 16.66666667%;
    }
}

@media (min-width: 992px) and (max-width:1600px){
    .col-lg-4 {
        flex: 0 0 auto;
        width: 33.33333333%;
    }
}


