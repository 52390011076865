.update-profile-row {
    margin-top: 1rem;
    .form {
        .form-heading {
            display: flex;
            margin-bottom: 2rem;
            .home-subtitle {
                margin-bottom: 1rem;
            }
        }

        .button-icon {
            padding-left: 0;
        }

        // .upd-save-btn {
        //     .btn {
        //         position: absolute;
        //         right: 2%;
        //         margin-top: -73px;
        //     }
        // }
    }
}


/* UI Changes */
.rl-userform-section {
    &.rl-admin-form-bg {
        .rl-user-profile {
            img {
                object-fit: cover !important;
                border: 1px solid rgba(0,0,0,.08)!important;
            }
        }
    }  
    .brokerage-block {
       margin-top: -1rem;   
    }
    .rl-dropdown-multilist {
        &.form-group {
            margin-bottom: 0px !important;
        }
        label {
            &:before {
                color: #d32f2f !important;
                content: " *";
            }
        }
    }
    .css-1s2u09g-control {
        height: 4.1rem !important;
    }
    .reg-upload-btn {
        align-items: center;
        background: transparent!important;
        border-color: #1474be!important;
        color: #1474be!important;
        // display: flex;
        // justify-content: center;
        padding: 1.5rem 1.8rem!important;
        // width: 100%;
    }
}
.rl-update-profile {
    .rl-brokerage-input {
        margin-bottom: 0rem !important;
        .MuiFormControl-root {
            margin-bottom: 0px !important;
        }
    }
    .MuiTableFooter-root {
        display: none !important;
    }
    margin-bottom: 12rem !important;
    .rl-select-noinput {
        .MuiInputBase-inputAdornedEnd {
            padding: 6px 12px!important;
        }
    } 
    .rl-title {
        font-size: 3rem !important;
        color: $color-black;
        margin: 0px !important;
        display: block;
        line-height: 1.2;
    }
    .home-subtitle {
        display: flex;
        align-items: center;
        font-size: 3rem !important;
        span {
            line-height: 0;
            display: block;
        }
    }
    .rl-table {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .MuiFormControl-root {
        input {
            font-size: 2rem !important;
        }
    } 
    .rl-dropdown-list {
        .css-1s2u09g-control {
            height: 4.1rem !important;
        }
    }
    .rl-linked-table {
        .MuiTableBody-root {
            .MuiTableRow-root {
                .MuiTableCell-body {
                    &:nth-child(5) {
                        text-align: center !important;
                    }
                }
            }
        }
        .MuiTableHead-root {
            .MuiTableRow-root {
                .MuiTableCell-head {
                    &:nth-child(5) {
                        text-align: center !important;
                    }
                }
            }
        }
    }
    .nav-brand-img {
        // width: 15%;
        width: 50%;
        height: 50%;
        object-fit: cover;
    }
    .rl-dropdown {
        input {
            height: 3.5rem !important;
        }
    }
    .MuiInputBase-input {
        &.MuiOutlinedInput-input {
            padding: 0 1.2rem 0.4rem;
        }
    }
    .roc-mui {
        .MuiInputLabel-root {
            &::before {
                content: " *";
                color: #d32f2f !important;
            }
        }
    }
    .no-asterisk {
        .MuiInputLabel-root {
            &::before {
                content: "";
            }
        }
    }
    .rl-updateprofile-img {
        display: flex;
        align-items: flex-end;
        .rl-updatephoto-img {
            .dashboard-row {
                //width: 200px;
                //height: 200px;
                overflow: hidden;
            }
        }
        .rl-logo-img {
            .dashboard-row {
                width: 200px;
                height: 200px;
                overflow: hidden;
            } 
        }
    }
    .update-profile-btn {
        text-transform: capitalize !important;
    }
    .rl-updatephoto-img {
        margin-right: 5rem !important;
        img {
            //width: 20rem;
            //height: 20rem;
            object-fit: cover;
            border: 1px solid rgba(0, 0, 0, 0.23) !important;
        }
    }
    .rl-logo-img {
        img {
            //width: 20rem;
            //height: 20rem;
            object-fit: cover;
            border: 1px solid rgba(0,0,0,.23) !important;
        }
    }
    .rl-contact {
        margin-left: -8px !important;
        text-transform: capitalize;
        &.rl-btn-primary {
            margin-left: 0px !important;
        }
        &:focus {
            box-shadow: none !important;
        }
        &:active {
            &:focus {
                box-shadow: none !important;
            }
        }
    }
    .rl-table {
        h1 {
            margin-bottom: 0px !important;
            background-color: #e6e6e6;
            padding: 8px 8px;
            text-align: center;
            color: #0e74be;
            text-transform: uppercase;
            font-family: $font-family !important;
            font-weight: 500 !important;
        }
        .btn {
            background-color: transparent;
            border-color: transparent;
        }
        .btn-primary {
            &.pr-3 {
                span {
                    svg {
                        color: $color-red;
                    }
                }
            }
            span {
                svg {
                    color: #0e74be;
                }
            }
            &:focus-visible {
                outline: none !important;
            }
            &:focus {
                box-shadow: none !important;
            }
            .MuiFormGroup-root {
                &:focus-visible {
                    outline: none !important;
                }
            }
        }
    }
    .ag-theme-alpine {
        .ag-header-cell-resize {
            &::after {
                background-color: transparent !important;
            }
        }
        .ag-header {
            background-color: $color-white !important;
            border-bottom-color: transparent !important;
        }
        .ag-row {
            border-color: transparent !important;
        }
    }
    .MuiFormControlLabel-root {
        //justify-content: center;
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
    .rl-access-checkbox {
        .MuiFormControlLabel-root {
            justify-content: flex-start !important;
        }
    }
    .rl-packages {
        .me-auto {
            font-size: 1.2rem;
        }
        .widget-summary-price {
            font-size: 1.2rem;
        }
        .rl-packages__name {
            font-size: 2.3rem;
            font-weight: 500;
            strong {
                font-size: 2rem !important;
                font-weight: 400 !important;
            }
        }
        .rl-packages__price {
            font-size: 2.8rem !important;
            font-weight: 400 !important;
        }
    }
}

.rl-see-list {
    color: $color-tertiary-light !important;
    padding-left: 0px !important;
    text-decoration: underline !important;
    text-transform: capitalize !important;
    background-color: transparent !important;
    border: 0px !important;
    font-weight: 700 !important;
}



.rl-user-profile {
    img {
        width: 14rem;
        height: 14rem !important;
        object-fit: contain;
        // box-shadow: 0px 0px 2px 0px rgba(136,136,136,0.5);
        padding: 10px;
        max-width: 14rem !important;
        max-height: 14rem !important;
    }
}

.rl-useredit-title {
    display: flex;
    align-items: center;
    .admin-subtitle {
        padding-right: 0px !important;
        margin-bottom: 0px !important;
        margin-top: 0px !important;
    }
}

.rl-edit-icon {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    font-size: 15px;
    padding: 5px !important;
    line-height: 10px;
    text-align: center;
}
.rl-user-logo {
    img {
        // box-shadow: 0px 0px 2px 0px rgba(136,136,136,0.5);
        padding: 10px;
        height: 14rem !important;
        width: 22rem;
        max-width: 22rem !important;
        max-height: 14rem !important;
        border-radius: 0px !important;
        object-fit: cover !important;
    }
}

.rl-market-dropdown {
    .MuiOutlinedInput-notchedOutline {
        border-radius: 0px !important;
    }
}

.rl-zipcode-dropdown {
    .MuiInputBase-input {
        &.MuiOutlinedInput-input {
            height: 3.5rem !important;
            padding: 0 1.2rem 0.2rem !important;
        }
    }
    .MuiAutocomplete-root {
        width: 100% !important;
    }
}

.rl-pagination-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .rl-page-count {
      font-size: 1.4rem !important;
    }
    .rl-page-counts {
        font-weight: 600;
    }
}

.rl-update-profile {
    .dashboard-row {
        margin-top: 0px !important;
    }
    .rl-dropdown-option {
        .form-label {
            margin-bottom: 0.3rem !important;
        }
        .rl-dropdown-list {
            div {
                min-height: auto !important;
                input {
                    padding: 5px 8px !important;
                }

            }
        }
    }

}

.MuiSelect-nativeInput {
    height: 3.5rem !important;
    padding: 0 1.2rem 0.2rem !important;
}
/* UI Changes */

@media (max-width: 576px) {
    .update-profile-row {
        .bottom-btn-set {
            text-align: center;
        }

        .upd-save-btn {
            text-align: center !important;
        }
    }
}

@media (max-width:768px) {
    .rl-update-profile {
        .bottom-btn-set {
            .btn-group {
                display: inline-grid !important;
                width: 100% !important;
            }
            button {
                margin-bottom: 10px !important;
                margin-left: 0px !important;
                margin-right: 0px !important;
            }
        }
        .rl-updateprofile-img {
            display: block !important;
        }
    }
}