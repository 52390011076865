.modal {
    padding-right: 0 !important;
    overflow-y: auto !important;

    // Modal MUI Form Style
    form {
        .MuiFormControl-root {
            label {
                text-align: left;
            }
        }

        fieldset {
            float: left;
        }
    }
}

.rl-add-agent-modal {
    .rl-btn-primary {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
}

.modal-header {
    border-bottom: none;

    .btn-close {
        padding-top: 3rem;
        padding-right: 3rem;

        &:focus {
            box-shadow: none;
            outline: none;
        }
    }
}

.rl-title-modal {
    h1 {
        margin-top: -60px !important;
    }
}

.modal-body {
    justify-content: center;
    text-align: center;
    padding: 0rem 6rem;

    .modal-icon {
        font-size: 9rem;
        padding: 2.5rem;
        border-radius: 50%;
        background: #fdf0e8;
        color: $color-primary;
        margin-bottom: 3rem;
    }

    .modal-title {
        text-transform: uppercase;
        margin-bottom: 0.5rem;
    }

    .sub-para {
        font-size: 1.2rem;
        color: $color-secondary;
    }

    form {
        margin-top: 3rem;
        padding-left: 2.5rem;
        padding-right: 2.5rem;

        .form-group {
            margin-bottom: 0.5rem;

            .form-label {
                font-size: 1.4rem;
                color: $color-dark;
                font-weight: 500;
            }

            input {
                font-size: 1.4rem;
                height: 4.2rem;
            }

            .check-mail {
                color: $color-tertiary-light;
                font-size: 1.8rem;
            }

            .form-check {
                font-weight: 400;

                .form-check-label {
                    display: block;
                    text-align: start;
                }
            }
        }

        .btn {
            font-size: 1.4rem;
            border-radius: 0;
            text-transform: uppercase;
            padding-left: 2.5rem;
            padding-right: 2.5rem;
            padding-top: 0.8rem;
            padding-bottom: 0.8rem;
        }

        .btn-primary {
            color: #FFFFFF;
            background: #1474be;
            border-color: #1474be;

            &:hover {
                color: #FFFFFF;
                background: #1268ab;
                border-color: #1268ab;
                transition: 0.3s;
            }
        }

        .back-btn {
            font-size: 1.3rem;
            color: $color-dark;
            background-color: $color-white;
            border-color: $color-white;
            text-transform: capitalize;

            &:hover {
                color: $color-tertiary-dark !important;
                background: $color-white !important;
                border-color: $color-white !important;
            }

            &:focus {
                box-shadow: none;
            }
        }

        .btn-cancel {
            color: $color-dark;
            background: $color-grey-light-2;
            border-color: $color-grey-light-2;

            &:hover {
                color: $color-dark;
                background: $color-grey-light-3;
                border-color: $color-grey-light-3;
                transition: 0.3s;
            }

            &:focus {
                background: $color-grey-light-3;
                border-color: $color-grey-light-3;
            }
        }

        .arrow-left {
            margin-right: 0.8rem;
        }

        .resent-option {
            font-size: 1.3rem;

            .btn {
                font-size: 1.3rem;
                color: $color-dark;
                background-color: $color-white;
                border-color: $color-white;
                text-transform: capitalize;
                color: $color-tertiary-light;
                text-decoration: underline;

                margin: 0;
                padding: 0;
                padding-left: 0.5rem;

                &:hover {
                    color: $color-tertiary-dark;
                    background: $color-white;
                    border-color: $color-white;
                }

                &:focus {
                    box-shadow: none;
                }
            }
        }

        .button-icon {
            padding-left: 0;
        }

        .credit-modal-title {
            text-align: start;
            font-size: 1.4rem;
            font-weight: 500;
            margin-top: 2rem;
            margin-bottom: 1rem;
            background-color: $color-grey-light-2;
            padding: 1.5rem 0.5rem;
        }

        .credit-select-option {
            display: inline;
            text-align: start;
            margin-bottom: 1.5rem;

            .credit-icon {
                font-size: 1.6rem;

                margin-top: 13px;
                margin-right: 20px !important;
            }

            .form-check-label {
                font-size: 1.3rem;
                padding-left: 0.5rem;

                .small-text {
                    font-size: 1rem;
                    padding-left: 0.3rem;
                    color: $color-grey-dark-2;
                }
            }

            .form-check-inline {
                font-size: 1.4rem;
                float: right;
            }
        }

        .col-md-auto {
            width: 100%;
        }
    }

    .credit-form {
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 5rem;

        .orsum-billCardInfo {
            .credit-card-row {
                display: block;

                .col-md-6 {
                    width: 100%;
                }
            }
        }
    }

    .upl-image-row {
        .form-label {
            font-size: 1.3rem;
            font-weight: 500;
            margin-top: auto;
            margin-bottom: auto;
        }

        .form-check-input {
            width: 3rem;
            height: 2rem;
        }
    }

    .upl-image-crop {
        display: flex;
        margin-bottom: 3rem;

        // .crop-box {

        // }

        .prev-box {
            .prev-title {
                font-size: 1.2rem;
                margin-bottom: 0.5rem;
            }

            canvas {
                width: 100% !important;
                border-color: $color-grey-dark-2 !important;
            }
        }
    }
}

// Admin Modal Body Padding
.mdl-py-5 {
    padding-top: 5rem;
    padding-bottom: 5rem;
}


.modal-footer {
    border-top: none;
    justify-content: center;
    margin-bottom: 4rem;
    display: block;
    text-align: center;
}

/* UI Changes */

.rl-brokerage-modal {
    .modal-title {
        margin-top: -40px !important;
    }
}

.rl-background {
    background-color: transparent !important;
}

.modal-backdrop {
    background-color: transparent !important;
}

.btn-close {
    z-index: 999999;
}

/*23*/
.rl-modal {
    min-height: auto;
    padding: 1rem !important;

    .modal-title {
        font-weight: $semibold-weight;
        letter-spacing: 1.0px;
    }

    .MuiFormControl-root {
        &.MuiTextField-root {
            margin-bottom: 0px !important;
        }
    }

    .roc-mui {
        .MuiOutlinedInput-root {
            input {
                height: 3.5rem !important;
            }
        }
    }

    .rl-superadmin-checkbox {
        .MuiButtonBase-root {
            &.MuiCheckbox-root {
                padding-left: calc(var(--bs-gutter-x) * 0.5);
                margin-left: -1px;
            }
        }

        .MuiFormControlLabel-root {
            margin-left: 0px !important;
        }
    }

    form {
        margin-top: 1rem !important;
    }

    .btn {
        letter-spacing: 1px;
        margin-right: 0.8rem;
        margin-left: 0.8rem;
    }
}

/*23*/
.rl-upload-video-modal {
    .dzu-dropzone {
        overflow: auto !important;
    }

    .dzu-inputLabel {
        color: $color-tertiary-light !important;
    }

    .dzu-dropzone {
        border: 0px !important;
    }

    .dzu-submitButton {
        background-color: $color-tertiary-light !important;
        border-color: $color-tertiary-light !important;
        color: $color-white !important;
    }

    .dzu-inputLabelWithFiles {
        color: $color-tertiary-light !important;
        background-color: $color-white !important;
        border: 1px solid $color-tertiary-light !important;
        width: 100%;
        overflow-x: auto !important;
        margin-left: 0px !important;
    }
}

.rl-custom-modal {
    .modal-title {
        font-family: $font-family !important;
    }

    .form {
        .form-group {
            .MuiInputBase-input {
                height: 3.5rem;
            }
        }
    }

    .modal-body {
        padding: 0px !important;

        .modal-icon {
            display: none !important;
        }

        .modal-title {
            font-weight: $semibold-weight !important;
            text-transform: capitalize;
        }

        form {
            margin-top: 1rem !important;

            .form-group {
                input {
                    height: 3.2rem !important;
                    font-size: 1.8rem;
                    font-weight: $light-weight !important;
                }
            }
        }
    }

    .modal-header {
        padding-left: 2.5rem;
        padding-right: 2.5rem;

        .modal-title {
            font-weight: 600 !important;
            text-transform: uppercase;
            text-align: center;
            margin-right: auto;
            // margin-left: auto;
            font-size: 2.5rem;
            line-height: 1.5;
            color: #18181b !important;
        }

        .btn-close {
            //   display: none !important;
            margin: 0px !important;
            padding: 0px !important;
            width: 2.5rem !important;
            height: 2.5rem !important;
            background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e") right/1em auto no-repeat;
        }

    }

    .btn {
        padding: 0.5rem 2.5rem !important;
    }
}

.rl-othercontact-modal {
    .roc-mui {
        input {
            height: 3.2rem !important;

        }
    }
}

.rl-custom-modal {
    .modal-sm {
        .MuiInputBase-input {
            &.MuiOutlinedInput-input {
                height: auto;
            }
        }
    }
}

.rl-title-content {
    font-family: $font-family !important;
    color: #18181b !important;
    font-size: 2rem !important;
}

.rl-remove-content {
    font-weight: 600 !important;
    text-align: left !important;
    line-height: 1em !important;
}

.rl-custom-modal {
    .modal-title {
        color: #18181b !important;
        font-size: 2.5rem;
        font-weight: 600 !important;
        line-height: 1.5;
        margin-right: auto;
        text-align: center;
        font-family: $font-family !important;
        text-transform: capitalize;
        padding: 0.5rem !important;
    }
}

.rl-servicelist-modal {
    .ag-header {
        background-color: #FFFFFF !important;
        border-bottom: 0px !important;
    }

    .ag-theme-alpine {
        .ag-row {
            border-color: transparent !important;
        }
    }

    .modal-body {
        padding: 0rem 2.5rem !important;
    }

    .btn-close {
        // color: $color-red !important;
        z-index: 999999;
    }

    .modal-title {
        text-align: left;
        margin-top: -45px;
        text-transform: capitalize !important;
        margin-bottom: 0rem !important;

        span {
            color: $color-tertiary-light !important;
            font-weight: $semibold-weight;
            text-transform: capitalize !important;
        }
    }
}

.rl-othercontact-modal {
    .modal-title {
        margin-top: -45px;
        text-align: left;
    }

    .modal-body {
        padding: 0px 2.5rem !important;
    }

    form {
        padding: 0px !important;
    }
}

.rl-notification-list {
    .MuiCheckbox-root {
        padding-left: 0px !important;
        padding-top: 0px !important;
        padding-bottom: 0px !important;

        .MuiSvgIcon-root {
            width: 1.5em !important;
            height: 1.5em !important;
        }
    }

    .MuiButtonBase-root {
        &.MuiCheckbox-root {
            // padding: 0px 15px;
        }
    }

    .rl-title-content {
        text-align: left;
        font-weight: $semibold-weight;
    }

    .MuiFormControlLabel-root {
        padding: 0.5rem !important;
        margin-left: 0px !important;
    }

    .MuiFormControlLabel-label {
        margin-left: 5px !important;
    }
}

.rl-profile-payment-modal {
    .credit-form {
        padding-left: 0px !important;
        padding-right: 0px !important;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2) !important;
        border: none !important;
    }

    &.rl-custom-modal {
        .rl-table-icon {
            .btn {
                padding: 0px !important;
                padding-left: 1rem !important;
            }
        }
    }

    .rl-card-details {
        .rl-card-option {
            border: 0px !important;
        }
    }

    .rl-admin-table {
        .MuiTableCell-root {
            font-size: 1.5rem !important;
        }

        table {
            tr {
                td {
                    &:first-child {
                        padding-left: 3rem !important;
                    }
                }

                th {
                    &:first-child {
                        padding-left: 3rem !important;
                    }
                }
            }
        }
    }

    .rl-card-info {
        // border-left: 2px solid #1474be !important;
    }

    .rl-creditcard-list {
        border-right: 0px !important;
        padding-right: calc(var(--bs-gutter-x)*.5) !important;
    }

    .rl-profile-form {
        padding-left: 15px !important;

        .MuiSelect-select {
            line-height: 3.5rem !important;
        }

        .MuiFormControlLabel-labelPlacementEnd {
            margin-left: 0px !important;
        }
    }

    .rl-profile-form-btn {
        &.rl-mls-btn {
            display: block !important;
            width: 100%;

            .back-btn {
                border: #1474be !important;
                margin-left: 10px !important;
                border: 1px solid #1474be !important;
            }

            .mt-5 {
                display: flex;
                margin-bottom: 3rem;
                justify-content: center;
            }

            .btn {
                width: auto !important;
            }
        }
    }

    .rl-credit-card-radio {
        margin-top: 0px !important;

        .MuiFormControlLabel-labelPlacementEnd {
            margin-left: 0px !important;
        }
    }

    &.rl-paymentmethod-modal {
        .MuiFormControlLabel-root {
            border: 0px !important;
        }
    }
}

.rl-paymentmethod-modal {
    .rl-credit-card {
        .MuiFormControl-root {
            margin-bottom: 0rem !important;
        }

        .MuiFormHelperText-root {
            // display: none !important;
        }
    }

    .rl-card-radio {
        width: 100% !important;
    }

    .credit-form {
        .rl-card-checkbox {
            width: 100%;

            .rl-card-radio {

                // .MuiSvgIcon-root {
                //     color: #1474be !important;
                // }
                .MuiFormControlLabel-label {
                    font-weight: 500 !important;
                }

                .MuiFormControlLabel-root {
                    border: 1px solid #707070 !important;
                    display: flex;
                    width: 100%;
                    padding: 5px 10px !important;
                    justify-content: space-between;
                }

                .MuiRadio-root {
                    &.Mui-checked {
                        color: #1474be !important;
                    }
                }

                .MuiTypography-body1 {
                    margin-right: 5px !important;
                }
            }
        }
    }

    .MuiSelect-select {
        height: 3.5rem !important;
        padding: 0 1.2rem 0.2rem !important;
    }

    .credit-form {
        margin-bottom: 0px !important;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        ;
    }

    .MuiSelect-nativeInput {
        height: 3.5rem !important;
        padding: 0 1.2rem 0.2rem !important;
    }

    .rl-billing-address {
        background-color: RGB(242 242 242 / 0.60) !important;
        border-radius: 6px;
        padding: 5px;
        margin-bottom: 20px;

        .MuiFormControl-root {
            margin-bottom: 0.5rem !important;
        }
    }

    .rl-credit-card {
        background-color: RGB(242 242 242 / 0.60) !important;
        border-radius: 6px;
        padding: 5px;
    }

    .rl-button-group {
        text-align: center !important;
    }

    .rl-credit-text {
        display: flex;

        svg {
            font-size: 2rem;
            margin-right: 1rem !important;
        }

        span {
            font-size: 2rem;
            display: inline-block;
            line-height: 2rem;
        }
    }

    .list-group-item {
        border: none !important;
    }

    .MuiFormGroup-root {
        // display: block !important;
    }

    .rl-checkbox {
        .MuiFormControlLabel-root {
            border: 0px !important;
        }
    }

    .MuiFormControlLabel-root {
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-bottom: 0px;

        &:last-child {
            border: 1px solid rgba(0, 0, 0, 0.125);
        }

        // display: block !important;
        .MuiFormControlLabel-label {
            font-size: 1.8rem !important;
            font-weight: $semibold-weight;
            margin-left: 5px !important;
        }
    }

    .rl-addcard-content {
        border: 1px solid #707070;
        border-radius: 7px;
        padding: 1rem 2rem !important;
        margin-top: 3.5rem !important;

        // .roc-mui {
        //     margin-bottom: 0.5rem !important;
        // }
        // .MuiFormControl-root {
        //     margin-bottom: 0.5rem !important;
        // }
        .rl-slash-tag {
            font-size: 3.8rem !important;
            margin-bottom: 0px !important;
            line-height: 1 !important;
        }

        .rl-card-save {
            text-align: left;

            .btn {
                margin-left: 0px !important;
            }
        }

        .credit-modal-title {
            text-align: center !important;
            margin-top: -40px;
            background-color: transparent !important;
            margin-bottom: 0px !important;
            padding-bottom: 0.5rem !important;

            span {
                font-size: 2rem !important;
                font-weight: $semibold-weight;
                background-color: $color-white !important;
                padding: 5px 20px;
                text-transform: uppercase !important;
            }
        }

        .text-start {
            font-size: 2.5rem !important;
            margin-bottom: 0.5rem !important;
        }
    }
}

.credit-form {
    .rl-nodata-content {
        p {
            color: #212529 !important;
        }
    }

    .form-group {
        label {
            &:before {
                color: red;
                content: " *";
            }
        }
    }

    .MuiInputLabel-formControl {
        &.Mui-error {
            color: #18181b !important;
        }
    }
}

.rl-popover-modal {
    h1 {
        text-align: left;
        margin-top: -55px;
        text-transform: capitalize !important;
        margin-bottom: 2rem !important;
        font-weight: 600 !important;
        font-size: 2.5rem;
        line-height: 1.5;
        margin-left: -5px !important;
    }
}

.rl-template-modal {
    .text-end {
        .rl-btn-primary {
            margin: 0px !important;
        }
    }
}

.rl-variant-modal {
    .rl-dropdown-content {
        text-align: left !important;

        .MuiFormControl-fullWidth {
            margin-bottom: 0px !important;
        }
    }

    .MuiFormGroup-root {
        display: inline !important;
    }

    .MuiSelect-select {
        text-align: left !important;
    }

    .MuiFormControlLabel-labelPlacementEnd {
        margin-left: 0px !important;
    }

    .MuiRadio-root {
        margin-right: 3px !important;
    }

    .MuiOutlinedInput-notchedOutline {
        border-radius: 0px !important;
    }

    .form-label {
        text-align: left !important;
    }

    .rl-indicator-dropdown {
        text-align: left !important;
    }
}

.rl-variant-modal {
    .rl-dropdown-list div {
        z-index: 99;
        min-height: auto !important;
    }
}

.rl-linked-modal {
    .MuiCheckbox-root {
        padding-left: 0px !important;
    }

    .rl-dropdown-content {
        text-align: left !important;
    }
}

.rl-service-variant-modal {
    .rl-card-radio {
        width: auto !important;
    }

    .form {
        .form-group {
            &.rl-dropdown-content {
                .MuiInputBase-input {
                    height: auto !important;
                }
            }

        }
    }
}

.rl-linkedaccounts-modal {
    .rl-dropdown-content {
        text-align: left !important;
    }

    .rl-dropdown-list {
        div {
            min-height: auto !important;
        }
    }

    .MuiFormGroup-root {
        .MuiCheckbox-root {
            padding-top: 0px !important;
            padding-bottom: 0px !important;
            padding-left: 5px !important;
        }
    }
}

.rl-addservice-modal {
    .rl-dropdown-list {
        div {
            min-height: auto !important;
        }
    }
}

.rl-addrider-modal {
    .MuiSelect-select {
        text-align: left !important;
    }

    .MuiIconButton-root {
        margin-right: 5px !important;
    }
}

/* UI Changes */

// ======================================
// MOBILE RESPONSIVE
// ======================================
@media (max-width: 768px) {
    .modal-body {
        padding: 0 3rem;
    }
}

.rl-image-container {
    flex: 0 0 calc(20% - 20px);
    margin: 10px;
}

.rl-image {
    max-width: 100%;
    height: auto;
}

.rl-myInventory-img {
    width: 10%;
    height: auto;
    margin-left: 15%;
}

.rl-inventoryList-img {
    width: 10%;
    height: auto;
}


.rl-inventoryDetail-img {
    width: 100px;
    height: auto;
}

.rl-appointment-img {
    width: 100px;
    height: 100px;
    margin-left: 9%;
}

.rl-checkbox-container {
    margin-top: 10px;
}

.rl-Download-Button {
    margin-right: 1.1rem;
}

.rl-checkbox {
    transform: scale(1.6);
}

.disable-pointer-events {
    pointer-events: none;
}

.rl-IMG-not-found {
    font-size: 46px;
}


.rl-filename {
    font-size: 15px;
    color: #333;
    margin-top: 4px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


.custom-xl-modal .modal-dialog {
    max-width: 70%;
    max-height: 60%;
}

.text-strat {
    text-align: left;
}

.RL-checkbox svg {
    width: 25px;
    height: 25px;
    margin-right: 8px;
}

label.RL-label {
    font-size: 23px;
}

.RL-checkbox {
    margin-left: 9px !important;
}

.inventory-items img {
    width: 80%;
    height: 140px;
    object-fit: cover;
}

.inventory-items-rl-list img {
    width: 80%;
    height: 200px;
    object-fit: contain;
}

textarea.form-control.rlform-control {
    font-size: 17px;
    box-shadow: none !important;
    min-height: 50px;
    margin-bottom: 30px;
    border-radius: 0px;
    color: #000;
}

textarea.form-control.rlform-control:hover {
    border-color: #000;
}

.rl-remove-content span h1 {
    font-size: 23px;
    font-weight: 500;
}

.mainbox-outer {
    background: #f7f7f7;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.Inventory-items {
    text-align: center;
}


.rocket-ListAccordion .accordion-item {
    border: 1px solid #e1dfdf;
    background: transparent;
    border-radius: 0px;
}

.rocket-ListAccordion .accordion-item .accordion-button {
    border: 0px !important;
    background: #efefef !important;
    color: #1474be;
    box-shadow: none !important;
    font-size: 1.7rem !important;
    position: relative;
}

.rocket-ListAccordion .accordion-item .accordion-button span {
    position: relative;
    left: 45%;
    // transform: translateX(-80%);
    transform: translateX(-0%);
}

.rocket-ListAccordion .accordion-item .accordion-button span span {
    position: initial;
    transform: initial;
    margin-left: 5px;
}

.rocket-ListAccordion .accordion-item .accordion-button span.img-count {
    position: absolute;
    right: 100px;
}

.rocket-ListAccordion .accordion-item .accordion-collapse {
    border: 0px !important;
}


.extra-text {
    height: 38px !important;
    resize: none !important;
    overflow-y: scroll !important;
}

.rocket-list-Outer .rl-checkbox {
    transform: scale(1.6);
    display: inline-block;
}

.rocket-list-Outer .rl-checkbox-container {
    text-align: center;
}

.rocket-list-Outer .rl-filename {
    font-size: 1.5rem;
    margin-bottom: 30px;
}

.rocket-list-Outer .rl-image {
    width: 100%;
    height: 230px;
    object-fit: contain;
    object-position: top;
}

.extra-view-image {
    height: 400px
}

.icon-lg {
    font-size: 24px !important;
    cursor: pointer;
}

.my-inventory-rl {
    text-align: left;
    font-family: bebas-neue-pro, sans-serif;
    font-size: 1.6rem;
}

.mainbox-outer-inventory {
    background: #f7f7f7;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
}

.inventory-Box img {
    width: 100%;
    height: 200px;
    object-fit: contain;
    object-position: center;
}

.Inventory-items.mr-3.inventory-Box {
    width: 100%;
}

.rocket-ListAccordion.add-extra-class .accordion-body {
    border: 2px solid #1474be;
}
.rl-mls-form span.rl-primary-contact {
    text-align: left;
    font-size: 1.3rem !important;
}
.rl-mls-form .rl-card-checkbox {
    text-align: left;
}
.rl-mls-form .rl-asterisk-input.rl_radioInput_btn.col .MuiFormGroup-root.MuiFormGroup-row {
    padding-left: 10px;
}

.rocket-ListAccordion.inventory .accordion-item .accordion-button {
    display: block;
    text-align: center;
}

.rocket-ListAccordion.inventory .accordion-item .accordion-button::after {
    width: 20px;
    display: block;
    position: absolute;
    right: 15px;
    top: 14px;
}

.inventory-breadcrumb {
    font-size: 20px;
    margin-bottom: 10px;
    display: block;

}
.grid-outer {
    background: #efefef !important;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.grid-outer h4 {
    margin-bottom: 18px;
}
.credit-card-add {
    padding: 0rem 2.5rem 2.5rem;
}

.credit-card-add .rl-card-date{
    margin: 0px -9px !important;
} 

.rl-modal .credit-card-add .MuiFormControl-root.MuiTextField-root {
margin-bottom: 1.5rem !important;
}