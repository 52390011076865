.contact-detail {
    .big-title {
        font-size: 3.6rem;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 1.5rem;
    }
    .light-title {
        font-size: 2.6rem;
        font-weight: 300;
        margin-bottom: 2.5rem;
    }
    .ct-description {
        font-size: 1.4rem;
        color: $color-grey-dark-2;
        line-height: 2.6rem;
        font-weight: 300;
        margin-bottom: 5rem;
    }
    .ct-info {
        color: $color-primary;
        margin-top: 5rem;
        font-weight: 300;
        font-size: 2.2rem;
        line-height: 3.2rem;
        display: flex;
    }
    .ct-info-icon {
        color: $color-tertiary-light;
        font-weight: 300;
        margin-right: 1rem;
        font-size: 2.4rem;
    }
}

.contact-form {
    .form {
        .form-group {
            .form-control {
                // border-left: none;
            }
        }

        .get-touch-btn {
            text-align: center;
            margin-bottom: 6rem;
        }
    }
}